import { useEffect, useState } from "react";
import ModalPortal from "../../Layout/Modal/ModalPortal";
import Loading from "../../components/loading/Loading";
import PDFViewer from "../../components/PDFViewer/PDFViewer";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import {AjaxClientV2} from "../../components/util/AjaxClientV2";


const AdminDentalConfirmResultModal = ({ onClose, isOpen, pdfURL, requestId, companyCode }: any) => {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(false);
    }, []);

    const pdfAttached = async () => {
        console.log(requestId);
        let parts = pdfURL.split("/");
        let file_name = parts[parts.length-1];
        const file_path = pdfURL.split("https://bocare.azureedge.net/insurancerequest/")[1];
        let file_size : any;
        try {
            let response = await axios.head(pdfURL);
            let contentLength = response.headers['content-length'];
            if (contentLength) {
                file_size = contentLength;
            }
        } catch (error) {
            alert('PDF 파일을 가져올 수 없습니다.');
        }

        let info = {
            "file_name" : file_name,
            "file_path" : file_path,
            "file_size" : file_size,
            "request_id" : requestId.toString()
        }

        await axios.post(`${process.env.REACT_APP_API_URL_ENTRY}/dentalPDF/attached`, JSON.stringify(info), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(async (res) => {
            alert("치과치료확인서가 첨부되었습니다. 접수를 진행해주세요.");
            window.location.reload();
        }).catch((err) => {
            console.log(err);
            alert("서버에서 문제가 발생하였습니다. 관리자에게 문의하여주세요.");
        });
    }


    return (
        <ModalPortal>
            {loading ? <Loading /> : null}
            <Modal show={isOpen} size="lg">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel"><b>치과치료 확인서 결과</b></h5>
                    <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <div className="d-flex justify-content-center align-items-center align-content-center">
                        <PDFViewer pdfUrl={pdfURL}/>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={onClose}>닫기</button>
                    <a href={pdfURL} download>
                        <button type="button" className="btn btn-primary">다운로드</button>
                    </a>
                    {requestId !== "" ? (
                    <button type="button" className="btn btn-success" onClick={() => pdfAttached()}>바로첨부</button>
                    ):null}
                </div>
            </Modal>
        </ModalPortal>
    );
}

export default AdminDentalConfirmResultModal;
