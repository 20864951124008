
function InsCard({insCompanyInfo} : any) {
    const handleImageError = (e:any) => {
        e.target.Error = null;
        e.target.src = `/image/company/${insCompanyInfo.name}.png`;
    }

    return(
        <div className={`${insCompanyInfo.display} mb-3`}>
            <div className="border border-5 rounded-3 p-4 me-3 w-25 d-flex align-items-center">
                <div>
                    <img src={`/image/company/${insCompanyInfo.name}.svg`} onError={handleImageError}
                        alt={insCompanyInfo.name} className="d-block w-100"/>
                    <div className="fs-4 mt-2">{insCompanyInfo.name}</div>
                </div>
            </div>
            <div className="w-75 text-start">
                <div className="fs-4 mb-2">[<span className="fw-bold">{insCompanyInfo.name} 고객센터 : {insCompanyInfo.companynumber}</span>]
                   <div>가상팩스 번호 발급 후 아래에 해당 번호를 기입해주세요.</div>
                </div>
                <input id="virtualFaxNumber" name="virtualFaxNumber" className={`${insCompanyInfo.display} form-control py-6 kiosk-input write-input`}
                       type="tel"
                       placeholder="'-'없이 입력"/>
            </div>
        </div>
    );
}

export default InsCard;