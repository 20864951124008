import ModalPortal from "../../Layout/Modal/ModalPortal";
import Modal from "react-bootstrap/Modal";
import '../../components/css/admin_dashboard.css';
import '../../components/css/admin_dashboard_rtl.css';
import '../../components/css/dentalConfirmModal.css';
import {useEffect, useState} from "react";
import Loading from "../../components/loading/Loading";
import axios from "axios";
import Resizer from "react-image-file-resizer";
import {AzureBlobStorage} from "../../AzureStorage/AzureStorage";
import {AjaxClientV2} from "../../components/util/AjaxClientV2";
import {DentalPdfConstruction} from "../../DTOs/DentalPdfConstruction";
import {ModifyRequest} from "../../DTOs/ModifyRequest";
import {DentalUserInfoRequest} from "../../DTOs/DentalUserInfoRequest";
import {DentalTreatmentInfoRequest} from "../../DTOs/DentalTreatmentInfoRequest";
import AdminDentalConfirmResultModal from "./AdminDentalConfirmResultModal";
import {removeBackground} from "@imgly/background-removal";

const AdminDentalConfirmModal = ({onClose, isOpen, requestId, companyCode}: any) => {
    const [loading, setLoading] = useState(false);
    const [selectedTeeth1, setSelectedTeeth1] = useState<string[]>([]);
    const [selectedTeeth2, setSelectedTeeth2] = useState<string[]>([]);
    const [hospitalPhone, setHospitalPhoneNumber] = useState<string>('');
    const [adminDentalConfirmResultModalOpen, setAdminDentalConfirmResultModalOpen] = useState();
    const [pdfURL, setPdfURL] = useState<string[]>([]);
    const [insuranceType, setInsuranceType] = useState('nonLife');
    const azure = new AzureBlobStorage("kioskmain");
    let checkBoxMaxSize = 20;
    if(companyCode === 32){ //DB손해보험
        checkBoxMaxSize = 20;
    }else if(companyCode === 25) { //한화손해보험
        checkBoxMaxSize = 8;
    }else if(companyCode === 30) { //현대해상화제
        checkBoxMaxSize = 20;
    }else if(companyCode === 31) { //KB손해보험
        checkBoxMaxSize = 10;
    }else if(companyCode === 24) { //메리츠화재
        checkBoxMaxSize = 20;
    }else if(companyCode === 37) { //NH농협손해보험
        checkBoxMaxSize = 20;
    }else if(companyCode === 7) { //ABL생명보험
        checkBoxMaxSize = 9;
    }else if(companyCode === 19) { //동양생명보험
        checkBoxMaxSize = 20;
    }else if(companyCode === 19) { //한화생명보험
        checkBoxMaxSize = 20;
    }else if(companyCode === 14) { //신한라이프
        checkBoxMaxSize = 16;
    }

    useEffect(() => {
        setLoading(false);

        const info = JSON.parse(localStorage.getItem("user") as string);
        let dental_image = document.getElementById("dentalSignCustomImage_pre") as HTMLImageElement;
        let doctor_image = document.getElementById("doctorSignCustomImage_pre") as HTMLImageElement;
        let hospital_name = document.getElementById("hospitalName") as HTMLInputElement;
        let doctor_name = document.getElementById("doctorName") as HTMLInputElement;
        let license_number = document.getElementById("license") as HTMLInputElement;

        dental_image.src = "";
        doctor_image.src = "";
        if (info.dental_stamp_path !== undefined) {
            dental_image.src = `https://bocare.azureedge.net/kioskmain/${info.dental_stamp_path}`;
        }
        if (info.doctor_stamp_path !== undefined) {
            doctor_image.src = `https://bocare.azureedge.net/kioskmain/${info.doctor_stamp_path}`;
        }

        if(info.hospital_name !== undefined){
            hospital_name.value = info.hospital_name;
        }
        if(info.doctor_name !== undefined){
            doctor_name.value = info.doctor_name;
        }
        if(info.license_number !== undefined){
            license_number.value = info.license_number;
        }
        if(info.hospital_phone !== undefined){
            setHospitalPhoneNumber(info.hospital_phone);
        }

    }, []);

    const autoHyphenNumber = (target: HTMLInputElement) => {
        target.value = target.value
            .replace(/[^0-9]/g, '')
            .replace(/^(\d{0,6})(\d{0,7})$/g, "$1-$2").replace(/(\-{1,2})$/g, "");
    }

    const data = [
        [18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28],
        ["18", "17", "16", "15", "14", "13", "12", "11", "21", "22", "23", "24", "25", "26", "27", "28"],
        ["48", "47", "46", "45", "44", "43", "42", "41", "31", "32", "33", "34", "35", "36", "37", "38"],
        [48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38],
    ];

    const handleCheckboxChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value, checked} = event.target;
        if (checked) {
            if (selectedTeeth1.length>= checkBoxMaxSize ) {
                alert(`최대 ${checkBoxMaxSize}개까지만 선택할 수 있습니다.`);
                event.target.checked = false;
                return;
            }
            setSelectedTeeth1(prevSelectedTeeth => [...prevSelectedTeeth, value]);
        } else {
            const updatedTeeth = selectedTeeth1.filter(teeth => teeth !== value);
            setSelectedTeeth1(updatedTeeth);
        }
    };

    const handleCheckboxChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value, checked} = event.target;
        if (checked) {
            if (selectedTeeth2.length>= checkBoxMaxSize) {
                alert(`최대 ${checkBoxMaxSize}개까지만 선택할 수 있습니다.`);
                event.target.checked = false;
                return;
            }
            setSelectedTeeth2(prevSelectedTeeth => [...prevSelectedTeeth, value]);
        } else {
            const updatedTeeth = selectedTeeth2.filter(teeth => teeth !== value);
            setSelectedTeeth2(updatedTeeth);
        }
    };

    const imagePreview = async (fileElement: any, imageElement: any) => {
        let fileTag = document.getElementById(fileElement) as HTMLInputElement;
        let imageTag = document.getElementById(imageElement) as HTMLImageElement;

        let fileReader = new FileReader();
        // @ts-ignore
        fileReader.readAsDataURL(fileTag.files[0]);
        fileReader.onloadend = () => {
            imageTag.src = fileReader.result as string;
        }
    }

    function formatDate(date: any): string {
        if (date != null) {
            const year = date.getFullYear();
            let month = String(date.getMonth() + 1);
            let day = String(date.getDate());

            // 월과 일이 한 자리 숫자인 경우 앞에 0을 붙여줍니다.
            if (month.length === 1) {
                month = '0' + month;
            }
            if (day.length === 1) {
                day = '0' + day;
            }
            return `${year}-${month}-${day}`;
        } else {
            return "";
        }
    }

    const hospitalPhoneHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let inputPhoneNumber = e.target.value;
        // 포맷팅된 전화번호로 변경
        let formattedNumber = formatPhoneNumber(inputPhoneNumber);
        // 상태 업데이트
        setHospitalPhoneNumber(formattedNumber);
    };

    const formatPhoneNumber = (phoneNumber: string) => {
        let formattedNumber = phoneNumber.replace(/\D/g, ''); // 숫자만 추출
        formattedNumber = formattedNumber.slice(0, 11); // 최대 11자리로 제한
        // 전화번호 형식에 따라 하이픈 추가
        if (formattedNumber.length >= 9) {
            // 국번이 있을 경우
            formattedNumber = formattedNumber.replace(/(\d{2,3})(\d{3,4})(\d{4})/, '$1-$2-$3');
        } else if (formattedNumber.length >= 5) {
            // 국번이 없는 경우
            formattedNumber = formattedNumber.replace(/(\d{3,4})(\d{0,4})/, '$1-$2');
        }
        return formattedNumber;
    };

    const HandleAdminDentalConfirmResultRequestId = (pdfURL :any , requestId:any, companyCode:any) => {
        // @ts-ignore
        setAdminDentalConfirmResultModalOpen(true);
    }

    const HandleAdminDentalConfirmResultModalShow = () => {
        // @ts-ignore
        setAdminDentalConfirmResultModalOpen(false);
    }

    const makeDentalConfirm = async () => {
        if(companyCode==="") { // 생성기로 들어온 경우
            let company : string;
            if(insuranceType === 'nonLife'){
                company = (document.getElementById("fp_insurance_nonLife") as HTMLSelectElement).value;
            }
            else{
                company = (document.getElementById("fp_insurance_life") as HTMLSelectElement).value;
            }
            
            if(company === ""){
                alert("보험사를 선택해주세요.");
                return;
            }
            else{
                companyCode = parseInt(company);
            }
        }
        // 필수 입력값 확인
        let name = (document.getElementById("name") as HTMLInputElement).value;
        let number = (document.getElementById("number") as HTMLInputElement).value;
        let hospital_name = (document.getElementById("hospitalName") as HTMLInputElement).value;
        let doctor_name = (document.getElementById("doctorName") as HTMLInputElement).value;
        let license = (document.getElementById("license") as HTMLInputElement).value;
        let hospitalPhone = (document.getElementById("hospitalPhone") as HTMLInputElement).value;
        const dentalSignCustomImagePre = document.getElementById("dentalSignCustomImage_pre");
        const doctorSignCustomImagePre = document.getElementById("doctorSignCustomImage_pre");
        if (!name || !number || !hospital_name || !doctor_name || !license || !hospitalPhone) {
            alert("모든 필수값을 입력해주세요.");
            return;
        }
        if(number.length !== 14){
            alert("주민번호를 제대로 입력해주세요.");
            return;
        }
        if(hospitalPhone.length <= 8){
            alert("전화번호를 제대로 입력해주세요.");
            return;
        }
        if (selectedTeeth1.length === 0 && selectedTeeth2.length === 0) {
            alert("적어도 하나의 치아를 선택해야 합니다.");
            return;
        }
        // @ts-ignore
        if (dentalSignCustomImagePre.src == "https://bocare.azureedge.net/kioskmain/null" || doctorSignCustomImagePre.src == "https://bocare.azureedge.net/kioskmain/null") {
            alert("도장을 등록해주세요.");
            return;
        }

        if (companyCode === 32) { //DB 손해보험 예외처리
            const selectTagsInfo = document.querySelectorAll('select');
            let intraoralPanoCheck = false;
            let periodontalCheck = 0;
            let amalgamGiCheck = 0;
            let resinCheck = 0;
            let goldCheck = 0;
            let extractImplantBridgeDentureCheck = 0;
            let crownCheck = 0;
            for (let i = 0; i < selectTagsInfo.length; i++) {
                const selectTag = selectTagsInfo[i];
                const selectedOption = selectTag.options[selectTag.selectedIndex];
                if (selectedOption.id) {
                    let type = selectedOption.id.split("-")[0];
                    if (type === "intraoral" || type === "panorama") {
                        if (intraoralPanoCheck) {
                            alert("방사선은 하나만 선택할 수 있습니다.");
                            return;
                        }
                        intraoralPanoCheck = true;
                    }
                    if (type === "curettage" || type === "planing") {
                        periodontalCheck += 1;
                        if (periodontalCheck > 3) {
                            alert("치주치료는 최대 3개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                    if (type === "amalgam" || type === "gi") {
                        amalgamGiCheck += 1;
                        if (amalgamGiCheck > 2) {
                            alert("아말감과 GI는 최대 2개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                    if (type === "resin") {
                        resinCheck += 1;
                        if (resinCheck > 3) {
                            alert("레진은 최대 3개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                    if (type === "gold") {
                        goldCheck += 1;
                        if (goldCheck > 3) {
                            alert("금은 최대 3개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                    if (type === "simple" || type === "elaborate" || type === "ambush" || type === "implant" || type === "bridge" || type === "denture") {
                        extractImplantBridgeDentureCheck += 1;
                        if (extractImplantBridgeDentureCheck > 5) {
                            alert("단순/정교/완전매복/임플란트/브릿지/틀니는 최대 5개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                }
            }
            const crownCheckboxes = document.querySelectorAll<HTMLInputElement>('input[type="checkbox"][id^="crown-"]');
            for (let i = 0; i < crownCheckboxes.length; i++) {
                const checkbox = crownCheckboxes[i];
                if (checkbox.checked) {
                    crownCheck += 1;
                    if (crownCheck > 3) {
                        alert("크라운은 최대 3개까지 선택할 수 있습니다.");
                        return;
                    }
                }
            }
        }else if (companyCode === 30) { //현대해상화재 예외처리
            const selectTagsInfo = document.querySelectorAll('select');
            let intraoralPanoCheck = 0;
            let periodontalCheck = 0;
            let preserveCheck = 0;
            let extractCheck = 0;
            let implantBridgeDentureCheck = 0;
            for (let i = 0; i < selectTagsInfo.length; i++) {
                const selectTag = selectTagsInfo[i];
                const selectedOption = selectTag.options[selectTag.selectedIndex];
                if (selectedOption.id) {
                    let type = selectedOption.id.split("-")[0];
                    if (type === "intraoral" || type === "panorama") {
                        intraoralPanoCheck += 1;
                        if (intraoralPanoCheck > 8) {
                            alert("방사선은 최대 8개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                    if (type === "curettage" || type === "planing") {
                        periodontalCheck += 1;
                        if (periodontalCheck > 8) {
                            alert("치주치료는 최대 8개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                    if (type === "amalgam" || type === "gi" || type === "resin" || type === "gold") {
                        preserveCheck += 1;
                        if (preserveCheck > 8) {
                            alert("아말감/GI/레진/금/크라운은 최대 8개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                    if (type === "simple" || type === "elaborate" || type === "ambush") {
                        extractCheck += 1;
                        if (extractCheck > 8) {
                            alert("단순/정교/완전매복은 최대 8개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                    if ( type === "implant" || type === "bridge" || type === "denture") {
                        implantBridgeDentureCheck += 1;
                        if (implantBridgeDentureCheck > 8) {
                            alert("임플란트/브릿지/틀니는 최대 8개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                }
            }
            const crownCheckboxes = document.querySelectorAll<HTMLInputElement>('input[type="checkbox"][id^="crown-"]');
            for (let i = 0; i < crownCheckboxes.length; i++) {
                const checkbox = crownCheckboxes[i];
                if (checkbox.checked) {
                    preserveCheck += 1;
                    if (preserveCheck > 8) {
                        alert("아말감/GI/레진/금/크라운은 최대 8개까지 선택할 수 있습니다.");
                        return;
                    }
                }
            }
        } else if (companyCode === 24) { //메리츠화재 예외처리
            const selectTagsInfo = document.querySelectorAll('select');
            let intraoralPanoCheck = 0;
            let periodontalCheck = 0;
            let preserveCheck = 0;
            let crownCheck = 0;
            let extractCheck = 0;
            let implantBridgeDentureCheck = 0;
            for (let i = 0; i < selectTagsInfo.length; i++) {
                const selectTag = selectTagsInfo[i];
                const selectedOption = selectTag.options[selectTag.selectedIndex];
                if (selectedOption.id) {
                    let type = selectedOption.id.split("-")[0];
                    if (type === "intraoral" || type === "panorama") {
                        intraoralPanoCheck += 1;
                        if (intraoralPanoCheck > 2) {
                            alert("방사선은 최대 2개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                    if (type === "curettage" || type === "planing") {
                        periodontalCheck += 1;
                        if (periodontalCheck > 1) {
                            alert("치주치료는 최대 1개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                    if (type === "amalgam" || type === "gi" || type === "resin" || type === "gold") {
                        preserveCheck += 1;
                        if (preserveCheck > 2) {
                            alert("아말감/GI/레진/금은 최대 2개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                    if (type === "simple" || type === "elaborate" || type === "ambush") {
                        extractCheck += 1;
                        if (extractCheck > 4) {
                            alert("단순/정교/완전매복은 최대 4개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                    if ( type === "implant" || type === "bridge" || type === "denture") {
                        implantBridgeDentureCheck += 1;
                        if (implantBridgeDentureCheck > 4) {
                            alert("임플란트/브릿지/틀니는 최대 4개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                }
            }
            const crownCheckboxes = document.querySelectorAll<HTMLInputElement>('input[type="checkbox"][id^="crown-"]');
            for (let i = 0; i < crownCheckboxes.length; i++) {
                const checkbox = crownCheckboxes[i];
                if (checkbox.checked) {
                    crownCheck += 1;
                    if (crownCheck > 2) {
                        alert("크라운은 최대 2개까지 선택할 수 있습니다.");
                        return;
                    }
                }
            }
        } else if (companyCode === 37) { //NH농협손해보험
            const selectTagsInfo = document.querySelectorAll('select');
            let intraoralPanoCheck = 0;
            let periodontalCheck = 0;
            let amalgamResinCheck = 0;
            let crownCheck = 0;
            let goldCheck = 0;
            let extractCheck = 0;
            for (let i = 0; i < selectTagsInfo.length; i++) {
                const selectTag = selectTagsInfo[i];
                const selectedOption = selectTag.options[selectTag.selectedIndex];
                if (selectedOption.id) {
                    let type = selectedOption.id.split("-")[0];
                    if (type === "gi") {
                        alert("해당 보험사에서는 GI를 선택할 수 없습니다.");
                        return;
                    }
                    if (type === "intraoral" || type === "panorama") {
                        intraoralPanoCheck += 1;
                        if (intraoralPanoCheck > 3) {
                            alert("방사선은 최대 3개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                    if (type === "curettage" || type === "planing") {
                        periodontalCheck += 1;
                        if (periodontalCheck > 3) {
                            alert("치주치료는 최대 3개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                    if (type === "amalgam" || type === "resin") {
                        amalgamResinCheck += 1;
                        if (amalgamResinCheck > 3) {
                            alert("아말감/레진은 최대 3개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                    if (type === "gold") {
                        goldCheck += 1;
                        if (goldCheck > 3) {
                            alert("금은 최대 3개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                    if (type === "simple" || type === "elaborate" || type === "ambush" || type === "implant" || type === "bridge" || type === "denture") {
                        extractCheck += 1;
                        if (extractCheck > 3) {
                            alert("단순/정교/완전매복/임플란트/브릿지/틀니는 최대 3개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                }
            }
            const crownCheckboxes = document.querySelectorAll<HTMLInputElement>('input[type="checkbox"][id^="crown-"]');
            for (let i = 0; i < crownCheckboxes.length; i++) {
                const checkbox = crownCheckboxes[i];
                if (checkbox.checked) {
                    crownCheck += 1;
                    if (crownCheck > 3) {
                        alert("크라운은 최대 3개까지 선택할 수 있습니다.");
                        return;
                    }
                }
            }
        } else if (companyCode === 19) { //동양생명보험
            const selectTagsInfo = document.querySelectorAll('select');
            let intraoralPanoCheck = 0;
            let periodontalCheck = 0;
            let amalgamResinCheck = 0;
            let crownCheck = 0;
            let goldCheck = 0;
            let extractCheck = 0;
            for (let i = 0; i < selectTagsInfo.length; i++) {
                const selectTag = selectTagsInfo[i];
                const selectedOption = selectTag.options[selectTag.selectedIndex];
                if (selectedOption.id) {
                    let type = selectedOption.id.split("-")[0];
                    if (type === "intraoral" || type === "panorama") {
                        intraoralPanoCheck += 1;
                        if (intraoralPanoCheck > 3) {
                            alert("방사선은 최대 3개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                    if (type === "curettage" || type === "planing") {
                        periodontalCheck += 1;
                        if (periodontalCheck > 3) {
                            alert("치주치료는 최대 3개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                    if (type === "amalgam" || type === "resin" || type === "gi") {
                        amalgamResinCheck += 1;
                        if (amalgamResinCheck > 4) {
                            alert("아말감/레진/GI는 최대 4개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                    if (type === "gold") {
                        goldCheck += 1;
                        if (goldCheck > 4) {
                            alert("금은 최대 4개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                    if (type === "simple" || type === "elaborate" || type === "ambush" || type === "implant" || type === "bridge" || type === "denture") {
                        extractCheck += 1;
                        if (extractCheck > 4) {
                            alert("단순/정교/완전매복/임플란트/브릿지/틀니는 최대 4개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                }
            }
            const crownCheckboxes = document.querySelectorAll<HTMLInputElement>('input[type="checkbox"][id^="crown-"]');
            for (let i = 0; i < crownCheckboxes.length; i++) {
                const checkbox = crownCheckboxes[i];
                if (checkbox.checked) {
                    crownCheck += 1;
                    if (crownCheck > 4) {
                        alert("크라운은 최대 4개까지 선택할 수 있습니다.");
                        return;
                    }
                }
            }
        } else if (companyCode === 12) { //흥국생명보험
            const selectTagsInfo = document.querySelectorAll('select');
            let extractionCheck = 0;
            let implantBridgeDentureCheck = 0;
            let crownGoldReginAmalgamGiCheck = 0;
            for (let i = 0; i < selectTagsInfo.length; i++) {
                const selectTag = selectTagsInfo[i];
                const selectedOption = selectTag.options[selectTag.selectedIndex];
                if (selectedOption.id) {
                    let type = selectedOption.id.split("-")[0];
                    if (type === "intraoral" || type === "panorama") {
                        alert("해당 보험사는 방사선을 선택할 수 없습니다.");
                        return;
                    }
                    if (type === "curettage" || type === "planing") {
                        alert("해당 보험사는 치주치료를 선택할 수 없습니다.");
                        return;
                    }
                    if (type === "implant" || type === "bridge" || type === "denture") {
                        implantBridgeDentureCheck += 1;
                        if (implantBridgeDentureCheck > 3) {
                            alert("임플란트/브릿지/틀니는 최대 3개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                    if (type === "amalgam" || type === "resin" || type === "gi" || type === "gold") {
                        crownGoldReginAmalgamGiCheck += 1;
                        if (crownGoldReginAmalgamGiCheck > 4) {
                            alert("아말감/레진/GI/금/크라운은 최대 4개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                    if (type === "simple" || type === "elaborate" || type === "ambush") {
                        extractionCheck += 1;
                        if (extractionCheck > 3) {
                            alert("단순/정교/완전매복은 최대 3개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                }
            }
            const crownCheckboxes = document.querySelectorAll<HTMLInputElement>('input[type="checkbox"][id^="crown-"]');
            for (let i = 0; i < crownCheckboxes.length; i++) {
                const checkbox = crownCheckboxes[i];
                if (checkbox.checked) {
                    crownGoldReginAmalgamGiCheck += 1;
                    if (crownGoldReginAmalgamGiCheck > 4) {
                        alert("아말감/레진/GI/금/크라운은 최대 4개까지 선택할 수 있습니다.");
                        return;
                    }
                }
            }
        } else if (companyCode === 3) { //라이나생명보험
            const selectTagsInfo = document.querySelectorAll('select');
            let intraoralPanoramaCheck = 0;
            let periodontalCheck = 0;
            let extractionCheck = 0;
            let implantBridgeDentureCheck = 0;
            let goldReginAmalgamGiCheck = 0;
            let crownCheck = 0;
            for (let i = 0; i < selectTagsInfo.length; i++) {
                const selectTag = selectTagsInfo[i];
                const selectedOption = selectTag.options[selectTag.selectedIndex];
                if (selectedOption.id) {
                    let type = selectedOption.id.split("-")[0];
                    if (type === "intraoral" || type === "panorama") {
                        intraoralPanoramaCheck += 1;
                        if (intraoralPanoramaCheck > 2) {
                            alert("방사선은 최대 2개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                    if (type === "curettage" || type === "planing") {
                        periodontalCheck += 1;
                        if (periodontalCheck > 3) {
                            alert("치주치료는 최대 3개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                    if (type === "implant" || type === "bridge" || type === "denture") {
                        implantBridgeDentureCheck += 1;
                        if (implantBridgeDentureCheck > 3) {
                            alert("임플란트/브릿지/틀니는 최대 3개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                    if (type === "amalgam" || type === "resin" || type === "gi" || type === "gold") {
                        goldReginAmalgamGiCheck += 1;
                        if (goldReginAmalgamGiCheck > 5) {
                            alert("아말감/레진/GI/금은 최대 5개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                    if (type === "simple" || type === "elaborate" || type === "ambush") {
                        extractionCheck += 1;
                        if (extractionCheck > 3) {
                            alert("단순/정교/완전매복은 최대 3개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                }
            }
            const crownCheckboxes = document.querySelectorAll<HTMLInputElement>('input[type="checkbox"][id^="crown-"]');
            for (let i = 0; i < crownCheckboxes.length; i++) {
                const checkbox = crownCheckboxes[i];
                if (checkbox.checked) {
                    crownCheck += 1;
                    if (crownCheck > 4) {
                        alert("크라운은 최대 4개까지 선택할 수 있습니다.");
                        return;
                    }
                }
            }
        }else if (companyCode === 14) { //신한라이프
            const selectTagsInfo = document.querySelectorAll('select');
            let panoramaCheck = 0;
            let periodontalCheck = 0;
            let extractionCheck = 0;
            let reginAmalgamGiCheck = 0;
            let crownCheck = 0;
            let goldCheck = 0;
            for (let i = 0; i < selectTagsInfo.length; i++) {
                const selectTag = selectTagsInfo[i];
                const selectedOption = selectTag.options[selectTag.selectedIndex];
                if (selectedOption.id) {
                    let type = selectedOption.id.split("-")[0];
                    if (type === "intraoral") {
                        alert("해당 보험사는 구내방사선을 선택할 수 없습니다.");
                        return;
                    }
                    if ( type === "panorama") {
                        panoramaCheck += 1;
                        if (panoramaCheck > 1) {
                            alert("파노라마는 최대 1개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                    if (type === "curettage" || type === "planing") {
                        periodontalCheck += 1;
                        if (periodontalCheck > 3) {
                            alert("치주치료는 최대 3개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                    if (type === "implant" || type === "bridge" || type === "denture" || type === "simple" || type === "elaborate" || type === "ambush") {
                        extractionCheck += 1;
                        if (extractionCheck > 3) {
                            alert("단순/정교/완전매복/임플란트/브릿지/틀니는 최대 3개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                    if (type === "amalgam" || type === "resin" || type === "gi") {
                        reginAmalgamGiCheck += 1;
                        if (reginAmalgamGiCheck > 3) {
                            alert("아말감/레진/GI는 최대 3개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                    if (type === "gold") {
                        goldCheck += 1;
                        if (goldCheck > 3) {
                            alert("금은 최대 3개까지 선택할 수 있습니다.");
                            return;
                        }
                    }
                }
            }
            const crownCheckboxes = document.querySelectorAll<HTMLInputElement>('input[type="checkbox"][id^="crown-"]');
            for (let i = 0; i < crownCheckboxes.length; i++) {
                const checkbox = crownCheckboxes[i];
                if (checkbox.checked) {
                    crownCheck += 1;
                    if (crownCheck > 3) {
                        alert("크라운은 최대 3개까지 선택할 수 있습니다.");
                        return;
                    }
                }
            }
        }

        setLoading(true);
        const info = JSON.parse(localStorage.getItem("user") as string);
        let dentalSignCustomImage = document.getElementById("dentalSignCustomImage") as HTMLInputElement;
        let doctorSignCustomImage = document.getElementById("doctorSignCustomImage") as HTMLInputElement;

        let resizeFile = (file: any) =>
            new Promise<File>((resolve) => {
                    Resizer.imageFileResizer(
                        file, // Blob
                        300, // maxWidth.
                        300,// maxHeight
                        "PNG",// Format.
                        100, // Quality 100 is max.
                        0,
                        (uri) => {
                            // @ts-ignore
                            return resolve(uri);
                        },
                        "file" // output type = base64 | blob | file
                    );
                }
            );
        let dental_stamp_path: string;
        let doctor_stamp_path: string;
        // @ts-ignore
        let file: File = dentalSignCustomImage.files[0];
        if (file != undefined) {
            file = await resizeFile(file);
            const blob = await removeBackground(file);
            file = new File([blob], file.name);

            const promises = [];

            let ext = file.name.substring(file.name.length, file.name.lastIndexOf('.')).toLowerCase();
            let timestamp = new Date().getTime();
            let containerFilePath = `${info.tel}_dentalSign_${timestamp}${ext}`;

            promises.push(azure.UploadInsuranceRequestAsync(file, containerFilePath));
            await Promise.all(promises);

            dental_stamp_path = containerFilePath;
        } else {
            dental_stamp_path = info.dental_stamp_path;
        }

        // @ts-ignore
        let file2 = doctorSignCustomImage.files[0];

        if (file2 != undefined) {
            file2 = await resizeFile(file2);

            const blob = await removeBackground(file2);
            file2 = new File([blob], file2.name);

            const promises = [];

            let ext = file2.name.substring(file2.name.length, file2.name.lastIndexOf('.')).toLowerCase();
            let timestamp = new Date().getTime();
            let containerFilePath = `${info.tel}_doctorSign_${timestamp}${ext}`;

            promises.push(azure.UploadInsuranceRequestAsync(file2, containerFilePath));
            await Promise.all(promises);

            doctor_stamp_path = containerFilePath;
        } else {
            doctor_stamp_path = info.doctor_stamp_path;
        }

        // 보철물
        let dentalTreatmentInfo: DentalTreatmentInfoRequest[] = [];
        let count: number;
        count = 0;
        const selectTags = document.querySelectorAll('select');
        await selectTags.forEach((selectTag) => {
            const selectedOption = selectTag.options[selectTag.selectedIndex];
            if (selectedOption.id) {
                let tooth_num = selectedOption.id.split("-")[1]
                let type = selectedOption.id.split("-")[0]
                let disease_code = (document.getElementById(`code-${tooth_num}`) as HTMLInputElement).value;
                let diagnosis_date = formatDate((document.getElementById(`diagnosis-${tooth_num}`) as HTMLInputElement).valueAsDate);
                let start_date = formatDate((document.getElementById(`start-${tooth_num}`) as HTMLInputElement).valueAsDate);
                dentalTreatmentInfo[count] = new DentalTreatmentInfoRequest(tooth_num, type, disease_code, diagnosis_date, start_date);
                count++;
            }
        });

        const crownCheckboxes = document.querySelectorAll<HTMLInputElement>('input[type="checkbox"][id^="crown-"]');
        await crownCheckboxes.forEach((checkbox) => {
            if (checkbox.checked) {
                let tooth_num = checkbox.id.split("-")[1];
                let type = checkbox.id.split("-")[0];
                let disease_code = (document.getElementById(`code-${tooth_num}`) as HTMLInputElement).value;
                let diagnosis_date = formatDate((document.getElementById(`diagnosis-${tooth_num}`) as HTMLInputElement).valueAsDate);
                let start_date = formatDate((document.getElementById(`start-${tooth_num}`) as HTMLInputElement).valueAsDate);
                dentalTreatmentInfo[count] = new DentalTreatmentInfoRequest(tooth_num, type, disease_code, diagnosis_date, start_date);
                count++;
            }
        });

        const checkboxTypes = ["defectStatus", "implantStatus", "bridgeStatus", "dentureStatus"];
        await checkboxTypes.forEach((type) => {
            const checkboxes = document.querySelectorAll<HTMLInputElement>(`input[type="checkbox"][id^="${type}-"]`);
            checkboxes.forEach((checkbox) => {
                if (checkbox.checked) {
                    const tooth_num = checkbox.id.split("-")[1];
                    const diagnosis_date = formatDate((document.getElementById(`diagnosis-${tooth_num}`) as HTMLInputElement).valueAsDate);
                    dentalTreatmentInfo[count++] = new DentalTreatmentInfoRequest(tooth_num, type, "", diagnosis_date, "");
                }
            });
        });


        let modifyRequest = new ModifyRequest(
            info.idx,
            info.name,
            info.account,
            info.tel,
            info.address,
            info.access_type,
            info.reg_date,
            info.expiration_date,
            info.main_logo_path_1,
            info.main_logo_path_2,
            info.main_logo_path_3,
            info.card_name,
            info.cardInfo,
            info.regular_payment_status,
            dental_stamp_path,
            doctor_stamp_path
        );

        let dentalUserInfoRequest = new DentalUserInfoRequest(
            name,
            requestId,
            number,
            hospital_name,
            doctor_name,
            license,
            hospitalPhone,
            companyCode
        )
        let dentalPdfRequest = new DentalPdfConstruction(modifyRequest, dentalUserInfoRequest, dentalTreatmentInfo);

        await axios.post(`${process.env.REACT_APP_API_URL_ENTRY}/dentalPDF`, JSON.stringify(dentalPdfRequest), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(async (res) => {
            const data = await AjaxClientV2.sendObjectAsync(`${process.env.REACT_APP_API_URL_ENTRY}/getUserAdminInfo`, {memberId: info.account.toString()});
            const result = JSON.parse(data.responseText);
            localStorage.setItem("user", JSON.stringify(result.responseData));
            if(res.data != null && res.data !=""){
                setLoading(false);
                alert("치과치료확인서 작성이 완료되었습니다.");
                // @ts-ignore
                setPdfURL("https://bocare.azureedge.net/insurancerequest/" + res.data);

                HandleAdminDentalConfirmResultRequestId(pdfURL, requestId, companyCode);
            }else{
                alert("치과치료확인서 작성에 문제가 발생하였습니다.");
                window.location.reload();
            }
        }).catch((err) => {
            console.log(err);
            alert("서버에서 문제가 발생하였습니다. 관리자에게 문의하여주세요.");
            window.location.reload();
        });
    }

    return (
        <ModalPortal>
            {loading ? <Loading/> : null}
            <Modal show={isOpen} size="xl">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel"><b>치과치료 확인서</b></h5>
                    <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    {companyCode === "" ? (
                    <div className="row">
                        <label className="fw-bold mb-2" htmlFor="insuranceCompanySelect">보험사</label>
                        <div className="col-xxl-2 col-6 btn">
                            <input type="radio" className="btn-check" name="insurance" id="nonLife" value="Yes"
                                   checked={insuranceType === 'nonLife'}
                                   onChange={() => setInsuranceType('nonLife')}/>
                            <label className="btn btn-outline-secondary w-100 py-2" htmlFor="nonLife"><i
                                className="bi bi-check"></i>손해보험</label>
                        </div>
                        <div className="col-xxl-2 col-6 btn">
                            <input type="radio" className="btn-check" name="insurance" id="life" value="No"
                                   checked={insuranceType === 'life'}
                                   onChange={() => setInsuranceType('life')}/>
                            <label className="btn btn-outline-secondary w-100 py-2" htmlFor="life"><i
                                className="bi bi-check"></i>생명보험</label>
                            <input id="fp_info_beneficiary" name="fp_info_beneficiary" type="hidden" value="Yes"/>
                        </div>
                        <div id="insuranceInfo" className={insuranceType === 'nonLife' ? '' : 'd-none'}>
                            <label className="fw-bold mt-3 mb-2">손해보험사 선택</label>
                            <select id="fp_insurance_nonLife" className="form-select py-3 me-xxl-4 mb-2 mb-xxl-0" aria-label="Default select example">
                                <option value="" selected>보험사선택</option>
                                <option value="32">DB손해보험</option>
                                <option value="31">KB손해보험</option>
                                <option value="37">NH농협손해보험</option>
                                <option value="24">메리츠화재</option>
                                <option value="30">현대해상화재</option>
                                <option value="25">한화손해보험</option>
                            </select>
                        </div>
                        <div id="insuranceInfo" className={insuranceType === 'life' ? '' : 'd-none'}>
                            <label className="fw-bold mt-3 mb-2">생명보험사 선택</label>
                            <select id="fp_insurance_life" className="form-select py-3 me-xxl-4 mb-2 mb-xxl-0" aria-label="Default select example">
                                <option value="" selected>보험사선택</option>
                                <option value="7">ABL생명</option>
                                <option value="19">동양생명</option>
                                <option value="3">라이나생명</option>
                                <option value="14">신한라이프</option>
                                <option value="11">한화생명</option>
                                <option value="12">흥국생명</option>
                            </select>
                        </div>
                    </div>
                    ) : null}
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label className="fw-bold mt-3 mb-2" htmlFor="name">이름</label>
                            <input type="text" className="form-control" id="name" placeholder="" required/>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="fw-bold mt-3 mb-2" htmlFor="number">주민번호</label>
                            <input type="text" className="form-control" id="number" maxLength={14}
                                   placeholder="- 기호 없이 입력해주세요"
                                   onInput={(event) => autoHyphenNumber(event.target as HTMLInputElement)}/>
                        </div>
                    </div>

                    <hr />
                    <div className="row mb-3">
                        <label className="fw-bold mb-2" htmlFor="selectTeeth">1. 초진 당시 치아결손 및 치아상태</label>
                        <table className="" id="teethTable">
                            <thead>
                            <tr>
                                <th colSpan={16}>치아 선택 (최대 {checkBoxMaxSize}개)
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map((cell, cellIndex) => (
                                        <td key={cellIndex} align="center">
                                            {typeof cell !== 'string' ? <input type="checkbox" style={{zoom: 2}}
                                                                               id={`checkbox-${cell}`}
                                                                               value={cell}
                                                                               onChange={handleCheckboxChange1}/> : cell}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="row mb-5">
                        <table id="teethTable">
                            <thead style={{backgroundColor: 'lightblue'}}>
                            <tr>
                                <th style={{width: '10%'}}>치아번호</th>
                                <th style={{width: '18%'}}>결손</th>
                                <th style={{width: '18%'}}>임플란트</th>
                                <th style={{width: '18%'}}>브릿지</th>
                                <th style={{width: '18%'}}>틀니</th>
                                <th style={{width: '18%'}}>진단일</th>
                            </tr>
                            </thead>
                            <tbody>
                            {selectedTeeth1.map((tooth, index) => ([
                                <tr>
                                    <input key={index} type="text" className="form-control" value={tooth}
                                           style={{textAlign: "center", marginTop:"0.1rem"}} readOnly/>
                                    <td key={`defect-${tooth}`}><input
                                        type="checkbox" id={`defectStatus-${tooth}`} value={`defectStatus-${tooth}`}
                                        style={{textAlign: "center"}}/></td>
                                    <td key={`implant-${tooth}`}><input
                                        type="checkbox" id={`implantStatus-${tooth}`}
                                        value={`implantStatus-${tooth}`}
                                        style={{textAlign: "center"}}/></td>
                                    <td key={`bridge-${tooth}`}><input
                                        type="checkbox" id={`bridgeStatus-${tooth}`} value={`bridgeStatus-${tooth}`}
                                        style={{textAlign: "center"}}/></td>
                                    <td key={`denture-${tooth}`}><input
                                        type="checkbox" id={`dentureStatus-${tooth}`}
                                        value={`dentureStatus-${tooth}`}
                                        style={{textAlign: "center"}}/></td>
                                    <td key={`diagnosis-${tooth}`}><input
                                        type="date" id={`diagnosis-${tooth}`}
                                        style={{textAlign: "center"}}/></td>
                                </tr>
                            ]))}
                            <tr>
                                <td colSpan={6}>
                                    <i className="bi bi-plus-circle me-1"></i>치아 선택 시, 항목이 추가됩니다.
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <hr />
                    <div className="row">
                        <label className="fw-bold mb-2" htmlFor="selectTeeth2">2. 치료 내용</label>
                        <table id="teethTable">
                            <thead>
                            <tr>
                                <th colSpan={16}>치아 선택 (최대 {checkBoxMaxSize}개)
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map((cell, cellIndex) => (
                                        <td key={cellIndex} align="center">
                                            {typeof cell !== 'string' ? <input type="checkbox" style={{zoom: 2}}
                                                                               id={`checkbox2-${cell}`}
                                                                               value={cell}
                                                                               onChange={handleCheckboxChange2}/> : cell}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
`
                    <div className="row mb-5">
                        <table id="teethTable">
                            <thead style={{backgroundColor: 'lightblue'}}>
                            <tr>
                                <th style={{width:"8%"}}>치아번호</th>
                                <th>발치</th>
                                <th>치주치료</th>
                                <th>방사선</th>
                                <th style={{width:"8%"}}>충전치료</th>
                                <th style={{width:"8%"}}>크라운</th>
                                <th>보철</th>
                                <th style={{width:"12%"}}>질병코드</th>
                                <th style={{width:"12%"}}>진단일</th>
                                <th style={{width:"12%"}}>치료일</th>
                            </tr>
                            </thead>
                            <tbody>
                            {selectedTeeth2.map((tooth, index) => ([
                                <tr style={{whiteSpace: "nowrap"}}>
                                    <input key={index} type="text" className="form-control" value={tooth}
                                           style={{textAlign: "center", marginTop:"0.25rem"}} readOnly/>
                                    <td key={`extraction-${tooth}`}>
                                        <select id={`extractionCmb-${tooth}`} name={`extractionCmb-${tooth}`}
                                                style={{textAlign: "center"}}>
                                            <option value={"none"}>없음</option>
                                            <option value={"simple"} id={`simple-${tooth}`}>단순</option>
                                            <option value={"elaborate"} id={`elaborate-${tooth}`}>정교</option>
                                            <option value={"ambush"} id={`ambush-${tooth}`}>완전매복</option>
                                        </select>
                                    </td>
                                    <td key={`pulp-${tooth}`}>
                                        <select name={`pulpCmb-${tooth}`} style={{textAlign: "center"}}>
                                            <option value={"none"}>없음</option>
                                            <option value={"curettage"} id={`curettage-${tooth}`}>치주소파술</option>
                                            <option value={"planing"} id={`planing-${tooth}`}>치근활택술</option>
                                        </select>

                                    </td>
                                    <td key={`radiation-${tooth}`}>
                                        <select name={`radiationCmb-${tooth}`} style={{textAlign: "center"}}>
                                            <option value={"none"}>없음</option>
                                            <option value={"intraoral"} id={`intraoral-${tooth}`}>구내방사선</option>
                                            <option value={"panorama"} id={`panorama-${tooth}`}>파노라마</option>
                                        </select>
                                    </td>
                                    <td key={`charging-${index}`}>
                                        <select name={`chargingCmb-${tooth}`} style={{textAlign: "center"}}>
                                            <option value={"none"}>없음</option>
                                            <option value={"amalgam"} id={`amalgam-${tooth}`}>아말감</option>
                                            <option value={"gi"} id={`gi-${tooth}`}>GI</option>
                                            <option value={"resin"} id={`resin-${tooth}`}>레진</option>
                                            <option value={"gold"} id={`gold-${tooth}`}>금</option>
                                        </select>
                                    </td>
                                    <td key={`crown-${tooth}`}><input
                                        type="checkbox" id={`crown-${tooth}`}
                                        style={{textAlign: "center"}}/>
                                    </td>
                                    <td key={`prosthetic-${tooth}`}>
                                        <select name={`prostheticCmb-${tooth}`} style={{textAlign: "center"}}>
                                            <option value={"none"}>없음</option>
                                            <option value={"implant"} id={`implant-${tooth}`}>임플란트</option>
                                            <option value={"denture"} id={`denture-${tooth}`}>틀니</option>
                                            <option value={"bridge"} id={`bridge-${tooth}`}>브릿지</option>
                                        </select>
                                    </td>
                                    <td key={`code-${tooth}`}><input type="text"
                                                                     id={`code-${tooth}`}
                                                                     style={{textAlign: "center", width: "80%"}}/>
                                    </td>
                                    <td key={`diagnosis-${tooth}`}><input
                                        type="date" id={`diagnosis-${tooth}`}
                                        style={{textAlign: "center"}}/></td>
                                    <td key={`start-${tooth}`}><input
                                        type="date"
                                        id={`start-${tooth}`}
                                        style={{textAlign: "center"}}/>
                                    </td>
                                </tr>
                            ]))}
                            <tr>
                                <td colSpan={10}>
                                    <i className="bi bi-plus-circle me-1"></i>치아 선택 시, 항목이 추가됩니다.
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <hr />
                    <div className="row">
                        <label className="fw-bold mt-3 mb-2" htmlFor="treatmentContent2">* 상기 질환으로 인하여 치료하였음을 확인합니다. </label>
                        <div className="col-3 mb-2">
                            <label className="fw-bold mb-2" htmlFor="hospitalName">병원명</label>
                            <input type="text" className="form-control" id="hospitalName" placeholder="" required/>
                        </div>
                        <div className="col-3 mb-2">
                            <label className="fw-bold mb-2" htmlFor="doctorName">의사명</label>
                            <input type="text" className="form-control" id="doctorName"/>
                        </div>
                        <div className="col-3 mb-2">
                            <label className="fw-bold mb-2" htmlFor="license">면허번호</label>
                            <input type="text" className="form-control" id="license"/>
                        </div>
                        <div className="col-3 mb-2">
                            <label className="fw-bold mb-2" htmlFor="hospitalPhone">병원 전화번호</label>
                            <input type="text" className="form-control" id="hospitalPhone"  value={hospitalPhone} onChange={hospitalPhoneHandleChange}  placeholder="- 기호 없이 입력해주세요"/>
                        </div>
                        <div className="col-6">
                            <label htmlFor="hospitalStamp" className={"fw-bold mb-2 centered-label"}>병원 직인</label>
                        </div>
                        <div className="col-6">
                            <label htmlFor="hospitalStamp" className={"fw-bold mb-2 centered-label"}>의사 직인</label>
                        </div>
                        <div className="col-6">
                            <div className="col-10 fs-6">
                                <div className="input-group mt-1">
                                    <input type="file" id="dentalSignCustomImage" name="dentalSignCustomImage"
                                           className="form-control" accept=".png, .jpg, .jpeg"
                                           onChange={() => imagePreview("dentalSignCustomImage", "dentalSignCustomImage_pre")}/>
                                </div>
                                <div className="input-group mt-1">
                                    <img id="dentalSignCustomImage_pre" className="img-dental" style={{
                                        backgroundColor: 'black',
                                        color: '#ffffff',
                                        width: 200,
                                        height: 200
                                    }} alt="도장을 등록해주세요"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="col-10 fs-6">
                                <div className="input-group mt-1">
                                    <input type="file" id="doctorSignCustomImage" name="doctorSignCustomImage"
                                           className="form-control" accept=".png, .jpg, .jpeg"
                                           onChange={() => imagePreview("doctorSignCustomImage", "doctorSignCustomImage_pre")}/>
                                </div>
                                <div className="input-group mt-1">
                                    <img id="doctorSignCustomImage_pre" className="img-doctor" style={{
                                        backgroundColor: 'black',
                                        color: '#ffffff',
                                        width: 200,
                                        height: 200
                                    }} alt="도장을 등록해주세요"/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                {adminDentalConfirmResultModalOpen && (
                    <ModalPortal>
                        <AdminDentalConfirmResultModal onClose={HandleAdminDentalConfirmResultModalShow} isOpen={true}
                                                       pdfURL={pdfURL} requestId={requestId} companyCode={companyCode}/>
                    </ModalPortal>
                )}


                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={onClose}>닫기</button>
                    <button type="button" className="btn btn-primary" onClick={() => makeDentalConfirm()}>작성완료</button>
                </div>

            </Modal>
        </ModalPortal>
    );
}

export default AdminDentalConfirmModal;
