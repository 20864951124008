import axios from "axios";

const TIME_OUT = 300*1000; // 5 minutes

// 에러 처리를 위한 status 선언
const statusError = {
    status: false,
    json: {
        error: ["연결이 원활하지 않습니다. 잠시 후 다시 시도해 주세요"]
    }
};

// 백으로 요청할 promis
const requestPromise = (url:any, option:any, credentials:any) => {
    console.log(url);
    return axios.post(url, credentials, option);
};

// promise 타임아웃 처리
const timeoutPromise = () => {
    return new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), TIME_OUT));
};

// promise 요청
const getPromise = async (url:any, option:any, credentials:any) => {
    return await Promise.race([
        requestPromise(url, option, credentials),
        timeoutPromise()
    ]);
};

export const adminLoginUser = async(credentials:any) => {
    const option = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access-Control-Allow-Origin': '*'
        },
        withCredentials: true
    };

    const loginUrl = process.env.REACT_APP_API_URL_ENTRY + '/admin_login';

    const data:any = await getPromise(loginUrl, option, credentials).catch(() => {
        return statusError;
    });

    console.log(data);

    if (parseInt(String(Number(data.status) / 100))===2) {
        const status = data.data.status;
        const code = data.data.status;
        const headers = data.headers;
        const responseData = data.data;
        return {
            status,
            code,
            responseData,
            headers
        };
    } else {
        return statusError;
    }
}

// 백으로 로그인 요청
export const loginUser = async (credentials:any) => {
    const option = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access-Control-Allow-Origin': '*'
        },
        withCredentials: true
    };

    const loginUrl = process.env.REACT_APP_API_URL_ENTRY + '/login';

    const data:any = await getPromise(loginUrl, option, credentials).catch(() => {
        return statusError;
    });

    console.log(data);

    if (parseInt(String(Number(data.status) / 100))===2) {
        const status = data.data.status;
        const code = data.data.status;
        const headers = data.headers;
        const responseData = data.data;
        return {
            status,
            code,
            responseData,
            headers
        };
    } else {
        return statusError;
    }
};

export const logoutUser = async (credentials:any, accessToken:any) => {
    const option = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(credentials)
    };

    const loginOutUrl:URL = new URL('/loginout', process.env.REACT_APP_API_URL_ENTRY as string);

    const data:any = await getPromise(loginOutUrl, option, credentials).catch(() => {
        return statusError;
    });

    if (parseInt(String(Number(data.status) / 100))===2) {
        const status = data.ok;
        const code = data.status;
        const text = await data.text();
        const json = text.length ? JSON.parse(text) : "";

        return {
            status,
            code,
            json
        };
    } else {
        return statusError;
    }
}

// 백으로 회원가입 요청
export const createUser = async (credentials:any) => {
    const option = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access-Control-Allow-Origin': '*'
        },
        withCredentials: true
    };

    const loginUrl = process.env.REACT_APP_API_URL_ENTRY + '/createAccount';

    try {
        const data: any = await getPromise(loginUrl, option, credentials);

        if (parseInt(String(Number(data.status) / 100)) === 2) {
            const { status, data: responseData, headers } = data;
            alert("성공적으로 가입되었습니다.\n3일 무료이용권이 발급되었습니다.");
            return {
                status,
                code: status,
                responseData,
                headers,
            };
        } else {
            return statusError;
        }
    } catch (error:any) {
        alert(error.response?.data);
        return error.response?.data;
    }
};