import Footer from "./Footer/Footer";
import React from "react";
import 'globalthis/auto';
import DentalHeader from "./Header/DentalHeader";

const DentalLayout = (props : {children: React.ReactNode}) => {
    return (
        <div>
            <DentalHeader/>

            <main>
                {props.children}
            </main>

            <Footer/>
        </div>
    );
}

export default DentalLayout;