import '../../components/css/kiosk_common.css';
import ModalPortal from "../../Layout/Modal/ModalPortal";
import Modal from 'react-bootstrap/Modal';

const CreditLogModal = ({ onClose, isOpen }: any) => {
    return (
        <ModalPortal>
            <Modal show={isOpen} size="xl">
                <div className="modal-content">
                    <div className="modal-header bg-light">
                        <div className="modal-title fs-2" id="list03Label">개인정보의 제3자 제공 (선택 동의)
                        </div>
                    </div>
                    <div className="modal-body text-start fs-4 p-4">
                        보케어는 가입자의 동의 없이 고객님의 개인정보를 제3자에게 제공하거나 유출하지 않습니다.<br />
                        다만, 관계 법령의 규정에 의거하거나 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우, 정보통신윤리위원회의 요청이 있는 경우, 특정개인을 알아볼 수 없는 형태로 가공하여 제공하는 경우에는 가입자의 동의 없이 개인정보를 제공할 수 있습니다.<br /><br />
                        1) 제공받는 자 : [고객님이 가입한 보험회사]<br />
                        - 목적 : 보험금 청구 대행<br />
                        - 내용 : 가입보험사,계약자명 ,휴대폰 번호,계약자 주민등록번호, 피보험자명, 피보험자 주민등록번호,예금주,사고유형,병원명, 진료과, 전자서명,진단서 및 영수증등 의료제증명 문서 서류<br />
                        - 보유∙이용기간 : 제공 기관의 목적 달성시까지<br /><br />
                        2) 제공받는 자 : 보험설계사<br />
                        - 목적 : 보험상담, 보험금청구<br />
                        - 내용 : 이름, 성별, 연락처, 생년월일, 병원명, 가입보험회사<br />
                        - 보유∙이용기간 : 계약 종료 시<br /><br />
                        3) 제공받는 자 : 연계 병원 명 내 의료제증명 관련 전산업체<br />
                        - 목적 : 병원진료기록 확인 및 결제<br />
                        - 내용 : 이름, 주민등록번호, 전화번호 외 환자구분코드 등<br />
                        - 보유∙이용기간 : 제공 기관의 목적 달성시까지<br /><br />
                        -부칙-<br />
                        기존 개인정보보호정책은 개인정보처리방침으로 개정합니다.<br />
                        현 개인정보처리방침의 수정, 변경이 있을 시 최소 7일 이전에 사이트 내 공지를 통하여 고지할 것입니다.<br />
                        개인정보처리방침 시행일자 : 2019년 01월 01일<br />
                        이 약관은 2023년 05월 01일부터 시행하며 종전의 약관은 이 약관으로 대체합니다.<br />
                    </div>
                    <div className="modal-footer w-100">
                        <button type="button" className="btn btn-secondary py-3 w-100 fs-4"
                            onClick={onClose}>확인
                        </button>
                    </div>
                </div>
            </Modal>
        </ModalPortal>
    );
}

export default CreditLogModal;