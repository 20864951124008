import AdminLayout from "../Layout/AdminLayout";
import '../components/css/admin_dashboard.css';
import '../components/css/admin_dashboard_rtl.css';
import {useEffect, useState} from "react";
import {AzureBlobStorage} from "../AzureStorage/AzureStorage";
import axios from "axios";
import {AjaxClientV2} from "../components/util/AjaxClientV2";
import Loading from "../components/loading/Loading";

function AdminManagementKiosk() {
    const azure = new AzureBlobStorage("kioskmain");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const info = JSON.parse(localStorage.getItem("user") as string);

        let defaultImageRadio = document.getElementById("defaultImageRadio") as HTMLInputElement;
        let customImageRadio = document.getElementById("customImageRadio") as HTMLInputElement;
        // let customImage: any = document.getElementById("customImage") as HTMLInputElement;

        if (info.main_logo_path_1 === "" || info.main_logo_path_1 == null) {
            defaultImageRadio.checked = true;
        } else {
            customImageRadio.checked = true;
        }
        // if(customImage.files.length > 0) {
        //     customImage.files.forEach((e:any) => {
        //         console.log(e);
        //     });
        // } else {
        //     console.log(info.main_logo_path_1);
        //     console.log(info.main_logo_path_2);
        //     console.log(info.main_logo_path_3);
        // }
    }, [])

    const dentistPaperHandleLinkClick = (event: { preventDefault: () => void; }) => {
        // @ts-ignore
        const info = JSON.parse(localStorage.getItem("user"));
        if (info.access_type === "dentistPaper") {
            event.preventDefault(); // 기본 동작(페이지 이동)을 막음
            alert("이용이 불가능한 서비스입니다. 이용을 원하시면 고객센터(1566-4875)로 연락주시기 바랍니다."); // 알림 창 표시
        }
    };

    const defaultHandleLinkClick = (event: { preventDefault: () => void; }) => {
        // @ts-ignore
        const info = JSON.parse(localStorage.getItem("user"));
        if (info.access_type === "default") {
            event.preventDefault(); // 기본 동작(페이지 이동)을 막음
            alert("이용이 불가능한 서비스입니다. 이용을 원하시면 고객센터(1566-4875)로 연락주시기 바랍니다."); // 알림 창 표시
        }
    };

    const changeRadio = async () => {
        let customImage: any = document.getElementById("customImage") as HTMLInputElement;
        let customImageRadio = document.getElementById("customImageRadio") as HTMLInputElement;
        let defaultImageRadio = document.getElementById("defaultImageRadio") as HTMLInputElement;
        if (customImage.files.length !== 0) {
            customImageRadio.checked = true;
        } else {
            defaultImageRadio.checked = true;
        }
    }

    const modify = async () => {
        setLoading(true);
        const info = JSON.parse(localStorage.getItem("user") as string);
        let customImage:any = document.getElementById("customImage") as HTMLInputElement;
        let customImageRadio = document.getElementById("customImageRadio") as HTMLInputElement;
        let obj: any = {};
        obj = {
            "name": info.name,
            "tel": info.tel,
            "account": info.account,
            "address": info.address,
            "expiration_date": info.expiration_date,
            "regular_payment_status": info.regular_payment_status,
            "dental_stamp_path": info.dental_stamp_path,
            "doctor_stamp_path": info.doctor_stamp_path,
            "card_name":info.card_name,
            "card_info":info.card_info,
            "hospital_name":info.hospital_name,
            "doctor_name":info.doctor_name,
            "license_number":info.license_number,
            "hospital_phone":info.hospital_phone,
        };

        obj.main_logo_path_1 = "";
        obj.main_logo_path_2 = "";
        obj.main_logo_path_3 = "";
        if(customImageRadio.checked == true) {
            let files = customImage.files;
            if (files.length > 0) {
                for(let i=0; i<customImage.files.length;i++) {
                    let promises = [];

                    let ext = files[i].name.substring(files[i].name.length, files[i].name.lastIndexOf('.')).toLowerCase();
                    let timestamp = new Date().getTime();
                    let containerFilePath = `${info.tel}_main_${i+1}_${timestamp}${ext}`;

                    promises.push(azure.UploadInsuranceRequestAsync(files[i], containerFilePath));
                    await Promise.all(promises);

                    obj[`main_logo_path_${i+1}`] = containerFilePath;
                }
            } else {
                obj.main_logo_path_1 = info.main_logo_path_1;
                obj.main_logo_path_2 = info.main_logo_path_2;
                obj.main_logo_path_3 = info.main_logo_path_3;
            }
        }


        await axios.post(`${process.env.REACT_APP_API_URL_ENTRY}/modifyAdmin`, JSON.stringify(obj), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(async (res) => {
            const data = await AjaxClientV2.sendObjectAsync(`${process.env.REACT_APP_API_URL_ENTRY}/getUserAdminInfo`, {memberId: info.account.toString()});
            const result = JSON.parse(data.responseText);

            localStorage.setItem("user", JSON.stringify(result.responseData));
            alert("수정이 완료되었습니다.");
            window.location.reload();
        }).catch((err) => {
            console.log(err);
            alert("서버에서 문제가 발생하였습니다. 관리자에게 문의하여주세요.");
        });
    }

    return (
        <AdminLayout>
            {loading ? <Loading/> : null}
            <div className="container-fluid">
                <div className="row">
                    <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
                        <div className="position-sticky pt-3">
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <a className="nav-link" aria-current="page" href="/admin/main/"
                                       onClick={dentistPaperHandleLinkClick}>
                                        <span data-feather="home"></span>
                                        보험금청구
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page"
                                       href="/admin/management/kiosk/" onClick={dentistPaperHandleLinkClick}>
                                        <span data-feather="home"></span>
                                        키오스크 관리
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" aria-current="page"
                                       href="/admin/management/member/">
                                        <span data-feather="home"></span>
                                        회원관리
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" aria-current="page"
                                       href="/admin/dental/" onClick={defaultHandleLinkClick}>
                                        <span data-feather="home"></span>
                                        치과치료확인서
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </nav>

                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                        <div
                            className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h1 className="h2">키오스크 관리</h1>
                        </div>
                        <div className="row">
                            <div className="col-12  mb-3">
                                <div className="card p-3">
                                    <div className="fs-5 fw-bold pb-3 border-bottom">메인페이지</div>
                                    <div className="row mt-3">
                                        <div className="col-2 fs-6 fw-bold  align-self-center">이미지</div>
                                        <div className="col-10 fs-6">
                                            <div className="form-check mb-2">
                                                <input className="form-check-input" type="radio"
                                                       name="mainImageSelectRadio"
                                                       id="defaultImageRadio" value="default" checked/>
                                                <label className="form-check-label" htmlFor="mainImageSelectRadio">
                                                    기본 이미지(제공 이미지)
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" value="custom"
                                                       name="mainImageSelectRadio"
                                                       id="customImageRadio"/>
                                                <label className="form-check-label" htmlFor="mainImageSelectRadio">
                                                    이미지 첨부(최대 3장) (기본 사이즈 : 가로1080px x 세로1720px)
                                                </label>
                                                <div className="input-group mt-1">
                                                    <input type="file" id="customImage" name="customImage"
                                                           className="form-control" onChange={() => {changeRadio()}}
                                                           multiple/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex pb-5">
                            <div className="ms-auto">
                                <button type="button" onClick={() => window.history.back()}
                                        className="btn btn-outline-secondary me-2">취소
                                </button>
                                <button onClick={() => modify()} className="btn btn-primary">저장</button>
                            </div>
                        </div>

                    </main>
                </div>
            </div>
        </AdminLayout>
    );
}

export default AdminManagementKiosk;