import '../../components/css/kiosk_common.css';
import ModalPortal from "../../Layout/Modal/ModalPortal";
import Modal from 'react-bootstrap/Modal';

const CreditInsModal = ({ onClose, isOpen }: any) => {
    return (
        <ModalPortal>
            <Modal show={isOpen} size="xl">
                <div className="modal-content">
                    <div className="modal-header bg-light">
                        <div className="modal-title fs-2" id="list01Label">개인정보 처리 목적(필수 동의)</div>
                    </div>
                    <div className="modal-body text-start fs-4 p-4">
                        보케어는 자사에서 제공하는 보험금 청구 서비스에 대한 이용자의 원활한 서비스 이용을 위해 아래와 같은 목적으로만 개인정보를 수집합니다.<br /><br />
                        수집하는 개인정보는 보험금 자동 청구 서비스 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제 18조등 에 따라 별도의 사전 동의를 구할 것입니다.<br /><br />
                        1) 목적 : 서비스 신청, 계약 이행 및 요금 정산<br />
                        - 내용 : 보험금 청구 서비스 제공(보험금 신청) 서비스 이용을 위한 필요 정보의 전달 등<br />
                        2) 목적 : 고객 관리<br />
                        - 내용 : 고객 본인확인, 개인식별, 불량 고객의 부정이용 방지와 비인가 사용 방지, 연령확인, 불만처리 등 민원처리, 고객 응대 및 상담, 고지사항 전달<br /><br />
                        -부칙-<br />
                        기존 개인정보보호정책은 개인정보처리방침으로 개정합니다.<br />
                        현 개인정보처리방침의 수정, 변경이 있을 시 최소 7일 이전에 사이트 내 공지를 통하여 고지할 것입니다.<br />
                        개인정보처리방침 시행일자 : 2019년 01월 01일<br />
                        이 약관은 2023년 05월 01일부터 시행하며 종전의 약관은 이 약관으로 대체합니다.<br />
                    </div>
                    <div className="modal-footer w-100">
                        <button type="button" className="btn btn-secondary py-3 w-100 fs-4"
                            onClick={onClose}>확인
                        </button>
                    </div>
                </div>
            </Modal>
        </ModalPortal>
    );
}

export default CreditInsModal;