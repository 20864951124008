export class ModifyRequest {
    constructor(
        public idx: number,
        public account: string,
        public name: string,//
        public tel: string,//
        public address: string,//
        public access_type: string,
        public main_logo_path_1: string,//
        public main_logo_path_2: string,//
        public main_logo_path_3: string,//
        public expiration_date: string,//
        public reg_date: string,
        public card_name: string,//
        public card_info: string,//
        public regular_payment_status: string,//
        public dental_stamp_path: string,//
        public doctor_stamp_path: string,//
    ) {
    }
}