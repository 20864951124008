import ModalPortal from "../../Layout/Modal/ModalPortal";
import Modal from 'react-bootstrap/Modal';
import '../../components/css/admin_dashboard.css';
import '../../components/css/admin_dashboard_rtl.css';
import {useEffect, useState} from "react";
import {
    getAdminInsuranceDataByIdx, getAdminInsuranceDataByIdxWithAttachedPdf, getAdminInsuranceDataByIdxWithPdf,
} from "../AdminData";
import Loading from "../../components/loading/Loading";
import {AzureBlobStorage} from "../../AzureStorage/AzureStorage";

const AdminResultModal = ({ onClose, isOpen, requestId } : any) => {
    const azure = new AzureBlobStorage("insurancerequest");
    const [showImages, setShowImages] = useState([]);
    const [showPdf, setShowPdf] = useState([]);
    const [showAttachedPdf, setShowAttachedPdf] = useState([]);
    const [uploadList, setUploadList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reclaimList, setReclaimList] = useState<any[]>([]);
    const [reclaimFileList, setReclaimFileList] = useState<any[]>([]);

    useEffect(() => {
        const closeXModal = document.getElementById("closeXModal") as HTMLInputElement;
        closeXModal.disabled = true;

        const closeButtonModal = document.getElementById("closeButtonModal") as HTMLInputElement;
        closeButtonModal.disabled = true;

        const concatButton = document.getElementById("concatButton") as HTMLInputElement;
        concatButton.disabled = true;

        setLoading(true);
        // requestId를 사용하여 청구내역을 불러옵니다.
        fetchAdminInsuranceDataByIdx(requestId);

        fetchAdminInsuranceDataByIdx(requestId)
            .then(r => {
                console.log("success");
                closeXModal.disabled = false;
                closeButtonModal.disabled = false;
                concatButton.disabled = false;
                setLoading(false);
            });
    }, []);

    const handleAddPreviousImage = (files : FileList) => {
        const imageLists = files;
        let imageUrlLists:any = [...showImages];
        let fileLists:any = [...uploadList];

        for (let i = 0; i < imageLists.length; i++) {
            const currentImageUrl:any = URL.createObjectURL(imageLists[i]);
            imageUrlLists.push(currentImageUrl);
            fileLists.push(imageLists[i]);
        }

        setUploadList(fileLists);
        setShowImages(imageUrlLists);

    }

    const handleAddPreviousAttachedPdf = (files : FileList) => {
        const attachedPdf = files;
        let attachedPdfUrlLists:any = [...showAttachedPdf];
        let fileLists:any = [...uploadList];

        for (let i = 0; i < attachedPdf.length; i++) {
            const currentImageUrl:any = URL.createObjectURL(attachedPdf[i]);
            attachedPdfUrlLists.push(currentImageUrl);
            fileLists.push(attachedPdf[i]);
        }

        setUploadList(fileLists);
        setShowAttachedPdf(attachedPdfUrlLists);

    }

    const handleAddPreviousPdf = (files : FileList) => {
        const pdf = files;
        let pdfUrlLists:any = [...showPdf];
        let fileLists:any = [...uploadList];

        for (let i = 0; i < pdf.length; i++) {
            const currentImageUrl:any = URL.createObjectURL(pdf[i]);
            pdfUrlLists.push(currentImageUrl);
            fileLists.push(pdf[i]);
        }

        setUploadList(fileLists);
        setShowPdf(pdfUrlLists);
    }

    // 청구내역을 불러오는 함수
    async function fetchAdminInsuranceDataByIdx(requestId: any) {
        const response = await getAdminInsuranceDataByIdx(requestId);
        const requestData = response.data.responseData.requestInfo;
        // requestData를 배열로 변환
        const dataArray = requestData ? [requestData] : [];
        setReclaimList(dataArray);
        setReclaimFileList(response.data.responseData.requestFileInfo);

        const dataTransfer = new DataTransfer();
        response.data.responseData.requestFileInfo.forEach((fileInfo : any) => {
            //base64 to file
            let bstr = atob(fileInfo.base64Data),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }

            const file = new File([u8arr], fileInfo.fileName, { type:"image/gif"});

            dataTransfer.items.add(file);
        });

        //Pdf response 가져오기
        const responsePdf = await getAdminInsuranceDataByIdxWithPdf(requestId);
        const requestDataPdf = responsePdf.data.responseData.requestInfo;
        // requestData를 배열로 변환
        const dataPdfArray = requestDataPdf ? [requestDataPdf] : [];
        setReclaimList(dataPdfArray);
        setReclaimFileList(responsePdf.data.responseData.requestFileInfo);

        const dataPdfTransfer = new DataTransfer();
        responsePdf.data.responseData.requestFileInfo.forEach((fileInfo : any) => {
            //base64 to file
            let bstr = atob(fileInfo.base64Data),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }

            const file = new File([u8arr], fileInfo.fileName, { type:"application/pdf"});

            dataPdfTransfer.items.add(file);
        });

        //첨부파일 Pdf response 가져오기
        const responseAttachedPdf = await getAdminInsuranceDataByIdxWithAttachedPdf(requestId);
        const requestDataAttachedPdf = responseAttachedPdf.data.responseData.requestInfo;
        // requestData를 배열로 변환
        const dataAttahcedPdfArray = requestDataAttachedPdf ? [requestDataAttachedPdf] : [];
        setReclaimList(dataAttahcedPdfArray);
        setReclaimFileList(responseAttachedPdf.data.responseData.requestFileInfo);

        const dataAttachedPdfTransfer = new DataTransfer();
        responseAttachedPdf.data.responseData.requestFileInfo.forEach((fileInfo : any) => {
            //base64 to file
            let bstr = atob(fileInfo.base64Data),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }

            const file = new File([u8arr], fileInfo.fileName, { type:"application/pdf"});

            dataAttachedPdfTransfer.items.add(file);
        });

        let fileAttachElement = document.getElementById("fileAttach") as HTMLInputElement;

        fileAttachElement.files = dataTransfer.files;
        fileAttachElement.files = dataPdfTransfer.files;
        fileAttachElement.files = dataAttachedPdfTransfer.files;

        setReclaimFileList(reclaimFileList);

        handleAddPreviousImage(dataTransfer.files);
        // handleAddPreviousImage(dataPdfTransfer.files);
        handleAddPreviousPdf(dataPdfTransfer.files);
        handleAddPreviousAttachedPdf(dataAttachedPdfTransfer.files);
    }
    // @ts-ignore
        return (
            <ModalPortal>
                {loading ? <Loading/> : null}
                <Modal show={isOpen} size="xl">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">청구 상세내역</h5>
                        <button type="button" id="closeXModal" className="btn-close" onClick={onClose}
                                aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        {
                            reclaimList.map((reclaimList:any) => (
                                <table className="table table-striped table-hover table-sm">
                                    <thead>
                                    <tr key={"row1"}>
                                        <th scope="col">접수번호</th>
                                        <th scope="col">{reclaimList.idx}번</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr key={"row2"}>
                                        <th scope="row">보험사</th>
                                        <td>{reclaimList.companyName}</td>
                                    </tr>
                                    <tr key={"row3"}>
                                        <th scope="row">팩스 번호</th>
                                        <td>{reclaimList.faxNumber}</td>
                                    </tr>
                                    <tr key={"row4"}>
                                        <th scope="row">사고유형</th>
                                        <td>
                                       {reclaimList.accidentType === 'disease' ? '질병' :
                                        reclaimList.accidentType === 'accident' ? '상해' :
                                        reclaimList.accidentType === 'carAccident' ? '교통사고' : ''}
                                        </td>
                                    </tr>
                                    <tr key={"row3"}>
                                        <th scope="row">사고경위</th>
                                        <td>{reclaimList.accidentDetail === null ? "자료첨부" : reclaimList.accidentDetail}</td>
                                    </tr>
                                    <tr key={"row5"}>
                                        <th scope="row">신청인</th>
                                        <td>{reclaimList.insurerName}</td>
                                    </tr>
                                    <tr key={"row6"}>
                                        <th scope="row">신청인 연락처</th>
                                        <td>{reclaimList.insurerPhone}</td>
                                    </tr>
                                    <tr key={"row7"}>
                                        <th scope="row">신청인 주민번호</th>
                                        <td>{reclaimList.insurerNumber}</td>
                                    </tr>
                                    <tr key={"row8"}>
                                        <th scope="row">수익자</th>
                                        <td>{reclaimList.beneficiaryName}</td>
                                    </tr>
                                    <tr key={"row9"}>
                                        <th scope="row">수익자 연락처</th>
                                        <td>{reclaimList.beneficiaryPhone}</td>
                                    </tr>
                                    <tr key={"row10"}>
                                        <th scope="row">수익자 주민번호</th>
                                        <td>{reclaimList.beneficiaryNumber}</td>
                                    </tr>
                                    <tr key={"row11"}>
                                        <th scope="row">은행명</th>
                                        <td>{reclaimList.bankName}</td>
                                    </tr>
                                    <tr key={"row12"}>
                                        <th scope="row">계좌번호</th>
                                        <td>{reclaimList.bankNumber}</td>
                                    </tr>
                                    <tr key={"row13"}>
                                        <th scope="row">예금주</th>
                                        <td>{reclaimList.bankHolder}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            ))
                        }
                        <div className="input-group mb-3">
                            <div className="fw-bold fs-6 w-100 mb-1">
                                <h4><i className={"bi bi-folder2-open"}></i> 청구 서류</h4>
                            </div>
                            <br/>
                        </div>
                            <div><input type="file" className="form-control" id="fileAttach" name="fileAttach" hidden/>
                                <button type={"button"} id={"concatButton"} className={"btn btn-pink"} hidden>
                                    <i className={"bi bi-cloud-arrow-down-fill"}></i>  파일 합친 후 내려받기
                                </button>
                            </div>
                        <div id="noDataDiv"
                             className="col-md-12 text-center text-secondary card px-3 py-5 mt-3"
                             style={{display: "none"}}>
                            <div id="" className="fs-2 mb-2">첨부하신 파일이 없습니다.</div>
                        </div>

                        <div id="file_wrapper" className="row">
                            {showImages.map((image, id) => (
                                <div className="col-4 mb-3" key={id}>
                                    <div className="border card h-100 p-3">
                                        <div className="w-100 d-flex flex-wrap h-100 text-center">
                                            <div className="w-100 align-self-center">
                                                <img
                                                    className="img-fluid"
                                                    src={image}
                                                    onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                                        const target = e.target as HTMLImageElement;
                                                        target.src = 'https://bocare.co.kr/v2/views/crm/insurance_inquiry/images/notImageFile.png';
                                                    }}
                                                    alt={`${image}-${id}`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {showPdf.map((pdf, id) => (
                                <div className="col-4 mb-3" key={id}>
                                    <div className="border card h-100 p-3">
                                        <div className="w-100 d-flex flex-wrap h-100 text-center">
                                            <div className="w-100 align-self-center">
                                                <img
                                                    className="img-fluid"
                                                    src={pdf}
                                                    onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                                        const target = e.target as HTMLImageElement;
                                                        target.src = 'https://bocare.co.kr/v2/views/crm/insurance_inquiry/images/notImageFile.png';
                                                    }}
                                                    alt={`${pdf}-${id}`}
                                                />
                                                <br/>
                                                <br/>
                                                <div><a href={`${pdf}`} className={"btn btn-info"}>청구서 PDF 파일 열기</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {showAttachedPdf.map((pdf, id) => (
                                <div className="col-4 mb-3" key={id}>
                                    <div className="border card h-100 p-3">
                                        <div className="w-100 d-flex flex-wrap h-100 text-center">
                                            <div className="w-100 align-self-center">
                                                <img
                                                    className="img-fluid"
                                                    src={pdf}
                                                    onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                                        const target = e.target as HTMLImageElement;
                                                        target.src = 'https://bocare.co.kr/v2/views/crm/insurance_inquiry/images/notImageFile.png';
                                                    }}
                                                    alt={`${pdf}-${id}`}
                                                />
                                                <br/>
                                                <br/>
                                                <div><a href={`${pdf}`} className={"btn btn-warning"}>첨부서류 PDF 열기</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" id="closeButtonModal" className="btn btn-secondary" onClick={onClose}>닫기
                        </button>

                    </div>
                </Modal>
            </ModalPortal>
        );
    }
export default AdminResultModal;