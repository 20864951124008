import AdminLayout from "../Layout/AdminLayout";
import '../components/css/admin_dashboard.css';
import '../components/css/admin_dashboard_rtl.css';
import {useEffect, useState} from "react";
import {AzureBlobStorage} from "../AzureStorage/AzureStorage";
import axios from "axios";
import {AjaxClientV2} from "../components/util/AjaxClientV2";
import {removeBackground} from "@imgly/background-removal"
import Loading from "../components/loading/Loading";
import Resizer from "react-image-file-resizer";

function AdminManagementKiosk() {
    const [loading, setLoading] = useState(false);
    const azure = new AzureBlobStorage("kioskmain");
    const [hospitalPhone, setHospitalPhoneNumber] = useState<string>('');

    useEffect(() => {
        const info = JSON.parse(localStorage.getItem("user") as string);

        let member_name = document.getElementById("member_name") as HTMLSpanElement;
        let member_usedate = document.getElementById("member_usedate") as HTMLSpanElement;
        let member_account = document.getElementById("member_account") as HTMLSpanElement;

        let card_name = document.getElementById("card_name") as HTMLInputElement;
        let card_number_front = document.getElementById("card_number_first") as HTMLInputElement;
        let card_number_second = document.getElementById("card_number_second") as HTMLInputElement;
        let card_number_third = document.getElementById("card_number_third") as HTMLInputElement;
        let card_number_fourth = document.getElementById("card_number_fourth") as HTMLInputElement;
        let vaild_year = document.getElementById("vaild_year") as HTMLInputElement;
        let vaild_month = document.getElementById("vaild_month") as HTMLInputElement;
        let divide_month = document.getElementById("divide_month") as HTMLSelectElement;
        let regular_payment_status = document.getElementById("regular_payment_status") as HTMLSelectElement;

        let hospital_name = document.getElementById("hospital_name") as HTMLInputElement;
        let doctor_name = document.getElementById("doctor_name") as HTMLInputElement;
        let license_number = document.getElementById("license_number") as HTMLInputElement;
        let hospital_phone = document.getElementById("hospital_phone") as HTMLInputElement;

        let dental_image = document.getElementById("dentalSignCustomImage_pre") as HTMLImageElement;
        let doctor_image = document.getElementById("doctorSignCustomImage_pre") as HTMLImageElement;
        dental_image.src = "";
        doctor_image.src = "";
        if (info.dental_stamp_path != undefined) {
            dental_image.src = `https://bocare.azureedge.net/kioskmain/${info.dental_stamp_path}`;
        }
        if (info.doctor_stamp_path != undefined) {
            doctor_image.src = `https://bocare.azureedge.net/kioskmain/${info.doctor_stamp_path}`;
        }

        member_name.innerHTML = info.name;
        member_usedate.innerHTML = info.expiration_date;
        member_account.innerHTML = info.account;

        if (info.card_info != null && info.card_info != "") {
            card_name.value = info.card_name;
            let cardInfo = info.card_info.split("/");
            let cardNumber = cardInfo[0].split("-");
            let cardVaild = cardInfo[1].split("-");
            let divideMonth = cardInfo[2];

            card_number_front.value = cardNumber[0];
            card_number_second.value = cardNumber[1];
            card_number_third.value = cardNumber[2];
            card_number_fourth.value = cardNumber[3];

            vaild_year.value = cardVaild[0];
            vaild_month.value = cardVaild[1];

            divide_month.value = divideMonth;
            regular_payment_status.value = info.regular_payment_status;
        }

        if(info.hospital_name != undefined){
            hospital_name.value = info.hospital_name;
        }
        if(info.doctor_name != undefined){
            doctor_name.value = info.doctor_name;
        }
        if(info.license_number != undefined){
            license_number.value = info.license_number;
        }
        if(info.hospital_phone != undefined){
            hospital_phone.value = info.hospital_phone;
        }
    }, [])

    const imagePreview = async (fileElement: any, imageElement: any) => {
        let fileTag = document.getElementById(fileElement) as HTMLInputElement;
        let imageTag = document.getElementById(imageElement) as HTMLImageElement;

        let fileReader = new FileReader();
        // @ts-ignore
        fileReader.readAsDataURL(fileTag.files[0]);
        fileReader.onloadend = () => {
            imageTag.src = fileReader.result as string;
        }
    }

    const dentistPaperHandleLinkClick = (event: { preventDefault: () => void; }) => {
        // @ts-ignore
        const info = JSON.parse(localStorage.getItem("user"));
        if (info.access_type === "dentistPaper") {
            event.preventDefault(); // 기본 동작(페이지 이동)을 막음
            alert("이용이 불가능한 서비스입니다. 이용을 원하시면 고객센터(1566-4875)로 연락주시기 바랍니다."); // 알림 창 표시
        }
    };

    const defaultHandleLinkClick = (event: { preventDefault: () => void; }) => {
        // @ts-ignore
        const info = JSON.parse(localStorage.getItem("user"));
        if (info.access_type === "default") {
            event.preventDefault(); // 기본 동작(페이지 이동)을 막음
            alert("이용이 불가능한 서비스입니다. 이용을 원하시면 고객센터(1566-4875)로 연락주시기 바랍니다."); // 알림 창 표시
        }
    };

    const hospitalPhoneHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let formattedNumber = e.target.value.replace(/\D/g, ''); // 숫자만 추출
        formattedNumber = formattedNumber.slice(0, 11); // 최대 11자리로 제한
        // 전화번호 형식에 따라 하이픈 추가
        if (formattedNumber.length >= 9) {
            // 국번이 있을 경우
            formattedNumber = formattedNumber.replace(/(\d{2,3})(\d{3,4})(\d{4})/, '$1-$2-$3');
        } else if (formattedNumber.length >= 5) {
            // 국번이 없는 경우
            formattedNumber = formattedNumber.replace(/(\d{3,4})(\d{0,4})/, '$1-$2');
        }
        // 상태 업데이트
        setHospitalPhoneNumber(formattedNumber);
    };


    const modify = async () => {
        setLoading(true);
        const info = JSON.parse(localStorage.getItem("user") as string);

        let dental_image = document.getElementById("dentalSignCustomImage_pre") as HTMLImageElement;
        let doctor_image = document.getElementById("doctorSignCustomImage_pre") as HTMLImageElement;

        dental_image.src = `https://bocare.azureedge.net/kioskmain/${info.tel}_dentalSign.png`;
        doctor_image.src = `https://bocare.azureedge.net/kioskmain/${info.tel}_doctorSign.png`;

        let dentalSignCustomImage = document.getElementById("dentalSignCustomImage") as HTMLInputElement;
        let doctorSignCustomImage = document.getElementById("doctorSignCustomImage") as HTMLInputElement;
        let card_name = document.getElementById("card_name") as HTMLInputElement;
        let card_number_first = document.getElementById("card_number_first") as HTMLInputElement;
        let card_number_second = document.getElementById("card_number_second") as HTMLInputElement;
        let card_number_third = document.getElementById("card_number_third") as HTMLInputElement;
        let card_number_fourth = document.getElementById("card_number_fourth") as HTMLInputElement;
        let vaild_year = document.getElementById("vaild_year") as HTMLInputElement;
        let vaild_month = document.getElementById("vaild_month") as HTMLInputElement;
        let divide_month = document.getElementById("divide_month") as HTMLSelectElement;
        let regular_payment_status = document.getElementById("regular_payment_status") as HTMLSelectElement;

        let hospital_name = document.getElementById("hospital_name") as HTMLInputElement;
        let doctor_name = document.getElementById("doctor_name") as HTMLInputElement;
        let license_number = document.getElementById("license_number") as HTMLInputElement;
        let hospital_phone = document.getElementById("hospital_phone") as HTMLInputElement;

        let obj: any = {};

        obj = {
            "name": info.name,
            "tel": info.tel,
            "account": info.account,
            "address": info.address,
            "expiration_date": info.expiration_date,
            "main_logo_path_1": info.main_logo_path_1,
            "main_logo_path_2": info.main_logo_path_2,
            "main_logo_path_3": info.main_logo_path_3,
        }


        if (card_name.value != "" && card_number_first.value != "" && card_number_second.value != "" && card_number_third.value != "" && card_number_fourth.value != "" && vaild_year.value != "" && vaild_month.value != "") {
            let cardInfo = `${card_number_first.value}-${card_number_second.value}-${card_number_third.value}-${card_number_fourth.value}/${vaild_year.value}-${vaild_month.value}/${divide_month.value}`;
            obj.card_name = card_name.value;
            obj.card_info = cardInfo;
            obj.regular_payment_status = regular_payment_status.value;
        }

        if(hospital_name.value != ""){
            obj.hospital_name = hospital_name.value;
        }
        if(doctor_name.value != ""){
            obj.doctor_name = doctor_name.value;
        }
        if(license_number.value != ""){
            obj.license_number = license_number.value;
        }
        if(hospital_phone.value != ""){
            obj.hospital_phone = hospital_phone.value;
        }


        let resizeFile = (file: any) =>
            new Promise<File>((resolve) => {
                    Resizer.imageFileResizer(
                        file, // Blob
                        300, // maxWidth.
                        300,// maxHeight
                        "PNG",// Format.
                        100, // Quality 100 is max.
                        0,
                        (uri) => {
                            // @ts-ignore
                            return resolve(uri);
                        },
                        "file" // output type = base64 | blob | file
                    );
                }
            );

        // @ts-ignore
        let file: File = dentalSignCustomImage.files[0];
        if (file != undefined) {
            file = await resizeFile(file);

            const blob = await removeBackground(file);
            file = new File([blob], file.name);

            const promises = [];

            let ext = file.name.substring(file.name.length, file.name.lastIndexOf('.')).toLowerCase();
            let timestamp = new Date().getTime();
            let containerFilePath = `${info.tel}_dentalSign_${timestamp}${ext}`;

            promises.push(azure.UploadInsuranceRequestAsync(file, containerFilePath));
            await Promise.all(promises);

            obj.dental_stamp_path = containerFilePath;
        } else {
            obj.dental_stamp_path = info.dental_stamp_path;
        }

        // @ts-ignore
        let file2 = doctorSignCustomImage.files[0];

        if (file2 != undefined) {
            file2 = await resizeFile(file2);

            const blob = await removeBackground(file2);
            file2 = new File([blob], file2.name);

            const promises = [];

            let ext = file2.name.substring(file2.name.length, file2.name.lastIndexOf('.')).toLowerCase();
            let timestamp = new Date().getTime();
            let containerFilePath = `${info.tel}_doctorSign_${timestamp}${ext}`;

            promises.push(azure.UploadInsuranceRequestAsync(file2, containerFilePath));
            await Promise.all(promises);

            obj.doctor_stamp_path = containerFilePath;
        } else {
            obj.doctor_stamp_path = info.doctor_stamp_path;
        }

        await axios.post(`${process.env.REACT_APP_API_URL_ENTRY}/modifyAdmin`, JSON.stringify(obj), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(async (res) => {
            const data = await AjaxClientV2.sendObjectAsync(`${process.env.REACT_APP_API_URL_ENTRY}/getUserAdminInfo`, {memberId: info.account.toString()});
            const result = JSON.parse(data.responseText);

            localStorage.setItem("user", JSON.stringify(result.responseData));
            alert("수정이 완료되었습니다.");
            window.location.reload();
        }).catch((err) => {
            console.log(err);
            alert("서버에서 문제가 발생하였습니다. 관리자에게 문의하여주세요.");
        });


    }


    return (
        <AdminLayout>
            {loading ? <Loading/> : null}
            <div className="container-fluid">
                <div className="row">
                    <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
                        <div className="position-sticky pt-3">
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <a className="nav-link" aria-current="page" href="/admin/main/" onClick={dentistPaperHandleLinkClick}>
                                        <span data-feather="home"></span>
                                        보험금청구
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" aria-current="page"
                                       href="/admin/management/kiosk/" onClick={dentistPaperHandleLinkClick}>
                                        <span data-feather="home"></span>
                                        키오스크 관리
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page"
                                       href="/admin/management/member/">
                                        <span data-feather="home"></span>
                                        회원관리
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" aria-current="page"
                                       href="/admin/dental/" onClick={defaultHandleLinkClick}>
                                        <span data-feather="home"></span>
                                        치과치료확인서
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </nav>

                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                        <div
                            className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h1 className="h2">회원 정보 수정</h1>
                        </div>
                        <div className="col-12 mb-3">
                            <div className="card p-3">
                                <div className="fs-5 fw-bold pb-3 border-bottom">가입 정보</div>

                                <table className="table">
                                    <tbody>
                                    <tr>
                                        <th scope="row">가입자</th>
                                        <td><span id="member_name">김원장</span></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">이용기간</th>
                                        <td><span id="member_usedate">2023.05.24. 00:00</span> 까지</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">결제 정보(월 결제)</th>
                                        <td>
                                            {/*<span id="card_name">비씨카드</span><br/><span id="card_info">0000-0000-0000-0000/28/04</span><br/>*/}
                                            <div className="mb-3">
                                                <label className="me-3">카드사</label>
                                                <input type="text" id="card_name" className=""
                                                       placeholder="ex)신한카드"/>
                                            </div>

                                            <div className="mb-3">
                                                <label className="me-3">카드번호</label>
                                                <input type="text" id="card_number_first" className="me-2"
                                                       placeholder="ex)1111"/>
                                                <input type="text" id="card_number_second" className="me-2"
                                                       placeholder="ex)2222"/>
                                                <input type="text" id="card_number_third" className="me-2"
                                                       placeholder="ex)3333"/>
                                                <input type="text" id="card_number_fourth" className="me-2"
                                                       placeholder="ex)4444"/>
                                            </div>

                                            <div className="mb-3">
                                                <label className="me-3">유효기간</label>
                                                <input type="text" id="vaild_year" className="me-2"
                                                       placeholder="년"/>
                                                <input type="text" id="vaild_month" className="me-2"
                                                       placeholder="월"/>
                                            </div>

                                            <div className="mb-3">
                                                <label className="me-3">할부개월</label>
                                                <select id="divide_month">
                                                    <option value="일시불" selected>일시불</option>
                                                    <option value="2개월">2개월</option>
                                                    <option value="3개월">3개월</option>
                                                </select>
                                            </div>

                                            <div className="mb-3">
                                                <label className="me-3">매월 결제유무</label>
                                                <select id="regular_payment_status">
                                                    <option value="Y">Y</option>
                                                    <option value="N" selected>N</option>
                                                </select>
                                            </div>
                                            ※ 결제 카드 정보는 고객센터로 연락주시면 변경 가능합니다.<br/>
                                            ※ 해당 이용료(10만원)은 월 200건의 기본 사용료입니다. 추가 건수는 건당 500원의 사용료가 추가됩니다.<br/>
                                            ※ 이용기간 마지막날 자동 결제되며 해당 영수증은 입력해주신 이메일로 발송됩니다.
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">계정</th>
                                        <td><span id="member_account">akak@naver.com</span></td>
                                    </tr>
                                    </tbody>
                                </table>
                                ※ 궁금하신 사항은 보케어 고객센터(1566-4875)로 연락주시기 바랍니다.<br/>
                                ※ 해당 태블릿 무상 A/S기간은 1년이며 이후에는 유상으로 진행됩니다.
                            </div>
                        </div>
                        <div className="col-12  mb-3">
                            <div className="card p-3">
                                <div className="fs-5 fw-bold pb-3 border-bottom">치과 정보</div>
                                <table className="table">
                                    <tbody>
                                    <tr>
                                        <th scope="row" style={{width: '14%'}}>병원명</th>
                                        <td><input type="text" id="hospital_name" className="me-2"/></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">의사명</th>
                                        <td><input type="text" id="doctor_name" className="me-2"/></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">면허번호</th>
                                        <td><input type="text" id="license_number" className="me-2"/></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">병원 전화번호</th>
                                        <td><input type="text" id="hospital_phone" className="me-2" value={hospitalPhone} onChange={hospitalPhoneHandleChange}  placeholder="숫자만 입력해주세요"/></td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div className="row mt-3">
                                    <div className="col-2 fs-6 fw-bold  align-self-center">병원 직인 이미지</div>
                                    <div className="col-10 fs-6">
                                        <div className="input-group mt-1">
                                            <input type="file" id="dentalSignCustomImage" name="dentalSignCustomImage"
                                                   className="form-control"
                                                   onChange={() => imagePreview("dentalSignCustomImage", "dentalSignCustomImage_pre")}/>
                                        </div>
                                        <div className="input-group mt-1">
                                            <img id="dentalSignCustomImage_pre" className="img-dental" style={{
                                                backgroundColor: 'black',
                                                color: '#ffffff',
                                                width: 200,
                                                height: 200
                                            }} alt="이미지가 없습니다"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-2 fs-6 fw-bold  align-self-center">의사 직인 이미지</div>
                                    <div className="col-10 fs-6">
                                        <div className="input-group mt-1">
                                            <input type="file" id="doctorSignCustomImage" name="doctorSignCustomImage"
                                                   className="form-control"
                                                   onChange={() => imagePreview("doctorSignCustomImage", "doctorSignCustomImage_pre")}/>
                                        </div>
                                        <div className="input-group mt-1">
                                            <img id="doctorSignCustomImage_pre" className="img-doctor" style={{
                                                backgroundColor: 'black',
                                                color: '#ffffff',
                                                width: 200,
                                                height: 200
                                            }} alt="이미지가 없습니다"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex pb-5">
                            <div className="ms-auto">
                                <button type="button" onClick={() => window.history.back()}
                                        className="btn btn-outline-secondary me-2">취소
                                </button>
                                <button onClick={() => modify()} className="btn btn-primary">저장</button>
                            </div>
                        </div>

                    </main>
                </div>
            </div>
        </AdminLayout>
    );
}

export default AdminManagementKiosk;