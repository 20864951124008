import Layout from "../Layout/Layout";
import {useLocation, useNavigate} from "react-router-dom";
import {useState} from "react";

function WriteBankInfo(){
    let navigate = useNavigate();
    let loc = useLocation();
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event:any) => {
        let bankName = document.getElementById("bank_name") as HTMLInputElement;
        let bankNumber = document.getElementById("bank_number") as HTMLInputElement;
        let bankHolder = document.getElementById("bank_holder") as HTMLInputElement;

        if(loc.state.selectInfo.length > 1) {
            alert("보험사 한 개 선택 시에만 가능합니다.");
            return;
        } else {
            //'자동이체가능' 보험사
            //메리츠화재, DB손해보험, KB손해보험, MG손해보험, NH농협손해보험, 롯데손해보험, 흥국화재, 현대해상화재, 한화손해보험
            let companyName = loc.state.selectInfo[0].name;
            if(companyName != "메리츠화재" && companyName != "DB손해보험" && companyName != "KB손해보험" && companyName != "MG손해보험" &&
                companyName != "NH농협손해보험" && companyName != "롯데손해보험" && companyName != "흥국화재" && companyName != "현대해상화재" &&
                companyName != "한화손해보험") {
                alert("자동이체 불가능한 보험사입니다.");
                return;
            }
        }

        let isChecked = event.target.checked;
        setIsChecked(isChecked);
        if(isChecked) {
            bankName.disabled = true;
            bankNumber.disabled = true;
            bankHolder.disabled = true;
        }

        if(!isChecked) {
            bankName.disabled = false;
            bankNumber.disabled = false;
            bankHolder.disabled = false;
        }
    };

    const nextPage = () => {
        let bankName = document.getElementById("bank_name") as HTMLInputElement;
        let bankNumber = document.getElementById("bank_number") as HTMLInputElement;
        let bankHolder = document.getElementById("bank_holder") as HTMLInputElement;

        if(bankName.value === "은행명을 선택하세요." && bankName.disabled == false){
            alert("은행명을 선택해주세요.");
            bankName.focus();
            return;
        }

        if(bankNumber.value === ""  && bankNumber.disabled == false){
            alert("계좌번호를 입력해주세요.");
            bankNumber.focus();
            return;
        }

        if(bankHolder.value === "" && bankHolder.disabled == false){
            alert("예금주 성함을 입력해주세요.");
            bankHolder.focus();
            return;
        }

        let bankInfo = {
            bankName: bankName.value,
            bankNumber: bankNumber.value,
            bankHolder: bankHolder.value
        }

        //체크 시 전달할 값 널스트링
        if(isChecked) {
            bankInfo = {
                bankName: "자동이체계좌",
                bankNumber: "",
                bankHolder: ""
            }
        }
        navigate("/chooseSendType", {
            state: {
                selectInfo: loc.state.selectInfo,
                accidentType: loc.state.accidentType,
                insurerInfo: loc.state.insurerInfo,
                accidentDetail: loc.state.accidentDetail,
                beneficiaryExist: loc.state.beneficiaryExist,
                beneficiaryInfo: loc.state.beneficiaryInfo,
                bankInfo: bankInfo,
            }
        });
    }

    return(
        <Layout>
            <main className="container main_pd text-center">
                <div className="fs-0_5 fw-bold">보험금 청구</div>
                <div className="fs-4 mb-5">보험사선택 - <span className="fw-bold fs-3 text-pink">청구서작성(5/5)</span> - 병원서류첨부 -
                    청구내용확인
                </div>
                <div id="openAccidentCause">
                    <div className="fs-2 fw-bold text-start mb-2">
                        <i className="bi bi-check2-circle me-2"></i>보험금을 받으실 계좌 정보를 입력하세요.</div>
                </div>
                <div className="d-flex">
                    <div className="fs-4 fw-bold mb-2"></div>
                    <div className="ms-auto">
                        <input id={"attachFile"} type={"checkbox"} checked={isChecked} onChange={handleCheckboxChange} className={"form-check-input me-2 p-3"} />
                        <span className="fs-4 fw-bold">자동이체</span>
                    </div>
                </div>
                <div className="py-5">
                    <select id="bank_name" name="bank_name" className="form-control py-6 mb-4 kiosk-input write-input">
                        <option value={"은행명을 선택하세요."}>은행명을 선택하세요.</option>
                        <option key={"산업은행"} value={"산업은행"}>산업은행</option>
                        <option key={"기업은행"} value={"기업은행"}>기업은행</option>
                        <option key={"국민은행"} value={"국민은행"}>국민은행</option>
                        <option key={"수협은행"} value={"수협은행"}>수협은행</option>
                        <option key={"수출입은행"} value={"수출입은행"}>수출입은행</option>
                        <option key={"농협은행"} value={"농협은행"}>농협은행</option>
                        <option key={"지역 농축협"} value={"지역 농축협"}>지역 농축협</option>
                        <option key={"우리은행"} value={"우리은행"}>우리은행</option>
                        <option key={"SC제일은행"} value={"SC제일은행"}>SC제일은행</option>
                        <option key={"한국씨티은행"} value={"한국씨티은행"}>한국씨티은행</option>
                        <option key={"대구은행"} value={"대구은행"}>대구은행</option>
                        <option key={"부산은행"} value={"부산은행"}>부산은행</option>
                        <option key={"광주은행"} value={"광주은행"}>광주은행</option>
                        <option key={"제주은행"} value={"제주은행"}>제주은행</option>
                        <option key={"전북은행"} value={"전북은행"}>전북은행</option>
                        <option key={"경남은행"} value={"경남은행"}>경남은행</option>
                        <option key={"새마을금고중앙회"} value={"새마을금고중앙회"}>새마을금고중앙회</option>
                        <option key={"신협"} value={"신협"}>신협</option>
                        <option key={"저축은행"} value={"저축은행"}>저축은행</option>
                        <option key={"HSBC은행"} value={"HSBC은행"}>HSBC은행</option>
                        <option key={"도이치은행"} value={"도이치은행"}>도이치은행</option>
                        <option key={"JP모간체이스은행"} value={"JP모간체이스은행"}>JP모간체이스은행</option>
                        <option key={"BOA은행"} value={"BOA은행"}>BOA은행</option>
                        <option key={"비엔피파리바은행"} value={"비엔피파리바은행"}>비엔피파리바은행</option>
                        <option key={"중국공상은행"} value={"중국공상은행"}>중국공상은행</option>
                        <option key={"중국은행"} value={"중국은행"}>중국은행</option>
                        <option key={"산림조합중앙회"} value={"산림조합중앙회"}>산림조합중앙회</option>
                        <option key={"중국건설은행"} value={"중국건설은행"}>중국건설은행</option>
                        <option key={"우체국"} value={"우체국"}>우체국</option>
                        <option key={"KEB하나은행"} value={"KEB하나은행"}>KEB하나은행</option>
                        <option key={"신한은행"} value={"신한은행"}>신한은행</option>
                        <option key={"케이뱅크"} value={"케이뱅크"}>케이뱅크</option>
                        <option key={"카카오뱅크"} value={"카카오뱅크"}>카카오뱅크</option>
                        <option key={"토스뱅크"} value={"토스뱅크"}>토스뱅크</option>
                        <option key={"유안타증권"} value={"유안타증권"}>유안타증권</option>
                        <option key={"KB증권"} value={"KB증권"}>KB증권</option>
                        <option key={"골든브릿지투자증권"} value={"골든브릿지투자증권"}>골든브릿지투자증권</option>
                        <option key={"한양증권"} value={"한양증권"}>한양증권</option>
                        <option key={"리딩투자증권"} value={"리딩투자증권"}>리딩투자증권</option>
                        <option key={"BNK투자증권"} value={"BNK투자증권"}>BNK투자증권</option>
                        <option key={"IBK투자증권"} value={"IBK투자증권"}>IBK투자증권</option>
                        <option key={"KTB투자증권"} value={"KTB투자증권"}>KTB투자증권</option>
                        <option key={"미래에셋대우"} value={"미래에셋대우"}>미래에셋대우</option>
                        <option key={"삼성증권"} value={"삼성증권"}>삼성증권</option>
                        <option key={"한국투자증권"} value={"한국투자증권"}>한국투자증권</option>
                        <option key={"NH투자증권"} value={"NH투자증권"}>NH투자증권</option>
                        <option key={"교보증권"} value={"교보증권"}>교보증권</option>
                        <option key={"하이투자증권"} value={"하이투자증권"}>하이투자증권</option>
                        <option key={"현대차증권"} value={"현대차증권"}>현대차증권</option>
                        <option key={"키움증권"} value={"키움증권"}>키움증권</option>
                        <option key={"이베스트투자증권"} value={"이베스트투자증권"}>이베스트투자증권</option>
                        <option key={"에스케이증권"} value={"에스케이증권"}>에스케이증권</option>
                        <option key={"대신증권"} value={"대신증권"}>대신증권</option>
                        <option key={"한화투자증권"} value={"한화투자증권"}>한화투자증권</option>
                        <option key={"하나금융투자"} value={"하나금융투자"}>하나금융투자</option>
                        <option key={"토스증권"} value={"토스증권"}>토스증권</option>
                        <option key={"신한금융투자"} value={"신한금융투자"}>신한금융투자</option>
                        <option key={"DB금융투자"} value={"DB금융투자"}>DB금융투자</option>
                        <option key={"유진투자증권"} value={"유진투자증권"}>유진투자증권</option>
                        <option key={"메리츠종합금융증권"} value={"메리츠종합금융증권"}>메리츠종합금융증권</option>
                        <option key={"카카오페이증권"} value={"카카오페이증권"}>카카오페이증권</option>
                        <option key={"부국증권"} value={"부국증권"}>부국증권</option>
                        <option key={"신영증권"} value={"신영증권"}>신영증권</option>
                        <option key={"케이프투자증권"} value={"케이프투자증권"}>케이프투자증권</option>
                        <option key={"한국증권금융"} value={"한국증권금융"}>한국증권금융</option>
                        <option key={"한국포스증권"} value={"한국포스증권"}>한국포스증권</option>
                        <option key={"우리종합금융"} value={"우리종합금융"}>우리종합금융</option>
                    </select>
                    <input id="bank_number" name="bank_number" className="form-control py-6 mb-4 kiosk-input write-input" type="text"
                           placeholder="'-'없이 숫자만 입력"
                           />
                    <input id="bank_holder" name="bank_holder" className="form-control py-6 mb-4 kiosk-input write-input" type="text"
                           placeholder="예금주 성함"
                           />
                </div>
                <div className="footer-fix">
                    <div className="d-flex container">
                        {/* eslint-disable-next-line no-restricted-globals */}
                        <button type="button" className="btn btn-secondary my-4 px-5 py-6 fs-3 w-100 me-4" onClick={() => history.back()}><i
                            className="bi bi-arrow-left-square me-2"></i>아니오 [뒤로가기]
                        </button>
                        <button type="button" className="btn btn-pink my-4 px-5 py-6 fs-3 w-100 ms-4" onClick={() => nextPage()}><i
                            className="bi bi-check2-circle me-2"></i>네
                            [청구서작성]
                        </button>
                    </div>
                </div>
            </main>
        </Layout>
    );
}

export default WriteBankInfo;