import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useNavigate} from "react-router-dom";
function AutoPlayMethods() {
    let navigate = useNavigate();
    let sliderRef:any = useRef(null);
    const play = () => {
        sliderRef.current.slickPlay();
    };
    const pause = () => {
        sliderRef.current.slickPause();
    };
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000
    };
    const nextPage = () => {
        return navigate("/insuranceMain");
    }
    return (
        <div className="slider-container">
            <Slider ref={sliderRef} {...settings} className={"my-5"}>
                <div>
                    <img id="main_image_1" src="/image/main_title.svg" className="main_1" style={{width:"100%"}} alt="..."
                    onClick={nextPage}/>
                </div>
                <div>
                    <img id="main_image_2" src="/image/main_title.svg" className="main_2" style={{width:"100%"}} alt="..."
                    onClick={nextPage}/>
                </div>
                <div>
                    <img id="main_image_3" src="/image/main_title.svg" className="main_3" style={{width:"100%"}} alt="..."
                    onClick={nextPage}/>
                </div>
            </Slider>
            <div style={{textAlign:"center"}}>
                <button className="btn btn-outline-secondary me-2" onClick={play}>
                    Play
                </button>
                <button className="btn btn-outline-secondary me-2" onClick={pause}>
                    Pause
                </button>
            </div>
        </div>
    );
}
export default AutoPlayMethods;