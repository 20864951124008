import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import InsuranceMain from "./Main/InsuranceMain";
import Agree from "./Agree/Agree";
import SelectCompany from "./Select/SelectCompany";
import VirtualFax from "./virtualFax/VirtualFax";
import Write from "./Write/Write";
import Choose from "./Choose/Choose";
import ChooseBeneficiary from "./Write/ChooseBeneficiary";
import ChooseSendType from "./Transmit/ChooseSendType";
import Complete from "./Complete/Complete";
import Introduce from "./Introduce/Introduce";
import Final from './Complete/Final';
import WriteBeneficiary from "./Write/WriteBeneficiary";
import AdminManagementKiosk from "./Admin/AdminManagementKiosk";
import AdminManagementMember from "./Admin/AdminManagementMember";
import AdminDental from "./Admin/AdminDental";
import AdminInsuranceResultDetail from "./Admin/Detail/AdminInsuranceResultDetail";
import Login from './Login/Login';
import Logout from "./Login/Logout";
import WriteBankInfo from './Write/WriteBankInfo';
import {AdminLogin} from "./Admin/AdminLogin/AdminLogin";
import CustomerShare from "./Share/CustomerShare";
import {CustomerShareComplete} from "./Share/CustomerShareComplete";
import {CustomerShareEndOpen} from "./Share/CustomerShareEndOpen";
import AdminMainJsx from "./Admin/AdminMainJsx";
import WriteAccidentDetail from "./Write/WriteAccidentDetail";
import DentalPaperMain from './DentalPaper/DentalPaperMain';
import DentalPaperLogin from './DentalPaper/DentalPaperLogin';
import CreateAccount from "./CreateAccount/CreateAccount";
import DentalPaperPayment from "./DentalPaper/DentalPaperPayment";
function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/login' element={<Login />} />
                <Route path='/logout' element={<Logout />} />
                <Route path='/' element={<Introduce />} />
                <Route path='/insuranceMain' element={<InsuranceMain />} />
                <Route path='/agree' element={<Agree />} />
                <Route path='/select' element={<SelectCompany />} />
                <Route path='/virtualfax' element={<VirtualFax/>} />
                <Route path='/choose' element={<Choose/>} />
                <Route path='/writeAccidentDetail' element={<WriteAccidentDetail/>} />
                <Route path='/write' element={<Write/>} />
                <Route path='/chooseBeneficiary' element={<ChooseBeneficiary/>}/>
                <Route path='/chooseSendType' element={<ChooseSendType/>}/>
                <Route path='/complete' element={<Complete/>}/>
                <Route path='/final' element={<Final/>}/>
                <Route path='/writeBeneficiary' element={<WriteBeneficiary />} />
                <Route path='/writeBankInfo' element={<WriteBankInfo/>} />

                {/* 관리자페이지 라우팅*/}
                <Route path='/admin' element={<AdminLogin />} />
                <Route path='/admin/main' element={<AdminMainJsx />} />
                <Route path='/admin/management/kiosk' element={<AdminManagementKiosk />} />
                <Route path='/admin/management/member' element={<AdminManagementMember />} />
                <Route path='/admin/insuranceDetail/' element={<AdminInsuranceResultDetail />} />
                <Route path='/admin/dental' element={<AdminDental />} />
                {/*<Route path='/chooseBeneficiary' element={<ChooseBeneficiary/>}/>*/}

                {/* 고객 공유 페이지 */}
                <Route path='/share/:uuid' element={<CustomerShare/>} />
                <Route path='/shareEnd' element={<CustomerShareComplete/>} />
                <Route path='/shareEndOpen' element={<CustomerShareEndOpen/>} />
                {/*덴탈페이퍼*/}
                <Route path='/dentalPaper' element={<DentalPaperMain/>} />
                <Route path='/dentalLogin' element={<DentalPaperLogin/>} />
                <Route path='/createAccount' element={<CreateAccount/>} />
                <Route path='/dentalPayment' element={<DentalPaperPayment/>} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
