export function CustomerShareEndOpen(){
return(
    <main className="container p-5 d-flex align-items-center mt-5">
        <div className="card p-4 g-py-50 w-100">
            <header className="d-flex flex-column my-5">
                <div className="text-center text-bocare rounded-circle mb-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor"
                         className="bi bi-exclamation-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path
                            d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
                    </svg>
                </div>
                <div className="text-center ms-2 ms-md-0">
                    <div className="fs-4">
                        첨부가 완료된 청구건입니다.<br/>감사합니다.
                    </div>
                </div>
            </header>
        </div>
    </main>
    );
}