import ModalPortal from "../../Layout/Modal/ModalPortal";
import Modal from 'react-bootstrap/Modal';
import '../../components/css/admin_dashboard.css';
import '../../components/css/admin_dashboard_rtl.css';
import {useEffect, useState} from "react";
import {getAdminInsuranceDataByIdx} from "../AdminData";
import axios from "axios";
import Loading from "../../components/loading/Loading";
import {AzureBlobStorage} from "../../AzureStorage/AzureStorage";
import {InsuranceImageScan} from "../../components/ImageResize/InsuranceImageScan";
import {AjaxClientV2} from "../../components/util/AjaxClientV2";
import {PdfJS} from "../../components/util/PdfJS";

declare let jsPDF:any;

//은행명 자동이체계좌 선택 시, 계좌번호, 예금주 입력불가
const checkAutoTransfer = (target: HTMLInputElement) => {
    let bankName = (document.getElementById("bankName") as HTMLInputElement).value;
    let bankNumber = document.getElementById("bankNumber") as HTMLInputElement;
    let bankHolder = document.getElementById("bankHolder") as HTMLInputElement;
    if(bankName == "자동이체계좌") {
        bankNumber.disabled = true;
        bankNumber.value = "";
        bankHolder.disabled = true;
        bankHolder.value = "";
    }

    if(bankName != "자동이체계좌") {
        bankNumber.disabled = false;
        bankHolder.disabled = false;
    }
}

//로딩시 자동이체계좌이면 계좌번호, 예금주 입력불가
const disableAutoTransfer = async () => {
    let bankName = document.getElementById("bankName") as HTMLInputElement;
    let bankNumber = document.getElementById("bankNumber") as HTMLInputElement;
    let bankHolder = document.getElementById("bankHolder") as HTMLInputElement;
    if (bankName && bankNumber && bankHolder) {
        if (bankName.value === "자동이체계좌") {
            bankNumber.disabled = true;
            bankNumber.value = "";
            bankHolder.disabled = true;
            bankHolder.value = "";
        }
    }
}
const autoHyphenPhone = (target: HTMLInputElement) => {
    target.value = target.value
        .replace(/[^0-9]/g, '')
        .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/(\-{1,2})$/g, "");
}

const autoHyphenNumber = (target: HTMLInputElement) => {
    target.value = target.value
        .replace(/[^0-9]/g, '')
        .replace(/^(\d{0,6})(\d{0,7})$/g, "$1-$2").replace(/(\-{1,2})$/g, "");
}

const AdminReclaimModal = ({ onClose, isOpen, requestId } : any) => {
    const azure = new AzureBlobStorage("insurancerequest");
    const [showImages, setShowImages] = useState([]);
    const [uploadList, setUploadList] = useState([]);
    const [uploadDBList, setUploadDBList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reclaimList, setReclaimList] = useState<any[]>([]);
    const [reclaimFileList, setReclaimFileList] = useState<any[]>([]);

    let scanImageList = new Array<{
        imageName:string,
        format:string,
        image:string
    }>();

    const GetScanImage = async (file:any) => {
        const extension = file.name.substring(file.name.length, file.name.lastIndexOf('.')).toLowerCase();
        await readFileAsync(file, extension);

    }

    function readFileAsync(file:any, extension:any) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = async() => {
                // resolve(reader.result);
                let imageName = file.name;
                let imagePath:any = reader.result;
                let format = InsuranceImageScan.getExtensionFormat(extension);

                if (format == "") {
                    return;
                }

                let base64 = await InsuranceImageScan.imageScanAsync(imagePath, extension);

                let formData = new FormData();

                let base64_split = base64.split(',');
                let byteString = atob(base64_split[1]);
                let max = byteString.length;
                let uint8Array = new Uint8Array(byteString.length);
                let mime_type = base64_split[0].split(':')[1].split(';')[0];

                for (let i = 0; i < max; i++) {
                    uint8Array[i] = byteString.charCodeAt(i);
                }

                let blob = new Blob([uint8Array], { type: mime_type });

                formData.append("image", blob);
                let response = await AjaxClientV2.sendPostFormDataAsync("https://bocareimagefunction.azurewebsites.net/api/scan", formData
                );
                let data = response.responseText;

                let tempObject = {
                    imageName: imageName,
                    format: format,
                    image: `${base64_split[0]},${data}`
                };

                scanImageList.push(tempObject);
                resolve(scanImageList);
            };
            reader.readAsDataURL(file);
            reader.onerror = reject;
        });

    }

    useEffect(() => {
        const closeXModal = document.getElementById("closeXModal") as HTMLInputElement;
        closeXModal.disabled = true;

        const closeButtonModal = document.getElementById("closeButtonModal") as HTMLInputElement;
        closeButtonModal.disabled = true;

        const reclaimButton = document.getElementById("reclaimButton") as HTMLInputElement;
        reclaimButton.disabled = true;

        setLoading(true);
        // requestId를 사용하여 청구내역을 불러옵니다.
        fetchAdminInsuranceDataByIdx(requestId);

        fetchAdminInsuranceDataByIdx(requestId)
            .then(r => {
                console.log("success");
                closeXModal.disabled = false;
                closeButtonModal.disabled = false;
                reclaimButton.disabled = false;
                setLoading(false);

                disableAutoTransfer();
            });
    }, []);

    disableAutoTransfer();

    const handleAddPreviousImage = (files : FileList) => {
        const imageLists = files;
        let imageUrlLists:any = [...showImages];
        let fileLists:any = [...uploadList];

        for (let i = 0; i < imageLists.length; i++) {
            const currentImageUrl:any = URL.createObjectURL(imageLists[i]);
            imageUrlLists.push(currentImageUrl);
            fileLists.push(imageLists[i]);
        }

        setUploadList(fileLists);
        setShowImages(imageUrlLists);
    }

    const handleAddImages = async (event:any) => {
        const imageLists = event.target.files;

        //파일 첨부 취소 시 종료
        if (imageLists.length === 0) {
            return;
        }

        let imageUrlLists:any = [...showImages];
        let fileLists:any = [...uploadList];

        let reader = new FileReader();
        let blob = new Blob([imageLists[0]], { type: "application/pdf" });
        let ext = imageLists[0].name.split(".")[1];
        reader.readAsDataURL(blob);
        reader.onload = async () => {
            let path = reader.result;
            if (ext === "pdf") {
                //로딩 화면
                setLoading(true);
                let pdfJs: any = new PdfJS();
                let pdfLists = await pdfJs.getConvertedImageList(path, imageLists[0].name);
                for await (let pdfList of pdfLists) {
                    const currentImageUrl: any = URL.createObjectURL(pdfList);
                    imageUrlLists.push(currentImageUrl);
                    fileLists.push(pdfList);
                }
                setLoading(false);
            } else {
                const currentImageUrl: any = URL.createObjectURL(imageLists[0]);
                imageUrlLists.push(currentImageUrl);
                fileLists.push(imageLists[0]);
            }
            setUploadList(fileLists);
            setShowImages(imageUrlLists);
        }
    };


    // 청구내역을 불러오는 함수
    async function fetchAdminInsuranceDataByIdx(requestId: any) {
        const response = await getAdminInsuranceDataByIdx(requestId);
        const requestData = response.data.responseData.requestInfo;

        // requestData를 배열로 변환
        const dataArray = requestData ? [requestData] : [];
        setReclaimList(dataArray);
        setReclaimFileList(response.data.responseData.requestFileInfo);

        // const closeXModal = document.getElementById("closeXModal") as HTMLInputElement;
        // closeXModal.disabled = true;

        const dataTransfer = new DataTransfer();
        response.data.responseData.requestFileInfo.forEach((fileInfo : any) => {
            //base64 to file
            let bstr = atob(fileInfo.base64Data),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }

            const file = new File([u8arr], fileInfo.fileName, { type:""});

            dataTransfer.items.add(file);
        });

        let fileAttachElement = document.getElementById("fileAttach") as HTMLInputElement;

        fileAttachElement.files = dataTransfer.files;//오류생기는 위치

        setReclaimFileList(reclaimFileList);

        handleAddPreviousImage(dataTransfer.files);

        // closeXModal.disabled = false;
    }

    // 데이터 저장 함수
    const handleReclaimSubmit = async () => {
        let companyName = (document.getElementById("companyName") as HTMLInputElement).value;
        let faxNumber = (document.getElementById("faxNumber") as HTMLInputElement).value;
        let accidentDetail = (document.getElementById("accidentDetail") as HTMLInputElement).value;
        let accidentType = (document.getElementById("accidentType") as HTMLInputElement).value;
        let insurerName = (document.getElementById("insurerName") as HTMLInputElement).value;
        let insurerPhone = (document.getElementById("insurerPhone") as HTMLInputElement).value;
        let insurerNumber = (document.getElementById("insurerNumber") as HTMLInputElement).value;
        let beneficiaryName = (document.getElementById("beneficiaryName") as HTMLInputElement).value;
        let beneficiaryPhone = (document.getElementById("beneficiaryPhone") as HTMLInputElement).value;
        let beneficiaryNumber = (document.getElementById("beneficiaryNumber") as HTMLInputElement).value;
        let bankName = (document.getElementById("bankName") as HTMLInputElement).value;
        let bankNumber = (document.getElementById("bankNumber") as HTMLInputElement).value;
        let bankHolder = (document.getElementById("bankHolder") as HTMLInputElement).value;

        //'자동이체가능' 보험사
        //메리츠화재, DB손해보험, KB손해보험, MG손해보험, NH농협손해보험, 롯데손해보험, 흥국화재, 현대해상화재, 한화손해보험
        if(bankName == "자동이체계좌") {
            if(companyName != "메리츠화재" && companyName != "DB손해보험" && companyName != "KB손해보험" && companyName != "MG손해보험" &&
                companyName != "NH농협손해보험" && companyName != "롯데손해보험" && companyName != "흥국화재" && companyName != "현대해상화재" &&
                companyName != "한화손해보험") {
                alert("자동이체 불가능한 보험사입니다.");
                return;
            }
        }

        setLoading(true); // 데이터 저장 중임을 표시 로딩 상태 설정

        const insuranceInfoSaveUrl = process.env.REACT_APP_API_URL_ENTRY + "/admin/saveReclaim";

        const userInfo:any = JSON.parse(localStorage.getItem('user') as string);

        let info = {
            accidentType: accidentType,
            insurerName: insurerName,
            insurerPhone: insurerPhone,
            insurerNumber: insurerNumber,
            beneficiaryName: beneficiaryName,
            beneficiaryPhone: beneficiaryPhone,
            beneficiaryNumber: beneficiaryNumber,
            bankName: bankName,
            bankNumber: bankNumber,
            bankHolder: bankHolder
        }

        let companyInfo = {
            companyName: companyName,
            faxNumber: faxNumber
        }

        let date = new Date();
        let year = date.getFullYear();
        let month = (("00" + (date.getMonth() + 1).toString()).slice(-2));
        let day = (("00" + date.getDate().toString()).slice(-2));

        const promises = [];
        let uploadDB:any = [...uploadDBList];

        let fileList = [...uploadList,...reclaimFileList];

        let elementName: string;
        let blob: Blob;
        let checkFirstImage:boolean = false;
        let imageName: string;

        //pdf열기(file 객체)
        let pdfDoc = new jsPDF("p", "mm", "a4", true);
        pdfDoc.deletePage(1);

        let pdfPromise:any = [];

        for await(let file of fileList){
            pdfPromise.push(GetScanImage(file));
        }

        await Promise.all(pdfPromise);

        for (let i = 0; i < fileList.length; i++) {
            let file: File = fileList[i];
            //bocare logic
            if (!checkFirstImage) {
                let base64_split = scanImageList[i].image.split(',');
                let byteString = atob(base64_split[1]);
                let max = byteString.length;
                let uint8Array = new Uint8Array(byteString.length);
                let mime_type = base64_split[0].split(':')[1].split(';')[0];

                for (let i = 0; i < max; i++) {
                    uint8Array[i] = byteString.charCodeAt(i);
                }
                blob = new Blob([uint8Array], {type: mime_type});
                checkFirstImage = true;
                elementName = "image_file";
                imageName = "imageThumbnail.jpg";

                const data = new DataTransfer();
                let newFile = new File([blob], `${imageName}`, { type: blob['type'] });
                data.items.add(newFile);
                file = data.files[0];
            } else {
                imageName = "mergeAttachImage.pdf";
                pdfDoc.addPage("a4", "p");
                pdfDoc.addImage(scanImageList[i].image, scanImageList[i].format, 0, 0, 210, 297, undefined, 'FAST'); //imageData as base64 encoded DataUrl or Image-HTMLElement or Canvas-HTMLElement
                blob = pdfDoc.output('blob');
                elementName = "image2pdf";

                const data = new DataTransfer();
                let newFile = new File([blob], `${imageName}`, { type: blob['type'] });
                data.items.add(newFile);
                file = data.files[0];
            }

            const ext = file.name.substring(file.name.length, file.name.lastIndexOf('.')).toLowerCase();
            let filePath: string = `${year}/${year}-${month}-${day}/${azure.uniqid("insurancerequest", ext)}`;

            //Image to Pdf

            if( i == 0 || i == fileList.length - 1) {
                uploadDB.push({
                    file_name: azure.uniqid("보험금청구_팩스_첨부파일", ext),
                    file_path: filePath,
                    file_size: file.size
                });
                promises.push(azure.UploadInsuranceRequestAsync(file, filePath));
            }
        }

        await Promise.all(promises);
        promises.length = 0;

        for(let i=0; i < fileList.length; i++) {
            let file:File = fileList[i];
            const ext = file.name.substring(file.name.length, file.name.lastIndexOf('.')).toLowerCase();
            let filePath:string = `${year}/${year}-${month}-${day}/${azure.uniqid("insurancerequest", ext)}`;
            uploadDB.push({
                file_name: file.name,
                file_path: filePath,
                file_size: file.size
            })
            promises.push(azure.UploadInsuranceRequestAsync(file, filePath));
        }
        await Promise.all(promises);
        setUploadDBList(uploadDB);

        //첨부파일이 2개 이상일 경우
        if(fileList.length > 1 ) {
            axios.post(insuranceInfoSaveUrl, {
                "info" : JSON.stringify(info),
                "companyInfo" : JSON.stringify(companyInfo),
                "accidentDetail" : JSON.stringify(accidentDetail),
                "uploadFileInfo" : JSON.stringify(uploadDB),
                "reclaimFileList": JSON.stringify(reclaimFileList),
                "memberIdx": userInfo.idx
            }, {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access-Control-Allow-Origin': '*'
                },
                withCredentials: true
            }).then(async (res) => {
            console.log("저장완료");
                alert("파일 업로드가 완료되었습니다. 팩스는 자동으로 발송됩니다.");
                window.location.reload();
                console.log(res);
            }).catch((err) => {
                console.log(err);
                alert("서버와의 통신중 에러가 발생하였습니다. 관리자에게 문의하여주세요.");
            });
        } else {
            //첨부파일이 1개일 경우
            const insuranceOneImageInfoSaveUrl = process.env.REACT_APP_API_URL_ENTRY + "/admin/saveReclaimOneImage";
            const idx:string = userInfo.idx;
            axios.post(insuranceOneImageInfoSaveUrl, {
                "memberIdx": idx,
                "info" : JSON.stringify(info),
                "companyInfo" : JSON.stringify(companyInfo),
                "accidentDetail" : JSON.stringify(accidentDetail),
                "uploadFileInfo" : JSON.stringify(uploadDB),
                "reclaimFileList": JSON.stringify(reclaimFileList)
            }, {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access-Control-Allow-Origin': '*'
                },
                withCredentials: true
            }).then(async (res) => {
                console.log("저장완료");
                alert("파일 업로드가 완료되었습니다. 팩스는 자동으로 발송됩니다.");
                window.location.reload();
                console.log(res);
            }).catch((err) => {
                console.log(err);
                alert("서버와의 통신중 에러가 발생하였습니다. 관리자에게 문의하여주세요.");
            });
        }
    }

    const handleDeleteImage = (id:any) => {
        setShowImages(showImages.filter((_, index) => index !== id));
        setUploadList(uploadList.filter((_, index) => index !== id));
    };

    //기존 첨부파일 삭제버튼 클릭시
    const handleDeleteReclaimImage = (id:any) => {
        setReclaimFileList(reclaimFileList.filter((_, index) => index !== id));
    };
    // @ts-ignore
        return (
            <ModalPortal>
                {loading ? <Loading/> : null}
                <Modal show={isOpen} size="xl">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">재청구하기</h5>
                        <button type="button" id="closeXModal" className="btn-close" onClick={onClose}
                                aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        <div className="mb-2">※ 보험금 재청구 시 <span className="text-pink fw-bold">입력값</span>을 변경하실 수 있습니다.
                        </div>
                        <div className="mb-2">재청구 버튼 클릭 시, 새로운 <span className="text-pink fw-bold">별도의 청구내역</span>이
                            추가됩니다.
                            {/*("<span className="text-pink fw-bold">접수대기</span>"상태로 추가되므로 파일추가작업을 하셔야 접수완료됩니다.)*/}
                        </div>
                        <div className="mb-2">미입력시 <span className="text-pink fw-bold">기존 데이터</span>로 접수됩니다.</div>
                        {
                            reclaimList.map((reclaimList:any) => (
                                <table className="table table-striped table-hover table-sm">
                                    <thead>
                                    <tr key={"row1"}>
                                        <th scope="col">접수번호</th>
                                        <th scope="col">{reclaimList.idx}번</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr key={"row2"}>
                                        <th scope="row">보험사</th>
                                        <td>
                                            <select id={"companyName"} defaultValue={reclaimList.companyName}>
                                                <optgroup label="손해보험사">
                                                    <option key="AIG손해보험" value="AIG손해보험">AIG손해보험</option>
                                                    <option key="AXA손해보험" value="AXA손해보험">AXA손해보험</option>
                                                    <option key="DB손해보험" value="DB손해보험">DB손해보험</option>
                                                    <option key="KB손해보험" value="KB손해보험">KB손해보험</option>
                                                    <option key="MG손해보험" value="MG손해보험">MG손해보험</option>
                                                    <option key="NH농협손해보험" value="NH농협손해보험">NH농협손해보험</option>
                                                    <option key="라이나손해보험" value="라이나손해보험">라이나손해보험</option>
                                                    <option key="롯데손해보험" value="롯데손해보험">롯데손해보험</option>
                                                    <option key="메리츠화재" value="메리츠화재">메리츠화재</option>
                                                    <option key="삼성화재" value="삼성화재">삼성화재</option>
                                                    <option key="우체국보험" value="우체국보험">우체국보험</option>
                                                    <option key="하나손해보험" value="하나손해보험">하나손해보험</option>
                                                    <option key="한화손해보험" value="한화손해보험">한화손해보험</option>
                                                    <option key="현대해상화재" value="현대해상화재">현대해상화재</option>
                                                    <option key="흥국화재" value="흥국화재">흥국화재</option>
                                                </optgroup>
                                                <optgroup label="생명보험사">
                                                    <option key="ABL생명" value="ABL생명">ABL생명</option>
                                                    <option key="AIA생명" value="AIA생명">AIA생명</option>
                                                    <option key="DB생명" value="DB생명">DB생명</option>
                                                    <option key="iM생명" value="iM생명">iM생명</option>
                                                    <option key="KB라이프생명" value="KB라이프생명">KB라이프생명</option>
                                                    <option key="KDB생명" value="KDB생명">KDB생명</option>
                                                    <option key="NH농협생명" value="NH농협생명">NH농협생명</option>
                                                    <option key="교보생명" value="교보생명">교보생명</option>
                                                    <option key="동양생명" value="동양생명">동양생명</option>
                                                    <option key="라이나생명" value="라이나생명">라이나생명</option>
                                                    <option key="메트라이프생명" value="메트라이프생명">메트라이프생명</option>
                                                    <option key="미래에셋생명" value="미래에셋생명">미래에셋생명</option>
                                                    <option key="삼성생명" value="삼성생명">삼성생명</option>
                                                    <option key="신한라이프" value="신한라이프">신한라이프</option>
                                                    <option key="오렌지라이프" value="오렌지라이프">오렌지라이프</option>
                                                    <option key="처브라이프생명" value="처브라이프생명">처브라이프생명</option>
                                                    <option key="푸르덴셜생명" value="푸르덴셜생명">푸르덴셜생명</option>
                                                    <option key="푸본현대생명" value="푸본현대생명">푸본현대생명</option>
                                                    <option key="하나생명" value="하나생명">하나생명</option>
                                                    <option key="한화생명" value="한화생명">한화생명</option>
                                                    <option key="흥국생명" value="흥국생명">흥국생명</option>
                                                </optgroup>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr key={"row3"}>
                                        <th scope="row">팩스 번호</th>
                                        <td><input type="text" id={"faxNumber"} defaultValue={reclaimList.faxNumber}/>
                                        </td>
                                    </tr>
                                    <tr key={"row4"}>
                                        <th scope="row">사고유형</th>
                                        <td>
                                            <select id={"accidentType"} defaultValue={reclaimList.accidentType}>
                                                <option key="disease" value="disease">질병</option>
                                                <option key="accident" value="accident">상해</option>
                                                <option key="carAccident" value="carAccident">교통사고</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr key={"row4"}>
                                        <th scope="row">사고경위</th>
                                        <td><input type="text" id={"accidentDetail"} defaultValue={reclaimList.accidentDetail === null ? "자료첨부" : reclaimList.accidentDetail}/>
                                        </td>
                                    </tr>
                                    <tr key={"row5"}>
                                        <th scope="row">신청인</th>
                                        <td><input type="text" id={"insurerName"}
                                                   defaultValue={reclaimList.insurerName}/></td>
                                    </tr>
                                    <tr key={"row6"}>
                                        <th scope="row">신청인 연락처</th>
                                        <td><input type="text" id="insurerPhone" maxLength={13}
                                                   onInput={(event) => autoHyphenPhone(event.target as HTMLInputElement)}
                                                   defaultValue={reclaimList.insurerPhone}/></td>
                                    </tr>
                                    <tr key={"row7"}>
                                        <th scope="row">신청인 주민번호</th>
                                        <td><input type="text" id={"insurerNumber"} maxLength={14}
                                                   onInput={(event) => autoHyphenNumber(event.target as HTMLInputElement)}
                                                   defaultValue={reclaimList.insurerNumber}/></td>
                                    </tr>
                                    <tr key={"row8"}>
                                        <th scope="row">수익자</th>
                                        <td><input type="text" id={"beneficiaryName"}
                                                   defaultValue={reclaimList.beneficiaryName}/></td>
                                    </tr>
                                    <tr key={"row9"}>
                                        <th scope="row">수익자 연락처</th>
                                        <td><input type="text" id={"beneficiaryPhone"} maxLength={13}
                                                   onInput={(event) => autoHyphenPhone(event.target as HTMLInputElement)}
                                                   defaultValue={reclaimList.beneficiaryPhone}/></td>
                                    </tr>
                                    <tr key={"row10"}>
                                        <th scope="row">수익자 주민번호</th>
                                        <td><input type="text" id={"beneficiaryNumber"} maxLength={14}
                                                   onInput={(event) => autoHyphenNumber(event.target as HTMLInputElement)}
                                                   defaultValue={reclaimList.beneficiaryNumber}/></td>
                                    </tr>
                                    <tr key={"row11"}>
                                        <th scope="row">은행명</th>
                                        <td>
                                            <select id="bankName" defaultValue={reclaimList.bankName}
                                                    onInput={(event) => checkAutoTransfer(event.target as HTMLInputElement)}>
                                                <option key={"산업은행"} value={"산업은행"}>산업은행</option>
                                                <option key={"기업은행"} value={"기업은행"}>기업은행</option>
                                                <option key={"국민은행"} value={"국민은행"}>국민은행</option>
                                                <option key={"수협은행"} value={"수협은행"}>수협은행</option>
                                                <option key={"수출입은행"} value={"수출입은행"}>수출입은행</option>
                                                <option key={"농협은행"} value={"농협은행"}>농협은행</option>
                                                <option key={"지역 농축협"} value={"지역 농축협"}>지역 농축협</option>
                                                <option key={"우리은행"} value={"우리은행"}>우리은행</option>
                                                <option key={"SC제일은행"} value={"SC제일은행"}>SC제일은행</option>
                                                <option key={"한국씨티은행"} value={"한국씨티은행"}>한국씨티은행</option>
                                                <option key={"대구은행"} value={"대구은행"}>대구은행</option>
                                                <option key={"부산은행"} value={"부산은행"}>부산은행</option>
                                                <option key={"광주은행"} value={"광주은행"}>광주은행</option>
                                                <option key={"제주은행"} value={"제주은행"}>제주은행</option>
                                                <option key={"전북은행"} value={"전북은행"}>전북은행</option>
                                                <option key={"경남은행"} value={"경남은행"}>경남은행</option>
                                                <option key={"새마을금고중앙회"} value={"새마을금고중앙회"}>새마을금고중앙회</option>
                                                <option key={"신협"} value={"신협"}>신협</option>
                                                <option key={"저축은행"} value={"저축은행"}>저축은행</option>
                                                <option key={"HSBC은행"} value={"HSBC은행"}>HSBC은행</option>
                                                <option key={"도이치은행"} value={"도이치은행"}>도이치은행</option>
                                                <option key={"JP모간체이스은행"} value={"JP모간체이스은행"}>JP모간체이스은행</option>
                                                <option key={"BOA은행"} value={"BOA은행"}>BOA은행</option>
                                                <option key={"비엔피파리바은행"} value={"비엔피파리바은행"}>비엔피파리바은행</option>
                                                <option key={"중국공상은행"} value={"중국공상은행"}>중국공상은행</option>
                                                <option key={"중국은행"} value={"중국은행"}>중국은행</option>
                                                <option key={"산림조합중앙회"} value={"산림조합중앙회"}>산림조합중앙회</option>
                                                <option key={"중국건설은행"} value={"중국건설은행"}>중국건설은행</option>
                                                <option key={"우체국"} value={"우체국"}>우체국</option>
                                                <option key={"KEB하나은행"} value={"KEB하나은행"}>KEB하나은행</option>
                                                <option key={"신한은행"} value={"신한은행"}>신한은행</option>
                                                <option key={"케이뱅크"} value={"케이뱅크"}>케이뱅크</option>
                                                <option key={"카카오뱅크"} value={"카카오뱅크"}>카카오뱅크</option>
                                                <option key={"토스뱅크"} value={"토스뱅크"}>토스뱅크</option>
                                                <option key={"유안타증권"} value={"유안타증권"}>유안타증권</option>
                                                <option key={"KB증권"} value={"KB증권"}>KB증권</option>
                                                <option key={"골든브릿지투자증권"} value={"골든브릿지투자증권"}>골든브릿지투자증권</option>
                                                <option key={"한양증권"} value={"한양증권"}>한양증권</option>
                                                <option key={"리딩투자증권"} value={"리딩투자증권"}>리딩투자증권</option>
                                                <option key={"BNK투자증권"} value={"BNK투자증권"}>BNK투자증권</option>
                                                <option key={"IBK투자증권"} value={"IBK투자증권"}>IBK투자증권</option>
                                                <option key={"KTB투자증권"} value={"KTB투자증권"}>KTB투자증권</option>
                                                <option key={"미래에셋대우"} value={"미래에셋대우"}>미래에셋대우</option>
                                                <option key={"삼성증권"} value={"삼성증권"}>삼성증권</option>
                                                <option key={"한국투자증권"} value={"한국투자증권"}>한국투자증권</option>
                                                <option key={"NH투자증권"} value={"NH투자증권"}>NH투자증권</option>
                                                <option key={"교보증권"} value={"교보증권"}>교보증권</option>
                                                <option key={"하이투자증권"} value={"하이투자증권"}>하이투자증권</option>
                                                <option key={"현대차증권"} value={"현대차증권"}>현대차증권</option>
                                                <option key={"키움증권"} value={"키움증권"}>키움증권</option>
                                                <option key={"이베스트투자증권"} value={"이베스트투자증권"}>이베스트투자증권</option>
                                                <option key={"에스케이증권"} value={"에스케이증권"}>에스케이증권</option>
                                                <option key={"대신증권"} value={"대신증권"}>대신증권</option>
                                                <option key={"한화투자증권"} value={"한화투자증권"}>한화투자증권</option>
                                                <option key={"하나금융투자"} value={"하나금융투자"}>하나금융투자</option>
                                                <option key={"토스증권"} value={"토스증권"}>토스증권</option>
                                                <option key={"신한금융투자"} value={"신한금융투자"}>신한금융투자</option>
                                                <option key={"DB금융투자"} value={"DB금융투자"}>DB금융투자</option>
                                                <option key={"유진투자증권"} value={"유진투자증권"}>유진투자증권</option>
                                                <option key={"메리츠종합금융증권"} value={"메리츠종합금융증권"}>메리츠종합금융증권</option>
                                                <option key={"카카오페이증권"} value={"카카오페이증권"}>카카오페이증권</option>
                                                <option key={"부국증권"} value={"부국증권"}>부국증권</option>
                                                <option key={"신영증권"} value={"신영증권"}>신영증권</option>
                                                <option key={"케이프투자증권"} value={"케이프투자증권"}>케이프투자증권</option>
                                                <option key={"한국증권금융"} value={"한국증권금융"}>한국증권금융</option>
                                                <option key={"한국포스증권"} value={"한국포스증권"}>한국포스증권</option>
                                                <option key={"우리종합금융"} value={"우리종합금융"}>우리종합금융</option>
                                                <option key={"자동이체계좌"} value={"자동이체계좌"}>자동이체계좌</option>
                                            </select></td>
                                    </tr>
                                    <tr key={"row12"}>
                                        <th scope="row">계좌번호</th>
                                        <td><input type="text" id={"bankNumber"} defaultValue={reclaimList.bankNumber}/>
                                        </td>
                                    </tr>
                                    <tr key={"row13"}>
                                        <th scope="row">예금주</th>
                                        <td><input type="text" id={"bankHolder"} defaultValue={reclaimList.bankHolder}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                            ))
                        }
                        <div className="input-group mb-3">
                            <div className="fw-bold fs-6 w-100 mb-1"><h4><i className={"bi bi-folder2-open"}></i>  청구서류 파일을 첨부하세요.</h4></div><br/><br/>
                            <input type="file" className="form-control" id="fileAttach" name="fileAttach" onChange={handleAddImages}/>
                        </div>
                        <div id="noDataDiv"
                             className="col-md-12 text-center text-secondary card px-3 py-5 mt-3"
                             style={{display: "none"}}>
                            <div id="" className="fs-2 mb-2">첨부하신 파일이 없습니다.</div>
                        </div>

                        <div id="file_wrapper" className="row">
                            {showImages.map((image, id) => (
                                <div className="col-4 mb-3" key={id}>
                                    <div className="border card h-100 p-3">
                                        <div className="w-100 d-flex flex-wrap h-100 text-center">
                                            <div className="w-100 align-self-center">
                                                <img className="img-fluid" src={image}
                                                    // onError="this.onerror=null; this.src='{{error}}'"
                                                     alt={`${image}-${id}`}/>
                                            </div>
                                            <div className="w-100 align-self-end">
                                                <button id="delete_{{idx}}" type="button" onClick={() => handleDeleteImage(id)}
                                                        className="btn btn-secondary mt-2">삭제
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <input type="file" className='d-none' id="file_{{idx}}" name="file_{{idx}}" />
                                </div>
                            ))}
                            {reclaimFileList.map((file, id) => (
                                <div className="w-100 d-flex flex-wrap h-100 text-center" key={id}>
                                    <div className="w-100 align-self-center">
                                        <img className="img-fluid" id={file.filePath} src={`data:image/jpg;base64,${file.base64Data}`} alt={file.filePath} />
                                    </div>
                                    <div className="w-100 align-self-end">
                                        <button id={`delete_${id}`} type="button" onClick={() => handleDeleteReclaimImage(id)} className="btn btn-secondary mt-2">삭제</button>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" id="closeButtonModal" className="btn btn-secondary" onClick={onClose}>닫기
                        </button>
                        <button type="button" id="reclaimButton" onClick={handleReclaimSubmit} className="btn btn-primary">재청구</button>

                    </div>
                </Modal>
            </ModalPortal>
        );
    }
export default AdminReclaimModal;