import AdminLayout from "../Layout/AdminLayout";
import '../components/css/admin_dashboard.css';
import '../components/css/admin_dashboard_rtl.css';
import {useEffect, useState} from "react";
import Loading from "../components/loading/Loading";
import ModalPortal from "../Layout/Modal/ModalPortal";
import AdminDentalConfirmModal from "./Modal/AdminDentalConfirmModal";
import MultiSlide from "../components/Slide/MultiSlide";
import {getDentalFlyer} from "./AdminData";
import '../components/css/dental.css'

function AdminDental() {
    const [loading, setLoading] = useState(false);
    const [adminDentalConfirmModalOpen, setAdminDentalConfirmModalOpen] = useState();
    const [slides, setSlides] = useState<{ image: string; alt: string; title:string;}[]>([]);
    const [imagesLoaded, setImagesLoaded] = useState(false);

    const dentistPaperHandleLinkClick = (event: { preventDefault: () => void; }) => {
        // @ts-ignore
        const info = JSON.parse(localStorage.getItem("user"));
        if (info.access_type === "dentistPaper") {
            event.preventDefault(); // 기본 동작(페이지 이동)을 막음
            alert("이용이 불가능한 서비스입니다. 이용을 원하시면 고객센터(1566-4875)로 연락주시기 바랍니다."); // 알림 창 표시
        }
    };

    const defaultHandleLinkClick = (event: { preventDefault: () => void; }) => {
        // @ts-ignore
        const info = JSON.parse(localStorage.getItem("user"));
        if (info.access_type === "default") {
            event.preventDefault(); // 기본 동작(페이지 이동)을 막음
            alert("이용이 불가능한 서비스입니다. 이용을 원하시면 고객센터(1566-4875)로 연락주시기 바랍니다."); // 알림 창 표시
        }
    };

    const HandleAdminDentalConfirmResultId = () => {
        // @ts-ignore
        setAdminDentalConfirmModalOpen(true);
    }

    const HandleAdminDentalConfirmModalShow = () => {
        // @ts-ignore
        setAdminDentalConfirmModalOpen(false);
    }

    function getRecentDentalAd(data: any[]): { image_path: string, title: string }[] {
        const keywords = ['치과', '치아', '치석', '임플란트', '틀니', '충전치료', '치과치료'];

        const dentalItems = data
            .filter((item: { contents: string | string[] }) => {
                if (!item.contents) return false;
                if (typeof item.contents === 'string') {
                    return keywords.some(keyword => item.contents.includes(keyword));
                }
                if (Array.isArray(item.contents)) {
                    return (item.contents as string[]).some(content =>
                        keywords.some(keyword => content.includes(keyword))
                    );
                }
                return false;
            })
            .slice(0, 8)
            .map((item: { image_path: string, title: string }) => ({
                image_path: item.image_path,
                title: item.title
            }));

        return dentalItems;
    }



    async function dentalAd() {
        const response = await getDentalFlyer();
        const imagePaths = getRecentDentalAd(response.data);
        const validSlides = await Promise.all(imagePaths.map(async (ad, index) => {
            try {
                const img = new Image();
                img.src = `https://bocare.azureedge.net/flyer/${ad.image_path}`;
                await img.decode(); // 이미지 디코딩 시도
                if (img.complete && img.naturalWidth !== 0) {
                    return {
                        image: img.src,
                        alt: `Image ${index + 1}`,
                        title: ad.title
                    };
                }
            } catch (error) {
                console.error('Error loading image:', error);
            }
            return null;
        }));
        return validSlides.filter(Boolean); // 유효한 슬라이드만 반환
    }

    async function loadImages() {
        const newSlides = await dentalAd();
        // @ts-ignore
        setSlides(newSlides);
        setImagesLoaded(true);
    }

    useEffect(() => {
        loadImages();
    }, [])

    // @ts-ignore
    return (
        <AdminLayout>
            {loading ? <Loading/> : null}
            <div className="container-fluid">
                <div className="row">
                    <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
                        <div className="position-sticky pt-3">
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <a className="nav-link" aria-current="page" href="/admin/main/"
                                       onClick={dentistPaperHandleLinkClick}>
                                        <span data-feather="home"></span>
                                        보험금청구
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" aria-current="page"
                                       href="/admin/management/kiosk/" onClick={dentistPaperHandleLinkClick}>
                                        <span data-feather="home"></span>
                                        키오스크 관리
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" aria-current="page"
                                       href="/admin/management/member/">
                                        <span data-feather="home"></span>
                                        회원관리
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page"
                                       href="/admin/dental/" onClick={defaultHandleLinkClick}>
                                        <span data-feather="home"></span>
                                        치과치료확인서
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                        <div
                            className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h1 className="h2">치과 치료 확인서</h1>
                        </div>
                        {/* 생명보험 */}
                        <div className="col-12 mb-3">
                            <div className="card p-3">
                                <div>
                                    <i className="bi bi-stickies me-1"></i> 치과 치료 확인서 서식
                                    <hr className="bg-secondary"/>
                                </div>


                                <nav>
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <button className="nav-link w-50 dental-btn active" id="page1Tab" data-bs-toggle="tab"
                                                data-bs-target="#nav-dental-care-nonlife-02" type="button" role="tab"
                                                aria-controls="nav-nonlife" aria-selected="true" >손해보험
                                        </button>
                                        <button className="nav-link w-50 dental-btn" id="page2Tab" data-bs-toggle="tab"
                                                data-bs-target="#nav-dental-care-life-02" type="button" role="tab"
                                                aria-controls="nav-life"
                                                aria-selected="false" >생명보험
                                        </button>
                                    </div>
                                </nav>
                                <br/>
                                <div className="tab-content mt-2">
                                    <div id="nav-dental-care-life-02" className="px-1 tab-pane fade" role="tabpanel">
                                        <div className="row px-2 mb-xl-0">
                                            <label className="col-4 px-0 pe-1 mb-2">
                                                <a className="btn radio-btn card w-100 px-2 py-3" target="_blank"
                                                   href="https://drive.google.com/file/d/1Q30HFPor2kTLQYFLqjBsIjtUxuT2MLS_/view?usp=sharing" rel="noreferrer">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <img className="mobile-company-image" src="/image/company/ABL생명.svg" alt="ABL생명" width="10%"/>
                                                        </div>
                                                        <div className="col-12 company-name-font-size">
                                                            ABL생명
                                                        </div>
                                                    </div>
                                                </a>
                                            </label>
                                            <label className="col-4 px-0 px-1 mb-2">
                                                <a className="btn radio-btn card w-100 px-2 py-3" target="_blank"
                                                   href="https://drive.google.com/file/d/1vd0KySG4PmlG_58i1DwxvAXC4ozkW9Ya/view?usp=sharing" rel="noreferrer">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <img className="mobile-company-image" src="/image/company/동양생명.svg" alt="동양생명" width="10%"/>
                                                        </div>
                                                        <div className="col-12 company-name-font-size">
                                                            동양생명
                                                        </div>
                                                    </div>
                                                </a>
                                            </label>
                                            <label className="col-4 px-0 ps-1 mb-2">
                                                <a className="btn radio-btn card w-100 px-2 py-3" target="_blank"
                                                   href="https://drive.google.com/file/d/1AJ27Js6op68BTffmRaNYGyRTbn-YfR_A/view?usp=sharing" rel="noreferrer">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <img className="mobile-company-image" src="/image/company/라이나생명.svg" alt="라이나생명" width="10%"/>
                                                        </div>
                                                        <div className="col-12 company-name-font-size">
                                                            라이나생명
                                                        </div>
                                                    </div>
                                                </a>
                                            </label>

                                            <label className="col-4 px-0 pe-1 mb-2">
                                                <a className="btn radio-btn card w-100 px-2 py-3" target="_blank"
                                                   href="https://drive.google.com/file/d/1aUNb9OJ8edi-6OFQ2_8esZsfz4MTCVLi/view?usp=sharing" rel="noreferrer">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <img className="mobile-company-image" src="/image/company/신한생명.svg" alt="신한생명" width="10%"/>
                                                        </div>
                                                        <div className="col-12 company-name-font-size">
                                                            신한라이프
                                                        </div>
                                                    </div>
                                                </a>
                                            </label>
                                            <label className="col-4 px-0 px-1 mb-2">
                                                <a className="btn radio-btn card w-100 px-2 py-3" target="_blank"
                                                   href="https://drive.google.com/file/d/16yx9XFtI05EndMYQ6vAmL3UD_NB461-I/view?usp=sharing" rel="noreferrer">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <img className="mobile-company-image" src="/image/company/한화생명.svg" alt="한화생명" width="10%"/>
                                                        </div>
                                                        <div className="col-12 company-name-font-size">
                                                            한화생명
                                                        </div>
                                                    </div>
                                                </a>
                                            </label>
                                            <label className="col-4 px-0 ps-1 mb-2">
                                                <a className="btn radio-btn card w-100 px-2 py-3" target="_blank"
                                                   href="https://drive.google.com/file/d/1crk3Eli_dnyp-VwUmr5tDu2HzXGOCyrB/view?usp=sharing" rel="noreferrer">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <img className="mobile-company-image" src="/image/company/흥국생명.svg" alt="흥국생명" width="10%"/>
                                                        </div>
                                                        <div className="col-12 company-name-font-size">
                                                            흥국생명
                                                        </div>
                                                    </div>
                                                </a>
                                            </label>
                                        </div>
                                    </div>
                                    
                                    {/* 손해보험 */}
                                    <div id="nav-dental-care-nonlife-02" className="px-1 tab-pane fade show active"
                                         role="tabpanel">
                                        <div className="row px-2 mb-xl-0">
                                            <label className="col-4 px-0 pe-1 mb-2">
                                                <a className="btn radio-btn card w-100 px-2 py-3" target="_blank"
                                                   href="https://drive.google.com/file/d/1aNT5J0JxxuAbLICF2jAg6E0fvRjXN-4i/view?usp=sharing" rel="noreferrer">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <img className="mobile-company-image" src="/image/company/DB손해보험.svg" alt="DB손해보험" width="10%"/>
                                                        </div>
                                                        <div className="col-12 company-name-font-size">
                                                            DB손해보험
                                                        </div>
                                                    </div>
                                                </a>
                                            </label>
                                            <label className="col-4 px-0 px-1 mb-2">
                                                <a className="btn radio-btn card w-100 px-2 py-3" target="_blank"
                                                   href="https://drive.google.com/file/d/1PLMpWnKTVwmKhTSKgkMPfTXB-esPid-f/view?usp=sharing" rel="noreferrer">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <img className="mobile-company-image" src="/image/company/KB손해보험.svg" alt="KB손해보험" width="10%"/>
                                                        </div>
                                                        <div className="col-12 company-name-font-size">
                                                            KB손해보험
                                                        </div>
                                                    </div>
                                                </a>
                                            </label>
                                            <label className="col-4 px-0 ps-1 mb-2">
                                                <a className="btn radio-btn card w-100 px-2 py-3" target="_blank"
                                                   href="https://drive.google.com/file/d/1QAuXGJKZDeLFMh9y2ojne8vXcQvKotY3/view?usp=sharing" rel="noreferrer">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <img className="mobile-company-image" src="/image/company/NH농협손해보험.svg" alt="NH농협손해보험" width="10%"/>
                                                        </div>
                                                        <div className="col-12 px-0 company-name-font-size">
                                                            NH농협손해보험
                                                        </div>
                                                    </div>
                                                </a>
                                            </label>

                                            <label className="col-4 px-0 pe-1 mb-2">
                                                <a className="btn radio-btn card w-100 px-2 py-3" target="_blank"
                                                   href="https://drive.google.com/file/d/18jbqDKc3Q9TZ3UFiXltXEritif2gwDZ3/view?usp=sharing" rel="noreferrer">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <img className="mobile-company-image" src="/image/company/메리츠화재.svg" alt="메리츠화재" width="10%"/>
                                                        </div>
                                                        <div className="col-12 company-name-font-size">
                                                            메리츠화재
                                                        </div>
                                                    </div>
                                                </a>
                                            </label>
                                            <label className="col-4 px-0 px-1 mb-2">
                                                <a className="btn radio-btn card w-100 px-2 py-3" target="_blank"
                                                   href="https://drive.google.com/file/d/1MYTRkZKeuqPGVaOvj9P54qKCea5_yFdC/view?usp=sharing" rel="noreferrer">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <img className="mobile-company-image" src="/image/company/현대해상화재.svg" alt="현대해상화재" width="10%"/>
                                                        </div>
                                                        <div className="col-12 company-name-font-size">
                                                            현대해상화재
                                                        </div>
                                                    </div>
                                                </a>
                                            </label>
                                            <label className="col-4 px-0 px-1 mb-2">
                                                <a className="btn radio-btn card w-100 px-2 py-3" target="_blank"
                                                   href="https://drive.google.com/file/d/1OIdBra3Xh22hopMga8ve5mMfJT_VFpPf/view?usp=sharing" rel="noreferrer">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <img className="mobile-company-image" src="/image/company/한화손해보험.svg" alt="한화손해보험" width="10%"/>
                                                        </div>
                                                        <div className="col-12 company-name-font-size">
                                                            한화손해보험
                                                        </div>
                                                    </div>
                                                </a>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-12 mb-3">
                            <div className="card p-3">
                                <div className="row">
                                    <div className="col-6 px-1 mb-2 text-center">
                                        <a className="btn btn-lg w-50 w-btn w-btn-gra1" role="button" onClick={() => HandleAdminDentalConfirmResultId()}>치과치료확인서 자동작성</a>
                                    </div>
                                    <div className="col-6 px-1 mb-2 text-center">
                                        <a className="btn btn-lg w-50 w-btn w-btn-gra1" role="button" href="/admin/management/member" >병원 정보 입력</a>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {adminDentalConfirmModalOpen && (
                            <ModalPortal>
                                <AdminDentalConfirmModal onClose={HandleAdminDentalConfirmModalShow} isOpen={true}
                                                         requestId={""} companyCode={""}/>
                            </ModalPortal>
                        )}
                        {/*보험전단지슬라이드*/}
                        {imagesLoaded ? (
                            <div className="col-12 mb-3">
                                <div className="card p-3">
                                    <div>
                                        <i className="bi bi-card-checklist me-2"></i>치과정보전단지
                                    </div>
                                    <hr className="bg-secondary"/>
                                    <div className="App">
                                        <MultiSlide slides={slides} />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <Loading />
                        )}
                        {/*보험전단지슬라이드*/}
                    </main>
                </div>
            </div>
        </AdminLayout>
    );
}

export default AdminDental;