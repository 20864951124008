import {BlobServiceClient, ContainerClient} from "@azure/storage-blob";
import {HtmlConvert} from "./HtmlConvert";
import {ImageResizer} from "./ImageResizer";
import axios from "axios";
import {useNavigate} from "react-router-dom";

export class AzureBlobStorage {
    BlobServiceClient: any = null;
    Container: string = "";
    navigate = useNavigate();

    constructor(container: string) {
        this.Container = container;
        this.connectAzureBlobService();
    }

    async connectAzureBlobService() {
        const url = process.env.REACT_APP_API_URL_ENTRY + "/getCredential";
        await axios.get(url).then((res) => {
            this.BlobServiceClient = BlobServiceClient.fromConnectionString(res.data);
        }).catch((err) => {
            if(err.state === 401){
                alert("계정정보가 유효하지 않습니다. 관리자에게 문의해주세요.")
            }else{
                // alert("서버와의 통신중 오류가 발생했습니다. 관리자에게 문의해주세요.");
            }

            console.log(err);
            window.location.reload();
        });

        console.log("Blob Storage Service Connected");
    }

    async createBlobFromLocalPathAsync(file: Blob, UploadPath: string){
        const containerClient:ContainerClient = this.BlobServiceClient.getContainerClient(this.Container);
        const promises = [];
        const blockBlobClient = containerClient.getBlockBlobClient(UploadPath);
        promises.push(blockBlobClient.uploadData(file));
        await Promise.all(promises);
    }

    async UploadInsuranceRequestAsync(file: File, file_path: string) {
        await this.createBlobFromLocalPathAsync(file, file_path);
    }

    uniqid(prefix = "", ext: string, random = false) {
        const sec = Date.now() * 1000 + Math.random() * 1000;
        const id = sec.toString(16).replace(/\./g, "").padEnd(14, "0");
        return `${prefix}_${id}${random ? `.${Math.trunc(Math.random() * 100000000)}` : ""}${ext}`;
    }

    async resizeImageAsync(file: File) {
        return new Promise<File>((resolve, reject) => {
            let reader: any = new FileReader();
            reader.onload = () => {
                let image = new Image();
                image.src = reader.result.toString();
                const mimeType = image.src.split(';')[0].split(':')[1];
                image.onload = async () => {
                    const imageData = HtmlConvert.imageElementToImageData(image);
                    const blobData = await ImageResizer.resizeImageToBlobAsync(imageData, mimeType, 0.7);

                    resolve(new File([blobData], `${file.name}`, {type: mimeType}));
                };
                image.onerror = reject;
            }
            reader.onerror = reject;
            reader.readAsDataURL(file);
        })
    }

    getFileSize(file: File, type: string) {
        let inputFileSize = file.size;
        const fSExt = ['Bytes', 'KB', 'MB', 'GB'];
        let checkSizeIndex = 0;
        let inputFileSizeIndex = 0;
        let originSize = 0;
        const pdfMaxSize = 1024 * 1024 * 25;
        const imageMaxSize = 1024 * 1024 * 15;

        const imageExt = ['jpg', 'jpeg', 'png'];

        const ext = file.name.substring(file.name.length, file.name.lastIndexOf('.') + 1).toLowerCase();
        let checkSize: number;

        if (imageExt.indexOf(ext) > -1) {
            checkSize = imageMaxSize;
        } else {
            if (ext != "pdf") {
                alert(`이미지(.jpg) 파일 및 PDF 파일만 업로드 가능합니다. \n해당파일명 : ${file.name}`);
                return false;
            }
            checkSize = pdfMaxSize;
        }

        originSize = checkSize;

        while (checkSize > 900) {
            checkSize /= 1024;
            checkSizeIndex++;
        }

        let fileSize = (Math.round(checkSize * 100) / 100) + ' ' + fSExt[checkSizeIndex];

        if ((Math.round(inputFileSize * 100) / 100) > originSize) {
            alert(`첨부파일은 ${fileSize} 이하로 첨부 바랍니다.\n해당파일명 : ${file.name}`);
            return false;
        }

        while (inputFileSize > 900) {
            inputFileSize /= 1024;
            inputFileSizeIndex++;
        }

        return (Math.round(inputFileSize * 100) / 100) + ' ' + fSExt[inputFileSizeIndex];
    }
}