import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import {adminLoginUser, loginUser} from "../../components/Jwt/User";
import {SET_TOKEN} from "../../components/Jwt/Auth";
import {ErrorMessage} from "@hookform/error-message";
import {setAccessToken, setRefreshToken} from "../../components/Jwt/Cookie";

export const AdminLogin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { register, setValue, formState: { errors }, handleSubmit } = useForm();

    const onValid = async ({ account, password }:any) => {
        // input 태그 값 비워주는 코드
        setValue("password", "");

        // 백으로부터 받은 응답
        const response:any = await adminLoginUser({ account, password });

        const data = response.responseData;


        if (response.status) {
            if(data?.status == "900"){
                alert("사용기간이 만료되었습니다. 관리자에게 문의하시기 바랍니다.");
                return navigate("/admin");
            }
            localStorage.setItem("user", JSON.stringify(data.responseData));

            // @ts-ignore
            const info = JSON.parse(localStorage.getItem("user"));
            if(info.access_type === "dentistPaper"){
                return navigate("/admin/dental/");
            }
            else{
                return navigate("/admin/main/");
            }
        } else {
            alert("아이디 또는 비밀번호를 잘못 입력했습니다.\n입력하신 값을 다시 확인해주세요.");
            console.log(response.text);
        }
    };

    return (
        <main className="form-signin text-center bg-light d-flex" style={{minHeight:"100vh", minWidth:"100vh"}}>
            <form onSubmit={handleSubmit(onValid)} className="align-self-center justify-content-center mx-auto" style={{minWidth:"350px"}}>
                <img className="mb-4" src="/image/logo/bocare_white.svg" alt="" width="150" />
                <h1 className="h3 mb-3 fw-normal">로그인</h1>

                <div className="form-floating mb-1">
                    <input type="text" className="form-control" id="account" placeholder="name@example.com" {...register("account", {required: "ID(이메일)를 입력해주세요."})} />
                    <label>ID(이메일)</label>
                </div>
                <div className="form-floating">
                    <input type="password" className="form-control" id="password" placeholder="Password" {...register("password", {required: "비밀번호를 입력해주세요."})} />
                    <ErrorMessage
                        name="account"
                        errors={errors}
                        render={( { message }) =>
                            <p className="text-sm font-medium text-rose-500">
                                { message }
                            </p>
                        }
                    />
                    <label>Password</label>
                </div>

                <div className="checkbox mb-3">
                    <label>
                        <input type="checkbox" value="remember-me" /> 아이디 저장
                    </label>
                </div>
                <button className="w-100 btn btn-lg btn-primary" type="submit">로그인</button>
                <p className="mt-5 mb-3 text-muted">BOCARE 고객센터 1566-4875</p>
            </form>
        </main>
    );
}