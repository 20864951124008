import Layout from "../Layout/Layout";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import '../components/css/kiosk_common.css';
import 'globalthis/auto';

function WriteAccidentDetail(){
    let navigate = useNavigate();
    let loc = useLocation();
    const [showAccidentDetail, setShowAccidentDetail]:any = useState("자료첨부");
    const [isChecked, setIsChecked] = useState(false);

    const checkData = () => {
        let detail:any = document.querySelector<HTMLInputElement>("input[id='fp_info_accidentCause']")?.value;
        if(detail.length < 1) {
            setShowAccidentDetail("자료첨부");
        } else {
            setShowAccidentDetail(detail);
        }
    }

    const handleCheckboxChange = (event:any) => {
        let attachFile = document.getElementById("fp_info_accidentCause") as HTMLInputElement;
        let isChecked = event.target.checked;
        setIsChecked(isChecked);
        if(isChecked) {
            attachFile.disabled = true;
            attachFile.value = "자료첨부";
            setShowAccidentDetail("자료첨부");
        }

        if(!isChecked) {
            attachFile.disabled = false;
            attachFile.value = "";
        }
    };

    const nextPage = () => {
        let attachFile = document.getElementById("fp_info_accidentCause") as HTMLInputElement;
        if(attachFile.value === "" || attachFile.value.length < 1){
            alert("사고경위를 입력하시거나 자료첨부를 체크해주세요.");
            attachFile.focus();
            return;
        }

        navigate("/write" , {
            state: {
                selectInfo: loc.state.selectInfo,
                accidentType: loc.state.accidentType,
                accidentDetail: showAccidentDetail
            }
        });
    }

    return(
        <Layout>
            <main className="container main_pd text-center">
                <div className="fs-0_5 fw-bold">보험금 청구</div>
                <div className="fs-4 mb-5">보험사선택 - <span className="fw-bold fs-3 text-pink">청구서작성(1/5)</span> - 병원서류첨부 -
                청구내용확인
                </div>
                <div id="openAccidentCause">
                    <div className="fs-2 fw-bold text-start mb-2">
                        <i className="fs-2 bi bi-pencil-square"></i> 사고 상세내용 입력하기</div>
                    <br/>
                    <div id={"accidentCause"}>
                        <div className="d-flex">
                            <div className="fs-4 fw-bold mb-2">사고 경위를 간단히 입력하세요.</div>
                            <div className="ms-auto">
                                <input id={"attachFile"} type={"checkbox"} className={"form-check-input me-2 p-3"} checked={isChecked} onChange={handleCheckboxChange}/>
                                <span className="fs-4 fw-bold">자료첨부</span>
                            </div>
                        </div>
                        <input onChange={checkData} id="fp_info_accidentCause" name="fp_info_accidentCause" className="form-control py-6 mb-4 kiosk-input write-input" type="text"
                               placeholder="사고경위 입력" aria-required="true" />
                    </div>
                </div>
                <div className="footer-fix">
                    <div className="d-flex container">
                        <button type="button" className="btn btn-secondary my-4 px-5 py-6 fs-3 w-100 me-4" onClick={() => window.location.href = `${window.location.origin}/select`}><i
                            className="bi bi-arrow-left-square me-2"></i>아니오 [뒤로가기]
                        </button>
                        <button type="button" className="btn btn-pink my-4 px-5 py-6 fs-3 w-100 ms-4" onClick={() => nextPage()}><i
                            className="bi bi-check2-circle me-2"></i>네
                            [청구서작성]
                        </button>
                    </div>
                </div>
            </main>
        </Layout>
    );
}

export default WriteAccidentDetail; // src\Write\WriteAccidentDetail.tsx