import {ImageResizer} from "./ImageResizer";

export class HtmlConvert {
    private static _convertToNotSupportDOmParser(str : string) {
        const div = document.createElement("div");
        div.innerHTML = str;
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return <HTMLElement> div;
    }

    // @ts-ignore
    static convert(str : string) : HTMLElement{
        if(!window.DOMParser){
            this._convertToNotSupportDOmParser(str);
        }

        try {
            let parser = new DOMParser();

            let doc = parser.parseFromString(str, 'text/html');
            return <HTMLElement>doc.body;
        } catch (error) {
            this._convertToNotSupportDOmParser(str);
        }
    }

    static imageElementToImageData(image : HTMLImageElement){
        let canvas = document.createElement('canvas');
        let wh = ImageResizer.getImageSize(image);
        let width = wh.width;
        let height = wh.height;
        canvas.width = width;
        canvas.height = height;

        let ctx = canvas.getContext("2d");
        ctx!.drawImage(image, 0, 0, width, height);

        let imageData = ctx!.getImageData(0, 0, image.width, image.height);

        return imageData;
    }
}