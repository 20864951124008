declare let pdfjsLib:any;

export class PdfJS{
    doc:any
    pages:any
    heights:any
    width:any
    height:any
    currentPage:any
    scale:any
    dataTransfer:any

    constructor(){
        this.pages = []
        this.heights = []
        this.width = 0
        this.height = 0
        this.currentPage = 1
        this.scale = 1.5
    }

    async getConvertedImageList(pdf_path:any, file_name:any){
        return new Promise((resolve) => {
            this.doc = pdfjsLib.getDocument(pdf_path);
            this.dataTransfer = new DataTransfer();
            this.doc.promise.then(async (pdf:any) => {
                let pageCount:number = pdf.numPages;
                let scale = this.scale;

                for(let i=0; i<Array(pageCount).length;i++){
                    let page = await pdf.getPage(i + 1);

                    let viewport = page.getViewport({ scale });
                    let canvas = document.createElement('canvas');
                    let ctx = canvas.getContext('2d');
                    let renderContext = { canvasContext: ctx, viewport: viewport };

                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    let renderTask = page.render(renderContext);

                    renderTask.promise.then(() => {
                        let newFile = this.convertB64ToFile(canvas.toDataURL("image/jpg"), "jpg", file_name, i+1);
                        this.dataTransfer.items.add(newFile);
                        if (i == pageCount - 1) {
                            resolve(this.dataTransfer.files);
                        }
                    });
                }
            });
        });
    }

    uniqid(prefix = "", ext:any, random = false) {
        const sec = Date.now() * 1000 + Math.random() * 1000;
        const id = sec.toString(16).replace(/\./g, "").padEnd(14, "0");
        return `${prefix}_${id}${random ? `.${Math.trunc(Math.random() * 100000000)}`:""}${ext}`;
    }

    convertB64ToFile(base64Str:any, extension:any, file_name:any, fileCount:any){
        let arr = base64Str.split(',')
        let mime = arr[0].match(/:(.*?);/)[1]
        let bstr = atob(arr[1])
        let n = bstr.length
        let u8arr = new Uint8Array(n)

        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }

        let lastDot = file_name.lastIndexOf(".");
        let filename = file_name.substring(0, lastDot);

        return new File([u8arr], `${filename}_${fileCount}.${extension}`, {type:mime});
    }
}