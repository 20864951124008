export class DentalUserInfoRequest {
    constructor(
        public name: string,
        public insuranceRequest: number,
        public number: string,
        public hospital_name: string,
        public doctor_name: string,
        public license: string,
        public hospitalPhone: string,
        public company_code: string
    ) {
    }
}