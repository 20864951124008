import ModalPortal from "../../Layout/Modal/ModalPortal";
import Modal from 'react-bootstrap/Modal';
import '../../components/css/admin_dashboard.css';
import '../../components/css/admin_dashboard_rtl.css';
import {useEffect, useState} from "react";
import {
    getAdminInsuranceDataByIdx
} from "../AdminData";
import Loading from "../../components/loading/Loading";

const AdminWaitingResultModal = ({ onClose, isOpen, requestId } : any) => {
    const [loading, setLoading] = useState(false);
    const [reclaimList, setReclaimList] = useState<any[]>([]);

    useEffect(() => {
        setLoading(true);

        // requestId를 사용하여 청구내역을 불러옵니다.
        fetchAdminInsuranceDataByIdx(requestId);

        fetchAdminInsuranceDataByIdx(requestId)
            .then(r => {
                console.log("success");
                setLoading(false);
            });
    }, []);


    // 청구내역을 불러오는 함수
    async function fetchAdminInsuranceDataByIdx(requestId: any) {
        const response = await getAdminInsuranceDataByIdx(requestId);
        const requestData = response.data.responseData.requestInfo;
        // requestData를 배열로 변환
        const dataArray = requestData ? [requestData] : [];
        setReclaimList(dataArray);

    }

    // @ts-ignore
        return (
            <ModalPortal>
                {loading ? <Loading/> : null}
                <Modal show={isOpen} size="xl">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">청구 상세내역</h5>
                        <button type="button" className="btn-close" onClick={onClose}
                                aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        {
                            reclaimList.map((reclaimList:any) => (
                                <table className="table table-striped table-hover table-sm">
                                    <thead>
                                    <tr key={"row1"}>
                                        <th scope="col">접수번호</th>
                                        <th scope="col">{reclaimList.idx}번</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr key={"row2"}>
                                        <th scope="row">보험사</th>
                                        <td>{reclaimList.companyName}</td>
                                    </tr>
                                    <tr key={"row3"}>
                                        <th scope="row">팩스 번호</th>
                                        <td>{reclaimList.faxNumber}</td>
                                    </tr>
                                    <tr key={"row4"}>
                                        <th scope="row">사고유형</th>
                                        <td>
                                       {reclaimList.accidentType === 'disease' ? '질병' :
                                        reclaimList.accidentType === 'accident' ? '상해' :
                                        reclaimList.accidentType === 'carAccident' ? '교통사고' : ''}
                                        </td>
                                    </tr>
                                    <tr key={"row3"}>
                                        <th scope="row">사고경위</th>
                                        <td>{reclaimList.accidentDetail === null ? "자료첨부" : reclaimList.accidentDetail}</td>
                                    </tr>
                                    <tr key={"row5"}>
                                        <th scope="row">신청인</th>
                                        <td>{reclaimList.insurerName}</td>
                                    </tr>
                                    <tr key={"row6"}>
                                        <th scope="row">신청인 연락처</th>
                                        <td>{reclaimList.insurerPhone}</td>
                                    </tr>
                                    <tr key={"row7"}>
                                        <th scope="row">신청인 주민번호</th>
                                        <td>{reclaimList.insurerNumber}</td>
                                    </tr>
                                    <tr key={"row8"}>
                                        <th scope="row">수익자</th>
                                        <td>{reclaimList.beneficiaryName}</td>
                                    </tr>
                                    <tr key={"row9"}>
                                        <th scope="row">수익자 연락처</th>
                                        <td>{reclaimList.beneficiaryPhone}</td>
                                    </tr>
                                    <tr key={"row10"}>
                                        <th scope="row">수익자 주민번호</th>
                                        <td>{reclaimList.beneficiaryNumber}</td>
                                    </tr>
                                    <tr key={"row11"}>
                                        <th scope="row">은행명</th>
                                        <td>{reclaimList.bankName}</td>
                                    </tr>
                                    <tr key={"row12"}>
                                        <th scope="row">계좌번호</th>
                                        <td>{reclaimList.bankNumber}</td>
                                    </tr>
                                    <tr key={"row13"}>
                                        <th scope="row">예금주</th>
                                        <td>{reclaimList.bankHolder}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            ))
                        }
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={onClose}>닫기
                        </button>
                    </div>
                </Modal>
            </ModalPortal>
        );
    }
export default AdminWaitingResultModal;