import '../../components/css/kiosk_common.css';
import ModalPortal from "../../Layout/Modal/ModalPortal";
import Modal from 'react-bootstrap/Modal';

const CreditDevModal = ({ onClose, isOpen }: any) => {
    return (
        <ModalPortal>
            <Modal show={isOpen} size="xl">
                <div className="modal-content">
                    <div className="modal-header bg-light">
                        <div className="modal-title fs-2" id="list02Label">개인정보 처리 및 보유기간(필수 동의)</div>
                    </div>
                    <div className="modal-body text-start fs-4 p-4">
                        1) 서비스별 개인정보 처리 및 보존기간<br />
                        보케어는 이용자의 서비스(보험금청구) 이용 시점부터 탈퇴(키오스크 이용서비스) 시점까지 서비스를 이용하는 기간 동안에 한하여 개인 정보를 보유하게 됩니다.<br />
                        2) 관계법령에 따른 개인정보 처리 및 보존기간<br /><br />
                        이용요금의 정산, 소송이나 분쟁 등 필요한 경우를 대비하여 일정기간 보유하여야 할 필요가 있을 경우 개인정보는 일정기간 보유합니다.<br /><br />
                        또한, 전자서명법, 통신비밀보호법, 전자상거래 등 소비자 보호에 관한 법률 등 관계법령에 따라 보존할 필요가 있는 경우 회사는 관계법령에서 정한 기간 동안 회원정보를 보관합니다.<br />
                        이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기관 동안만 보관합니다.<br /><br />
                        가.  전자상거래 등에서 소비자 보호에 관한 법률<br />
                        계약 또는 청약철회 등에 과한 기록 : 5년 보관<br />
                        나. 통신비밀보호법<br />
                        로그인 기록, IP 등 : 1년<br /><br />
                        라. 기타 법령이 정한 기록의 경우 관련 법령에 따름<br /><br />
                        3) 이용자에게 개인정보 보관기간에 대해 동의를 얻은 경우<br />
                        - 회원가입 및 탈퇴를 반복하여 서비스를 부정 이용하는 사례를 막기 위해 탈퇴한 이용자의 정보를 6개월간 보관합니다.<br />
                        - 회원 가입 시 마켓팅 활용 동의를 선택 했을 경우, 개인을 식별할 수 없는 이용자 활동정보(행태정보)를 서비스 종료 전까지 보관합니다.<br />
                        - 정보통신망 이용촉진 및 정보보호에 관한 법률 제29조에 의거하여 최근 1년 동안 서비스의 이용기록이 없는 회원 또는 이용자에게 별도의 동의를 얻은 경우 해당 개인정보는 이 약관에 따라 일정기간 기존 개인정보와 분리하여 보관 됩니다.<br /><br />
                        -부칙-<br />
                        기존 개인정보보호정책은 개인정보처리방침으로 개정합니다.<br />
                        현 개인정보처리방침의 수정, 변경이 있을 시 최소 7일 이전에 사이트 내 공지를 통하여 고지할 것입니다.<br />
                        개인정보처리방침 시행일자 : 2019년 01월 01일<br />
                        이 약관은 2023년 05월 01일부터 시행하며 종전의 약관은 이 약관으로 대체합니다.<br />
                    </div>
                    <div className="modal-footer w-100">
                        <button type="button" className="btn btn-secondary py-3 w-100 fs-4"
                            onClick={onClose}>확인
                        </button>
                    </div>
                </div>
            </Modal>
        </ModalPortal>
    );
}

export default CreditDevModal;