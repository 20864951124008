export class ImageResizer {
    private static maxSize = 2048;

    public static getImageSize(image: { width: number, height: number }, maxSize:number = this.maxSize) {
        let width = image.width;
        let height = image.height;
        if (width > height) {
            if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
            }
        } else {
            if (height > maxSize) {
                width *= maxSize / height;
                height = maxSize;
            }
        }

        return { width: width, height: height };
    }

    public static async resizeImageToBlobAsync (image: ImageData, type?: string, quality?: any) {
        let dataURI = await this.resizeImageDataToBase64StringAsync(image, type, quality);

        let bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
        let mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
        let max = bytes.length;
        let ia = new Uint8Array(max);

        for (let i = 0; i < max; i++){
            ia[i] = bytes.charCodeAt(i);
        }

        return new Blob([ia], { type: mime});
    };

    public static async resizeImageDataToBase64StringAsync(image: ImageData, type?: string, quality?: any) {
        let canvas = document.createElement('canvas');
        let wh = this.getImageSize(image);

        canvas.width = wh.width;
        canvas.height = wh.height;
        let ctx:any = canvas.getContext('2d');
        ctx.putImageData(image, 0, 0);

        let dataUrl = canvas.toDataURL(type, quality);
        return dataUrl;
    };

    public static async rotateBase64ImageAsync(base64data: string, degrees: number, type?: string, quality?: undefined) {
        return new Promise<string>((resolve) => {
            const canvas = document.createElement('canvas');
            let ctx:any = canvas.getContext("2d");
            let image = new Image();

            image.onload = function () {
                canvas.width = degrees % 180 === 0 ? image.width : image.height;
                canvas.height = degrees % 180 === 0 ? image.height : image.width;

                ctx.translate(canvas.width / 2, canvas.height / 2);
                ctx.rotate(degrees * Math.PI / 180);
                ctx.drawImage(image, image.width / -2, image.height / -2);

                resolve(canvas.toDataURL(type, quality));
            };

            image.src = base64data;
        });
    }

    public static reszieImageData(image : HTMLImageElement ,imageData : ImageData){
        let canvas = document.createElement("canvas");
        let wh = this.getImageSize(imageData);

        canvas.width = wh.width;
        canvas.height = wh.height;

        let ctx:any = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, wh.width , wh.height);

        return ctx.getImageData(0, 0, wh.width , wh.height);
    }
}