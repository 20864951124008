import { useNavigate } from "react-router-dom";
import useInterval from "../../components/util/useInterval";
import {useEffect} from "react";

function DentalHeader() {
    const navigate = useNavigate();
    const homeBtn = () => {
        navigate("/dentalPaper");
    }

    //onClick={() => homeBtn()}
    const refreshTime = () => {
        let timeElement = document.getElementById("timeto") as HTMLDivElement;
        let presentTime = new Date();
        let year = presentTime.getFullYear();
        let month = presentTime.getMonth() + 1;
        let date = presentTime.getDate();
        let day = presentTime.getDay();
        let week = ["일", "월", "화", "수", "목", "금", "토"];

        let hour = presentTime.getHours();
        let minutes = presentTime.getMinutes();

        timeElement.innerHTML = `${year}-${("00"+month.toString()).slice(-2)}-${("00"+date.toString()).slice(-2)}(${week[day]}) ${hour}:${("00"+minutes.toString()).slice(-2)}`;
    }

    useInterval(() => {
        refreshTime();
    }, 1000);

    useEffect(() => {
        let adminName = document.getElementById("adminName") as HTMLSpanElement;
        if (!localStorage.getItem("user")) {
            // alert("로그인이 필요합니다.");
            // window.location.replace(`${window.location.origin}/login`);
            adminName.innerHTML = "로그인이 필요합니다.";
        } else {
            let userData = JSON.parse(localStorage.getItem("user") as string);
            adminName.innerHTML = userData.name;
        }
    }, []);

    return (
        <header className="w-100 border-bottom p-2">
            <div className="d-flex justify-content-between align-items-center container text-secondary">
                <div><img src="/image/logo/bocare_white.svg" className="d-block w-75" alt="..."/></div>
                <div id="timeto">2023-02-16(목) 14:24</div>
                <div><span id="adminName"></span> <button className="btn btn-outline-primary rounded-circle" onClick={() => homeBtn()}>홈</button></div>
            </div>
        </header>
    );
}

export default DentalHeader;