import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {createUser, loginUser} from "../components/Jwt/User";
import {SET_TOKEN} from "../components/Jwt/Auth";
import { ErrorMessage } from '@hookform/error-message';
import {setAccessToken, setRefreshToken} from "../components/Jwt/Cookie";
import axios from "axios";
import {getAccessToken, getCookieToken} from "../components/Jwt/Cookie";

function CreateAccount() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { register, formState: { errors }, handleSubmit } = useForm();

    const formatDate = (date:Date) => {
        let year = date.getFullYear();
        let month = ('0' + (date.getMonth() + 1)).slice(-2);
        let day = ('0' + date.getDate()).slice(-2);
        let hours = ('0' + date.getHours()).slice(-2);
        let minutes = ('0' + date.getMinutes()).slice(-2);
        let seconds = ('0' + date.getSeconds()).slice(-2);
        return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    }

    const createAccountKakaoUrl = process.env.REACT_APP_API_URL_ENTRY + "/createAccountKakao";
    const createAccount = async ({ name, account, password, passwordConfirm, address }:any) => {
        // checkForm 함수로 입력값 검증
        if(checkForm({ name, account, password, passwordConfirm, address })) {
            let today = new Date();

            let regDate = formatDate(today);

            let date_3_plus = new Date(today);
            date_3_plus.setDate(date_3_plus.getDate() + 3);
            let expDate = formatDate(date_3_plus);

            let obj:any = {
                "name" : name,
                "account" : account,
                "password" : password,
                "tel" : account,
                "address" : address,
                "access_type" : "dentistPaper",
                "main_logo_path" : "",
                "reg_date" : regDate,
                "expiration_date" : expDate,
                "card_name" : "",
                "card_info" : "",
                "regular_payment_status" : "N"
            }
            // 백으로부터 받은 응답
            const response:any = await createUser(obj);
            console.log(response);
            if(response.status == 200) {
                const response:any = await loginUser({ account, password });
                const data = response.responseData;
                setRefreshToken(data.token.refreshToken);
                setAccessToken(data.token.accessToken);
                dispatch(SET_TOKEN(data.token.accessToken));
                localStorage.setItem("user", JSON.stringify(data.responseData));
                await axios.post(createAccountKakaoUrl, {
                    "mem_name" : name,
                    "templateCode": "boKiosk_alim_003",
                    "phone": account
                }, {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Access-Control-Allow-Origin': '*',
                        'Access_Token': `${getAccessToken()}`,
                        'Refresh_Token': `${getCookieToken()}`
                    },
                    withCredentials: true
                }).then((res) => {
                    console.log("문자 발송완료");
                });
                return navigate("/dentalLogin")
            }
        }
    };
    const checkForm = (data:any) => {
        if (!data.name || !data.account || !data.password || !data.passwordConfirm || !data.address) {
            alert("입력란을 모두 작성하세요.");
            return false;
        }
        if (data.password !== data.passwordConfirm) {
            alert("비밀번호가 일치하지 않습니다.");
            return false;
        }
        return true;
    };
    const autoDeleteSpecialChar = (target: HTMLInputElement) => {
        target.value = target.value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gim, "");
    }
    const checkPasswordConfirm = (target: HTMLInputElement) => {
        let confirmMessage = document.getElementById("confirmMessage") as HTMLInputElement;
        let password = document.getElementById("password") as HTMLInputElement;
        let passwordConfirm = document.getElementById("passwordConfirm") as HTMLInputElement;
        confirmMessage.style.display = "block";
        if(target.value.length > 0 && passwordConfirm.value == password.value) {
            confirmMessage.className = "text-success";
            confirmMessage.innerText = "비밀번호가 일치합니다.";
        } else {
            confirmMessage.className = "text-danger";
            confirmMessage.innerText = "비밀번호가 일치하지 않습니다.";
        }
    }
    return (
        <main className="form-signin text-center bg-light d-flex" style={{minHeight:"100vh", minWidth:"100%"}}>
            <form onSubmit={handleSubmit(createAccount)} className="align-self-center justify-content-center mx-auto" style={{minWidth:"350px"}}>
                <img className="mb-4" src="/image/logo/bocare_white.svg" alt="" width="150" />
                <h1 className="h3 mb-3 fw-normal">회원가입</h1>

                <div className="form-floating mb-2">
                    <input type="text" className="form-control" id="name" placeholder="김보험" {...register("name", {required: "이름을 입력해주세요."})}
                           maxLength={20} onInput={(event) => autoDeleteSpecialChar(event.target as HTMLInputElement)}/>
                    <label>이름</label>
                </div>
                <div className="form-floating mb-2">
                    <input type="number" className="form-control" id="account" placeholder="01012345678" {...register("account", {required: "휴대폰 번호를 입력해주세요."})}
                           maxLength={11} onInput={(event) => autoDeleteSpecialChar(event.target as HTMLInputElement)}/>
                    <label>휴대폰 번호</label>
                </div>
                <p id="idInfo" className="form-floating mb-2">* 휴대폰 번호로 아이디가 설정됩니다.</p>
                <div className="form-floating mb-2">
                    <input type="password" className="form-control" id="password" placeholder="Password" {...register("password", {required: "비밀번호를 입력해주세요."})}
                           maxLength={20} onInput={(event) => checkPasswordConfirm(event.target as HTMLInputElement)}/>
                    <ErrorMessage
                        name="password"
                        errors={errors}
                        render={( { message }) =>
                            <p className="text-sm font-medium text-rose-500">
                                { message }
                            </p>
                        }
                    />
                    <label>비밀번호</label>
                </div>
                <div className="form-floating mb-2">
                    <input type="password" className="form-control" id="passwordConfirm" placeholder="PasswordConfirm" {...register("passwordConfirm", {required: "비밀번호를 입력해주세요."})}
                           maxLength={20} onInput={(event) => checkPasswordConfirm(event.target as HTMLInputElement)}/>
                    <ErrorMessage
                        name="passwordConfirm"
                        errors={errors}
                        render={( { message }) =>
                            <p className="text-sm font-medium text-rose-500">
                                { message }
                            </p>
                        }
                    />
                    <label>비밀번호 확인</label>
                </div>
                <p id="confirmMessage" className="text-danger" style={{display:"none"}}>비밀번호가 일치하지 않습니다.</p>
                <div className="form-floating mb-3">
                    <input type="address" className="form-control" id="address" placeholder="부산광역시 남구 수영로" {...register("address", {required: "주소를 입력해주세요."})} />
                    <label>주소</label>
                </div>
                <button className="w-100 btn btn-lg btn-primary" type="submit" id="createAccountButton" >가입하기</button>
                <p className="mt-5 mb-3 text-muted">BOCARE 고객센터 1566-4875</p>
            </form>
        </main>

    );
}

export default CreateAccount;