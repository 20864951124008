export class AjaxClientV2 {
    static sendGet(url: string, callback: any, errorCallback: any) {
        const xhttp = AjaxClientV2.initialize(callback, errorCallback);

        xhttp.open("GET", url);
        xhttp.send();
    }

    static async sendGetAsync(url: string) : Promise<XMLHttpRequest> {
        return new Promise((resolve , reject)=>{
            const xhttp = this.initialize(resolve , reject)
            xhttp.open("GET", url, true);
            xhttp.send();
        });
    }

    // sendPost(url, query, callback, errorCallback)
    static sendPost(url: string, query: any, callback: (arg0: XMLHttpRequest) => void, errorCallback: (arg0: XMLHttpRequest) => void) {
        const xhttp = AjaxClientV2.initialize(callback, errorCallback);

        xhttp.open("POST", url);
        xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded; charset=UTF-8");
        xhttp.send(query);
    }

    static sendPostFormData(url: string, query: FormData, callback: (arg0: XMLHttpRequest) => void, errorCallback: (arg0: XMLHttpRequest) => void) {
        const xhttp = AjaxClientV2.initialize(callback, errorCallback);

        xhttp.open("POST", url, true);
        xhttp.send(query);
    }

    static sendPostFormDataAsync(url: string, query: FormData) : Promise<XMLHttpRequest> {
        return new Promise((resolve , reject)=>{
            AjaxClientV2.sendPostFormData(url, query, resolve, reject);
        });
    }

    static async sendPostAsync(url: string, query: string | Document | Blob | ArrayBufferView | ArrayBuffer | URLSearchParams | ReadableStream<Uint8Array>) : Promise<XMLHttpRequest>{
        return new Promise((resolve , reject)=>{
            AjaxClientV2.sendPost(url, query, resolve, reject);
        });
    }

    static async sendJsonAsync(url:string, json: string) : Promise<XMLHttpRequest> {
        return new Promise((resolve, reject) => {
            const xhttp = AjaxClientV2.initialize(resolve, reject);
            xhttp.open("POST", url, true);
            xhttp.setRequestHeader("Content-Type", "application/json");
            xhttp.send(json);
        });
    }

    static async sendObjectAsync(url:string, obj: Object) : Promise<XMLHttpRequest> {
        return await AjaxClientV2.sendJsonAsync(url, JSON.stringify(obj));
    }

    static async sendFetchAsync(url : string) : Promise<XMLHttpRequest>{
        return new Promise((resolve, reject)=>{
            const xhttp = this.initialize(resolve, reject);
            xhttp.responseType = "blob";
            xhttp.withCredentials = false;
            xhttp.open("GET", url);
            xhttp.send();
        });
    }

    static initialize(callback: (arg0: XMLHttpRequest) => void, errorCallback: (arg0: XMLHttpRequest) => void) {
        const xhttp = new XMLHttpRequest();
        xhttp.withCredentials = false;

        xhttp.onreadystatechange = function () {
            if(this.readyState == 4) {
                if (this.status == 200) {
                    callback(this);
                }
                else {
                    errorCallback(this);
                }
            }
        };

        return xhttp;
    }
}