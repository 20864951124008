import {useLocation, useNavigate} from "react-router-dom";
import Layout from "../Layout/Layout";
import axios from "axios";
import {getAccessToken, getCookieToken} from "../components/Jwt/Cookie";
import {useEffect, useState} from "react";
import Loading from "../components/loading/Loading";

function Complete() {
    let navigate = useNavigate();
    let loc = useLocation();
    const [loading, setLoading] = useState(false);
    const [kakaoPhoneNum, setKakaoPhoneNum] = useState<string>("");

    const nextPage = () => {
        setLoading(true);
        let info = {...loc.state.beneficiaryInfo, ...loc.state.insurerInfo, ...loc.state.bankInfo};
        info.accidentType = loc.state.accidentType;

        const insuranceInfoSaveUrl = process.env.REACT_APP_API_URL_ENTRY + "/simple_request";
        const kakaoSendUrl = process.env.REACT_APP_API_URL_ENTRY + "/kakaoSend";

        axios.post(insuranceInfoSaveUrl, {
            "selectInfo": JSON.stringify(loc.state.selectInfo),
            "info": JSON.stringify(info),
            "accidentDetail": JSON.stringify(loc.state.accidentDetail),
            "beneficiaryExist": loc.state.beneficiaryExist
        }, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access-Control-Allow-Origin': '*',
                'Access_Token': `${getAccessToken()}`,
                'Refresh_Token': `${getCookieToken()}`
            },
            withCredentials: true
        }).then(async (res) => {
            console.log("저장완료");
            console.log(res);
            if (loc.state.kakaoPhoneNum) {
                await axios.post(kakaoSendUrl, {
                    "map_uuid": res.data.responseData,
                    "templateCode": "boKiosk_alim_002",
                    "phone": loc.state.kakaoPhoneNum
                }, {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Access-Control-Allow-Origin': '*',
                        'Access_Token': `${getAccessToken()}`,
                        'Refresh_Token': `${getCookieToken()}`
                    },
                    withCredentials: true
                }).then((res) => {
                    console.log("발송완료");
                });
            }
            navigate("/final",{
                state: {
                    kakaoPhoneNum: kakaoPhoneNum
                }
            })
        }).catch((err) => {
            console.log(err);
            alert("서버와의 통신중 에러가 발생하였습니다. 관리자에게 문의하여주세요.");
        });
    }

    useEffect(() => {
        setKakaoPhoneNum(loc.state.kakaoPhoneNum)
        const insurer_name = document.getElementById("insurer_name") as HTMLSpanElement;
        const insurer_number = document.getElementById("insurer_number") as HTMLSpanElement;
        const insurer_phone = document.getElementById("insurer_phone") as HTMLSpanElement;
        const selected_info = document.getElementById("selected_info") as HTMLSpanElement;

        insurer_name.innerHTML = loc.state.insurerInfo.insurerName;
        insurer_number.innerHTML = loc.state.insurerInfo.insurerNumber;
        insurer_phone.innerHTML = loc.state.insurerInfo.insurerPhone;
        for (let i = 0; i < loc.state.selectInfo.length; i++) {
            if (i === 0) {
                selected_info.innerHTML = loc.state.selectInfo[i].name;
            } else {
                selected_info.innerHTML += ", " + loc.state.selectInfo[i].name;
            }
        }
    }, []);

    return (
        <Layout>
            {loading ? <Loading /> : null}
            <main className="container main_pd text-center">
                <div className="fs-0_5 fw-bold">보험금 청구</div>
                <div className="fs-4 mb-5">보험사선택 - 청구서작성 - 병원서류첨부 - <span
                    className="fw-bold fs-3 text-pink">청구내용확인</span>
                </div>
                <div className="py-5">
                    <div className="fs-2 fw-bold text-start mb-2"><i className="bi bi-check2-circle me-2"></i>청구 전 마지막으로
                        확인하세요.
                    </div>
                    <div className="mb-3 w-100 border">
                        <div className="text-start p-5">
                            <div className="fs-1 fw-bold mb-4">인적사항</div>
                            <div className="d-flex fs-3 w-100 mb-3">
                                <div className="w-50">· 진료받으신 환자</div>
                                <div className="w-50 fw-bold text-primary">
                                    <span id="insurer_name"></span>
                                </div>
                            </div>
                            <div className="d-flex fs-3 w-100 mb-3">
                                <div className="w-50">· 주민등록번호</div>
                                <div className="w-50 fw-bold text-primary">
                                    <span id="insurer_number"></span>
                                </div>
                            </div>
                            <div className="d-flex fs-3 w-100">
                                <div className="w-50">· 연락처</div>
                                <div className="w-50 fw-bold text-primary">
                                    <span id="insurer_phone"></span>
                                </div>
                            </div>
                            <hr className="secondary my-5"/>
                            <div className="fs-1 fw-bold mb-4">선택보험사</div>
                            <div className="fs-3 fw-bold text-primary">
                                <span id="selected_info"></span>
                            </div>
                            <hr className="secondary my-5"/>
                            <div className="fs-1 fw-bold mb-4">병원서류 첨부</div>
                            {kakaoPhoneNum === "" ?
                                <div className="fs-3 fw-bold text-primary"><span className="text-pink">관리자가 전송</span>

                                </div>
                                :
                                <div className="fs-3 fw-bold text-primary"><span className="text-pink">폰으로 사진 전송</span><br/>※
                                    해당 안내는 문자 발송되며 사진 첨부 URL주소는 발송 후 24시간 유효합니다.
                                </div>
                            }
                        </div>
                        <div className="d-flex align-items-center text-center bg-light fs-4 p-4">
                            <div>보험금청구는 각 보험사 사정에 따라 <span className="fw-bold">1-2일이 소요</span>됩니다.</div>
                        </div>

                    </div>
                </div>
                <div id="footer_fixed" className="footer-fix">
                    <div className="d-flex container">
                        <button type="button" className="btn btn-secondary my-4 px-5 py-6 fs-3 w-100 me-4"
                                onClick={() => window.history.back()}><i
                            className="bi bi-arrow-left-square me-2"></i>아니오 [뒤로가기]
                        </button>
                        <button type="button" className="btn btn-pink my-4 px-5 py-6 fs-3 w-100 ms-4"
                                onClick={() => nextPage()}><i
                            className="bi bi-send me-2"></i>청구 진행
                        </button>
                    </div>
                </div>

            </main>
        </Layout>
    );
}

export default Complete;