import ModalPortal from "../../Layout/Modal/ModalPortal";
import Modal from 'react-bootstrap/Modal';
import '../../components/css/admin_dashboard.css';
import '../../components/css/admin_dashboard_rtl.css';
import {useEffect, useState} from "react";
import {AzureBlobStorage} from "../../AzureStorage/AzureStorage";
import axios from "axios";
import Loading from "../../components/loading/Loading";
import {InsuranceImageScan} from "../../components/ImageResize/InsuranceImageScan";
import {AjaxClientV2} from "../../components/util/AjaxClientV2";
import {PdfJS} from "../../components/util/PdfJS";
import {
    getAdminDentalConfirmDataByIdxWithPdf,
} from "../AdminData";

declare let jsPDF:any;

const AdminFileAttachModal = ({onClose, isOpen, requestId}: any) => {
    const azure = new AzureBlobStorage("insurancerequest");
    const [showImages, setShowImages] = useState([]);
    const [uploadList, setUploadList] = useState([]);
    const [idCard, setIdCard] = useState([]);
    const [uploadDBList, setUploadDBList] = useState([]);
    const [loading, setLoading] = useState(false);

    let scanImageList = new Array<{
        imageName:string,
        format:string,
        image:string
    }>();

    const GetScanImage = async (file:any) => {
        const extension = file.name.substring(file.name.length, file.name.lastIndexOf('.')).toLowerCase();
        await readFileAsync(file, extension);

    }

    useEffect(() => {
        setLoading(true);
        fetchAdminInsuranceDataByIdx(requestId);
        setLoading(false)

    }, []);

    function readFileAsync(file:any, extension:any) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = async() => {
                // resolve(reader.result);
                let imageName = file.name;
                let imagePath:any = reader.result;
                let format = InsuranceImageScan.getExtensionFormat(extension);

                if (format == "") {
                    return;
                }

                let base64 = await InsuranceImageScan.imageScanAsync(imagePath, extension);

                let formData = new FormData();

                let base64_split = base64.split(',');
                let byteString = atob(base64_split[1]);
                let max = byteString.length;
                let uint8Array = new Uint8Array(byteString.length);
                let mime_type = base64_split[0].split(':')[1].split(';')[0];

                for (let i = 0; i < max; i++) {
                    uint8Array[i] = byteString.charCodeAt(i);
                }

                let blob = new Blob([uint8Array], { type: mime_type });

                formData.append("image", blob);
                let response = await AjaxClientV2.sendPostFormDataAsync("https://bocareimagefunction.azurewebsites.net/api/scan", formData
                );
                let data = response.responseText;

                let tempObject = {
                    imageName: imageName,
                    format: format,
                    image: `${base64_split[0]},${data}`
                };

                scanImageList.push(tempObject);
                resolve(scanImageList);
            };
            reader.readAsDataURL(file);
            reader.onerror = reject;
        });

    }


    const handleFileSubmit = async() => {
        setLoading(true);

        if(uploadList.length === 0){
            alert("보험금 청부서류를 첨부해주세요.");
            setLoading(false);
            return;
        }

        if(uploadList.length > 50){
            alert("보험금 청부서류는 최대 50장까지 첨부가능합니다.");
            setLoading(false);
            return;
        }

        // if(idCard.length === 0){
        //     alert("신분증을 첨부해주세요.");
        //     setLoading(false);
        //     return;
        // }

        let date = new Date();
        let year = date.getFullYear();
        let month = (("00" + (date.getMonth() + 1).toString()).slice(-2));
        let day = (("00" + date.getDate().toString()).slice(-2));

        const promises = [];
        let uploadDB:any = [...uploadDBList];

        let fileList = [...uploadList, ...idCard];

        let elementName: string;
        let blob: Blob;
        let checkFirstImage:boolean = false;
        let imageName: string;

        //pdf열기(file 객체)
        let pdfDoc = new jsPDF("p", "mm", "a4", true);
        pdfDoc.deletePage(1);

        let pdfPromise:any = [];

        for await(let file of fileList){
            pdfPromise.push(GetScanImage(file));
        }

        await Promise.all(pdfPromise);

        for (let i = 0; i < fileList.length; i++) {
            let file: File = fileList[i];
            //bocare logic
            if (!checkFirstImage) {
                let base64_split = scanImageList[i].image.split(',');
                let byteString = atob(base64_split[1]);
                let max = byteString.length;
                let uint8Array = new Uint8Array(byteString.length);
                let mime_type = base64_split[0].split(':')[1].split(';')[0];

                for (let i = 0; i < max; i++) {
                    uint8Array[i] = byteString.charCodeAt(i);
                }
                blob = new Blob([uint8Array], {type: mime_type});
                checkFirstImage = true;
                elementName = "image_file";
                imageName = "imageThumbnail.jpg";

                const data = new DataTransfer();
                let newFile = new File([blob], `${imageName}`, { type: blob['type'] });
                data.items.add(newFile);
                file = data.files[0];
            } else {
                imageName = "mergeAttachImage.pdf";
                pdfDoc.addPage("a4", "p");
                pdfDoc.addImage(scanImageList[i].image, scanImageList[i].format, 0, 0, 210, 297, undefined, 'FAST'); //imageData as base64 encoded DataUrl or Image-HTMLElement or Canvas-HTMLElement
                blob = pdfDoc.output('blob');
                elementName = "image2pdf";

                const data = new DataTransfer();
                let newFile = new File([blob], `${imageName}`, { type: blob['type'] });
                data.items.add(newFile);
                file = data.files[0];
            }

            const ext = file.name.substring(file.name.length, file.name.lastIndexOf('.')).toLowerCase();
            let filePath: string = `${year}/${year}-${month}-${day}/${azure.uniqid("insurancerequest", ext)}`;
            //Image to Pdf
            if( i == 0 || i == fileList.length - 1) {
                uploadDB.push({
                    file_name: azure.uniqid("보험금청구_팩스_첨부파일", ext),
                    file_path: filePath,
                    file_size: file.size
                });
                promises.push(azure.UploadInsuranceRequestAsync(file, filePath));
            }
        }

        await Promise.all(promises);
        promises.length = 0;

        for(let i = 0; i < fileList.length; i++) {
            let file:File = fileList[i];
            const ext = file.name.substring(file.name.length, file.name.lastIndexOf('.')).toLowerCase();
            let filePath:string = `${year}/${year}-${month}-${day}/${azure.uniqid("insurancerequest", ext)}`;
            uploadDB.push({
                file_name: file.name,
                file_path: filePath,
                file_size: file.size
            })
            promises.push(azure.UploadInsuranceRequestAsync(file, filePath));
        }
        await Promise.all(promises);
        setUploadDBList(uploadDB);

        if (fileList.length > 1) {
            await axios.post(`${process.env.REACT_APP_API_URL_ENTRY}/admin/save`
                ,{"uploadFileInfo" : JSON.stringify(uploadDB)
                , "requestId":requestId.toString()
                }).then((res) => {
                alert("파일 업로드가 완료되었습니다. 팩스는 자동으로 발송됩니다.");
                window.location.reload();
            }).catch((err) => {
                console.log(err);
                alert("파일 업로드 도중 에러가 발생하였습니다. 관리자에게 문의하여주세요.");
            });
        } else {
            await axios.post(`${process.env.REACT_APP_API_URL_ENTRY}/admin/saveOneImage`
                ,{"uploadFileInfo" : JSON.stringify(uploadDB)
                    , "requestId":requestId.toString()
                }).then((res) => {
                alert("파일 업로드가 완료되었습니다. 팩스는 자동으로 발송됩니다.");
                window.location.reload();
            }).catch((err) => {
                console.log(err);
                alert("파일 업로드 도중 에러가 발생하였습니다. 관리자에게 문의하여주세요.");
            });
        }
    }

    const handleAddIdCard = (event: any) => {
        const imageLists = event.target.files;
        let imageUrlLists: any = [...showImages];
        let fileLists: any = [...idCard];

        for (let i = 0; i < imageLists.length; i++) {
            const currentImageUrl: any = URL.createObjectURL(imageLists[i]);
            imageUrlLists.push(currentImageUrl);
            fileLists.push(imageLists[i]);
        }

        setIdCard(fileLists);
        setShowImages(imageUrlLists);
    };

    const handleAddImages = async (event:any) => {
        const imageLists = event.target.files;

        //파일 첨부 취소 시 종료
        if (imageLists.length === 0) {
            return;
        }

        let imageUrlLists:any = [...showImages];
        let fileLists:any = [...uploadList];

        let reader = new FileReader();
        let blob = new Blob([imageLists[0]], { type: "application/pdf" });
        let ext = imageLists[0].name.split(".")[1];
        reader.readAsDataURL(blob);
        reader.onload = async () => {
            let path = reader.result;
            if (ext === "pdf") {
                //로딩 화면
                setLoading(true);
                let pdfJs: any = new PdfJS();
                let pdfLists = await pdfJs.getConvertedImageList(path, imageLists[0].name);
                for await (let pdfList of pdfLists) {
                    const currentImageUrl: any = URL.createObjectURL(pdfList);
                    imageUrlLists.push(currentImageUrl);
                    fileLists.push(pdfList);
                }
                setLoading(false);
            } else {
                const currentImageUrl: any = URL.createObjectURL(imageLists[0]);
                imageUrlLists.push(currentImageUrl);
                fileLists.push(imageLists[0]);
            }
            setUploadList(fileLists);
            setShowImages(imageUrlLists);
        }
    };

    const handleDeleteImage = (id:any) => {
        setShowImages(showImages.filter((_, index) => index !== id));
        setUploadList(uploadList.filter((_, index) => index !== id));
    };


    async function fetchAdminInsuranceDataByIdx(requestId: any) {
        //Pdf response 가져오기
        const responsePdf = await getAdminDentalConfirmDataByIdxWithPdf(requestId);
        let imageUrlLists: any = [...showImages];
        let fileLists: any = [...uploadList];

        // 반복문 내에서 파일 및 Blob 생성 및 FileReader 생성
        for (const fileInfo of responsePdf.data.responseData.requestFileInfo) {
            // base64 to file
            let bstr = atob(fileInfo.base64Data),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            const file = new File([u8arr], fileInfo.fileName, { type: "application/pdf" });
            const blob = new Blob([file], { type: "application/pdf" });

            let reader = new FileReader();

            reader.readAsDataURL(blob);
            reader.onload = async () => {
                let path = reader.result;
                setLoading(true);
                let pdfJs: any = new PdfJS();
                let pdfLists = await pdfJs.getConvertedImageList(path, file.name);
                for await (let pdfList of pdfLists) {
                    const currentImageUrl: any = URL.createObjectURL(pdfList);
                    imageUrlLists.push(currentImageUrl);
                    fileLists.push(pdfList);
                }
                setLoading(false);
                setUploadList(fileLists);
                setShowImages(imageUrlLists);
            };
        }
    }




    return (
        <ModalPortal>
            {loading ? <Loading /> : null}
            <Modal show={isOpen} size="xl">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">청구서류 첨부</h5>
                            <button type="button" className="btn-close" onClick={onClose}
                                    aria-label="Close"></button>
                        </div>
                
                    <div className="modal-body">
                        <div className="mb-2">※ 보험금청구 시 <span className="text-pink fw-bold">병원 첨부서류</span>와 <span className="text-pink fw-bold">주민등록증 or 운전면허증</span>을
                        함께 첨부해주세요.

                        <div className="fs-6_5">(서류가 누락 될 경우 보험금 지급 및 금액이 제대로 지급이 안될 수 있습니다)</div>


                    </div>
                            <div className="input-group mb-3">
                                <div className="fw-bold fs-6 w-100 mb-1">① 청구서류 파일 첨부 (PDF파일 청구 가능)</div>
                                <input type="file" className="form-control" id="fileAttach" name="fileAttach" onChange={handleAddImages}/>
                            </div>
                            <div className="input-group mb-3">
                            <div className="fw-bold fs-6 w-100 mb-1">② 신분증 파일 첨부 (필수 아님)</div>
                                <input type="file" className="form-control" id="fileAttach" name="fileAttach" onChange={handleAddIdCard}/>
                            </div>

                            <div id="noDataDiv"
                                 className="col-md-12 text-center text-secondary card px-3 py-5 mt-3"
                                 style={{display: "none"}}>
                                <div id="" className="fs-2 mb-2">첨부하신 파일이 없습니다.</div>
                            </div>

                            <div id="file_wrapper" className="row">
                                {showImages.map((image, id) => (
                                    <div className="col-4 mb-3" key={id}>
                                        <div className="border card h-100 p-3">
                                            <div className="w-100 d-flex flex-wrap h-100 text-center">
                                                <div className="w-100 align-self-center">
                                                    <img className="img-fluid" src={image}
                                                        // onError="this.onerror=null; this.src='{{error}}'"
                                                         alt={`${image}-${id}`}/>
                                                </div>
                                                <div className="w-100 align-self-end">
                                                    <button id="delete_{{idx}}" type="button" onClick={() => handleDeleteImage(id)}
                                                            className="btn btn-secondary mt-2">삭제
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <input type="file" className='d-none' id="file_{{idx}}" name="file_{{idx}}" />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={onClose}>닫기
                            </button>
                            <button onClick={() => handleFileSubmit()} type="button" className="btn btn-primary">첨부하기</button>
                        </div>
            </Modal>
        </ModalPortal>
    );
}

export default AdminFileAttachModal;