import AdminLayout from "../Layout/AdminLayout";
import '../components/css/admin_dashboard.css';
import '../components/css/admin_dashboard_rtl.css';
import ModalPortal from "../Layout/Modal/ModalPortal";
import AdminFileAttachModal from "./Modal/AdminFileAttachModal";
import AdminReclaimModal from "./Modal/AdminReclaimModal";
import {useEffect, useState} from "react";
import {getAdminInsuranceData} from "./AdminData";
import Pagination from 'react-js-pagination';
import styled from 'styled-components';
import AdminResultModal from "./Modal/AdminResultModal";
import AdminWaitingResultModal from "./Modal/AdminWaitingResultModal";
import AdminDentalConfirmModal from "./Modal/AdminDentalConfirmModal";
import AdminDentalFlyerModal from "./Modal/AdminDentalFlyerModal";
// import {start} from "repl";
// import {start} from "repl";

function AdminMainJsx() {
    const [adminFileAttachModalOpen, setAdminFileAttachModalOpen] = useState();
    const [insLists, setInsLists] = useState([]);
    const [searchList, setSearchList] = useState([]);
    const [requestId, setRequestId] = useState();
    const [companyCode, setCompanyCode] = useState();

    const [adminReclaimModalOpen, setAdminReclaimModalOpen] = useState();
    const [adminResultModalOpen, setAdminResultModalOpen] = useState();
    const [adminWaitingResultModalOpen, setAdminWaitingResultModalOpen] = useState();
    const [adminDentalConfirmModalOpen, setAdminDentalConfirmModalOpen] = useState();
    const accessType = JSON.parse(localStorage.getItem("user")).access_type;

    const allowedCompanyCodes = [7, 19, 11, 12, 3, 14, 32, 25, 30, 31, 24, 37]; // 치과치료 확인서 추가 시 추가해줘야 함.

    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [items, setItems] = useState(10);

    //입력값 가져오기, 소문자로 변경
    const [userInput, setUserInput] = useState('');
    const getValue = (e) => {
        setUserInput(e.target.value.toLowerCase())
    };

    //설정한 달력 날짜 가져오기
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const getStartDate = (e) => {
        const startDateValue = e.target.value;
        setStartDate(startDateValue);
    }
    const getEndDate = (e) => {
        const endDateValue = e.target.value;
        setEndDate(endDateValue);
    }

    const handlePageChange = (page) => {
        setPage(page);
    };
    const itemChange = (e) => {
        setItems(Number(e.target.value))
    }

    const HandleAdminFileAttachModalShow = () => {
        setAdminFileAttachModalOpen(false);
    };

    const HandleRequestId = (requestId) => {
        setRequestId(requestId);
        setAdminFileAttachModalOpen(true);
    }

    const HandleResultId = (requestId) => {
        setRequestId(requestId);
        setAdminResultModalOpen(true);
    }

    const HandleWaitingResultId = (requestId) => {
        setRequestId(requestId);
        setAdminWaitingResultModalOpen(true);
    }

    const ReclaimRequestId = (requestId) => {
        setRequestId(requestId);
        setAdminReclaimModalOpen(true);
    }

    const HandleAdminDentalConfirmResultId = (requestId , companyCode) => {
        setRequestId(requestId);
        setCompanyCode(companyCode);
        setAdminDentalConfirmModalOpen(true);
    }

    const HandleAdminDentalConfirmModalShow = () => {
        setAdminDentalConfirmModalOpen(false);
    }

    const HandleAdminReclaimModalShow = () => {
        setAdminReclaimModalOpen(false);
    };

    const HandleAdminResultModalShow = () => {
        setAdminResultModalOpen(false);
    };

    const HandleAdminWaitingResultModalShow = () => {
        setAdminWaitingResultModalOpen(false);
    };

    const ShowAllList = () => {
        setSearchList(insLists);
    }

    const ShowNotCompleteList = () => {
        let tempList = insLists.filter((item) => {
            return item.status === 10;
        });

        setSearchList(tempList);
    }

    const ShowCompleteList = () => {
        let tempList = insLists.filter((item) => {
            return item.status !== 10;
        });

        setSearchList(tempList);
    }

    const regTest = (date) => {
        let regex = RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);

        if(regex.test(date)){
            return true;
        }
        return false;
    }

    const SearchList = () => {
        let searchTemporary =  insLists.filter((searchItem) => {
            return (
                searchItem.insurerName.includes(userInput) ||
                searchItem.insurerPhone.includes(userInput)
            )//검색어를 이름, 연락처 중에서 찾아서 필터링
        })

        if(startDate != undefined && regTest(startDate) && endDate != undefined && regTest(endDate)){
            searchTemporary = searchTemporary.filter((searchDate) => {
                return (
                    searchDate.requestDate.slice(0,10) >= startDate && searchDate.requestDate.slice(0,10) <= endDate
                )
            })
        }
        setSearchList(searchTemporary);
    }

    const dentistPaperHandleLinkClick = (event) => {
        const info = JSON.parse(localStorage.getItem("user"));
        if (info.access_type === "dentistPaper") {
            event.preventDefault(); // 기본 동작(페이지 이동)을 막음
            alert("이용이 불가능한 서비스입니다. 이용을 원하시면 고객센터(1566-4875)로 연락주시기 바랍니다."); // 알림 창 표시
        }
    };

    const defaultHandleLinkClick = (event) => {
        const info = JSON.parse(localStorage.getItem("user"));
        if (info.access_type === "default") {
            event.preventDefault(); // 기본 동작(페이지 이동)을 막음
            alert("이용이 불가능한 서비스입니다. 이용을 원하시면 고객센터(1566-4875)로 연락주시기 바랍니다."); // 알림 창 표시
        }
    };

    const PaginationBox = styled.div`
  .pagination { display: flex; justify-content: center; margin-top: 15px;}
  ul { list-style: none; padding: 0; }
  ul.pagination li {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 1px solid #e2e2e2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem; 
  }
  ul.pagination li:first-child{ border-radius: 5px 0 0 5px; }
  ul.pagination li:last-child{ border-radius: 0 5px 5px 0; }
  ul.pagination li a { text-decoration: none; color: #337ab7; font-size: 1rem; }
  ul.pagination li.active a { color: white; }
  ul.pagination li.active { background-color: #337ab7; }
  ul.pagination li a:hover,
  ul.pagination li a.active { color: blue; }`;

    useEffect(() => {
        async function fetchAdminInsuranceData() {
            const response = await getAdminInsuranceData();
            setInsLists(response.data.responseData);
            setSearchList(response.data.responseData);
        }

        fetchAdminInsuranceData().then(r => console.log("success"));
    }, []);

    useEffect(() => {
        SearchList();
    }, [userInput, startDate, endDate]);

    const convertCompanyCode = (code) => {
        switch(code){
            case 2:
                return "iM생명";
            case 3:
                return "라이나생명";
            case 4:
                return "KDB생명";
            case 5:
                return "DB생명";
            case 6:
                return "미래에셋생명";
            case 7:
                return "ABL생명";
            case 8:
                return "메트라이프생명";
            case 9:
                return "푸르덴셜생명";
            case 10:
                return "삼성생명";
            case 11:
                return "한화생명";
            case 12:
                return "흥국생명";
            case 13:
                return "교보생명";
            case 14:
                return "신한라이프";
            case 15:
                return "푸본현대생명";
            case 16:
                return "하나생명";
            case 17:
                return "NH농협생명";
            case 18:
                return "ing생명";
            case 19:
                return "동양생명";
            case 20:
                return "AIA생명";
            case 21:
                return "처브라이프생명";
            case 23:
                return "KB라이프생명";
            case 24:
                return "메리츠화재";
            case 25:
                return "한화손해보험";
            case 26:
                return "롯데손해보험";
            case 27:
                return "MG손해보험";
            case 28:
                return "흥국화재";
            case 29:
                return "삼성화재";
            case 30:
                return "현대해상화재";
            case 31:
                return "KB손해보험";
            case 32:
                return "DB손해보험";
            case 33:
                return "우체국보험";
            case 34:
                return "AXA손해보험";
            case 35:
                return "AIG손해보험";
            case 36:
                return "하나손해보험";
            case 37:
                return "NH농협손해보험";
            case 38:
                return "Chubb";
            case 39:
                return "라이나손해보험";
            case 40:
                return "BNP파리바카디프손해보험";
            case 41:
                return "BNP파리바카디프생명";
            case 42:
                return "오렌지라이프";
            case 43:
                return "NH농협금융";
            case 44:
                return "DB손해보험(펫보험)";
            case 45:
                return "메리츠화재(펫보험)";
            case 46:
                return "삼성화재(펫보험)";
            case 47:
                return "한화손해보험(펫보험)";
            case 48:
                return "현대해상화재(펫보험)";
            case 49:
                return "롯데손해보험(펫보험)";
            default :
                return "청구불가보험사";
        }
    }

    return (
        <AdminLayout>
            <div className="container-fluid">
                <div className="row">
                    <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
                        <div className="position-sticky pt-3">
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="/admin/main/" onClick={dentistPaperHandleLinkClick}>
                                        <span data-feather="home"></span>
                                        보험금청구
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" aria-current="page"
                                       href="/admin/management/kiosk/" onClick={dentistPaperHandleLinkClick}>
                                        <span data-feather="home"></span>
                                        키오스크 관리
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" aria-current="page"
                                       href="/admin/management/member/">
                                        <span data-feather="home"></span>
                                        회원관리
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" aria-current="page" href="/admin/dental/" onClick={defaultHandleLinkClick} >
                                        <span data-feather="home"></span>
                                        치과치료확인서
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </nav>

                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                        <div
                            className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h1 className="h2">보험금청구 리스트</h1>
                            <div className="btn-toolbar mb-2 mb-md-0">

                                <div className="btn-group">
                                    <button id="showAll" type="button" onClick={() => ShowAllList()} className="btn btn-sm btn-secondary">전체</button>
                                    <button id="showAll" type="button" onClick={() => ShowNotCompleteList()}
                                            className="btn btn-sm btn-outline-secondary">접수대기
                                    </button>
                                    <button id="showComplete" type="button" onClick={() => ShowCompleteList()}
                                            className="btn btn-sm btn-outline-secondary">접수완료
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex mb-4">
                            <div className="input-group search-box-size">
                                <input type="date" id="startDate" className="form-control" placeholder="" onChange={getStartDate}/> <span
                                className="px-2 pt-1"> ~ </span> <input type="date" id="endDate" className="form-control"

                                                                        placeholder="" onChange={getEndDate}/>
                            </div>
                            <div className="input-group search-box-size ms-auto">

                                <input type="text" className="form-control" placeholder="이름, 연락처를 입력하세요."
                                       aria-label="이름, 연락처를 입력하세요."
                                       aria-describedby="customerSearchButton" id="searchKeyword" name="searchKeyword"
                                       onChange={getValue}
                                />

                            </div>
                        </div>
                        <div id="billWrapper">
                            <div id="insuranceBillBody"></div>
                            {/*<div navigation="/v2/views/paging/page_navigator_v2.html"></div>*/}
                        </div>

                        <div className="table-responsive mb-3">
                            <table className="table table-striped table-hover table-sm">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">보험사</th>
                                    <th scope="col">신청인</th>
                                    <th scope="col">신청인 연락처</th>
                                    <th scope="col">접수일</th>
                                    <th scope="col">접수관리</th>
                                    <th scope="col">상세내역</th>
                                    {accessType !== "default" ? (
                                        <th scope="col">치과치료확인서</th>
                                    ):null}

                                </tr>
                                </thead>
                                <tbody>
                                {
                                    searchList && searchList
                                        .slice(items * (page - 1), items * (page - 1) + items)
                                        .map((searchList) => (
                                            <tr key={searchList.idx}>
                                                <td className="py-2">{searchList.idx}</td>
                                                <td className="py-2">{convertCompanyCode(searchList.companyCode)}</td>
                                                <td className="py-2">{searchList.insurerName}</td>
                                                <td className="py-2">{searchList.insurerPhone}</td>
                                                <td className="py-2">{searchList.requestDate}</td>
                                                {searchList.status === 10
                                                    ? <td><a className="btn btn-pink btn-sm" role="button"
                                                             onClick={() => HandleRequestId(searchList.idx)}>접수대기(파일첨부)</a></td>
                                                    : <td><a className="btn btn-dark btn-sm" role="button"
                                                             onClick={() => ReclaimRequestId(searchList.idx)}>&nbsp;접수완료&nbsp;(재청구)&nbsp;</a></td>
                                                }
                                                {searchList.status === 10
                                                    ? <td><a className="btn btn-info btn-sm" role="button"
                                                             onClick={() => HandleWaitingResultId(searchList.idx)}>상세내역</a></td>
                                                    : <td><a className="btn btn-info btn-sm" role="button"
                                                             onClick={() => HandleResultId(searchList.idx)}>상세내역</a></td>
                                                }
                                                <td>
                                                    {accessType !== "default" && allowedCompanyCodes.includes(searchList.companyCode) && searchList.status === 10 ? (
                                                        <a className="btn btn-warning btn-sm" role="button" onClick={() => HandleAdminDentalConfirmResultId(searchList.idx, searchList.companyCode)}>작성</a>
                                                    ) : null}
                                                </td>
                                            </tr>
                                        ))
                                }

                                </tbody>
                            </table>
                            <PaginationBox>
                                <Pagination activePage={page}
                                            itemsCountPerPage={items}
                                            totalItemsCount={searchList.length - 1}
                                            pageRangeDisplayed={5}
                                            onChange={handlePageChange}>
                                </Pagination>
                            </PaginationBox>
                        </div>

                        {adminFileAttachModalOpen && (
                            <ModalPortal>
                                <AdminFileAttachModal onClose={HandleAdminFileAttachModalShow} isOpen={true}
                                                      requestId={requestId}/>
                            </ModalPortal>
                        )}

                        {adminReclaimModalOpen && (
                            <ModalPortal>
                                <AdminReclaimModal onClose={HandleAdminReclaimModalShow} isOpen={true}
                                                   requestId={requestId}/>
                            </ModalPortal>
                        )}

                        {adminResultModalOpen && (
                            <ModalPortal>
                                <AdminResultModal onClose={HandleAdminResultModalShow} isOpen={true}
                                                  requestId={requestId}/>
                            </ModalPortal>
                        )}

                        {adminWaitingResultModalOpen && (
                            <ModalPortal>
                                <AdminWaitingResultModal onClose={HandleAdminWaitingResultModalShow} isOpen={true}
                                                  requestId={requestId}/>
                            </ModalPortal>
                        )}

                        {adminDentalConfirmModalOpen && (
                            <ModalPortal>
                                <AdminDentalConfirmModal onClose={HandleAdminDentalConfirmModalShow} isOpen={true}
                                                         requestId={requestId} companyCode={companyCode}/>
                            </ModalPortal>
                        )}



                        <div id="noDataDiv" className="col-md-12 text-center text-secondary card px-3 py-5 mt-3"
                             style={{display: "none"}}>
                            <div id="" className="fs-2 mb-2">보험금청구 완료 내역이 없습니다.</div>
                        </div>

                        <div id="noSearchData" className="col-md-12 text-center text-secondary card px-3 py-5 mt-3"
                             style={{display: "none"}}>
                            <div className="fs-2 mb-2">조회된 데이터가 없습니다.</div>
                            <div className="fs-5 mb-0">다시 확인해주세요.</div>
                            <div className="mt-4 fw-bold">
                                <button id="reloadButton" className="btn btn-outline-secondary"><i
                                    className="bi bi-arrow-clockwise me-1"></i>뒤로가기
                                </button>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </AdminLayout>
    );
}

export default AdminMainJsx;