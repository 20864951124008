import ModalPortal from "../../Layout/Modal/ModalPortal";
import {Modal} from "react-bootstrap";
import '../../components/css/kiosk_common.css';
import {useNavigate} from "react-router-dom";

function SendTypeModal({ onClose, isOpen, selectInfo, accidentType, accidentDetail, insurerInfo, beneficiaryExist, beneficiaryInfo, bankInfo}: any){
    let navigate = useNavigate();

    const nextPage = () => {
        let phone_num_first = document.getElementById("phone_num_first") as HTMLInputElement;
        let phone_num_second = document.getElementById("phone_num_second") as HTMLInputElement;
        let phone_num_third = document.getElementById("phone_num_third") as HTMLInputElement;

        if(phone_num_first.value.length < 3 || phone_num_second.value.length < 4 || phone_num_third.value.length < 4){
            alert("연락처를 정확히 기입해주세요.");
            return;
        }

        let phone_num = phone_num_first.value + "-" + phone_num_second.value + "-" + phone_num_third.value;

        navigate("/complete",{
            state: {
                selectInfo: selectInfo,
                accidentType: accidentType,
                accidentDetail: accidentDetail,
                insurerInfo: insurerInfo,
                beneficiaryExist: beneficiaryExist,
                beneficiaryInfo: beneficiaryInfo,
                bankInfo: bankInfo,
                kakaoPhoneNum: phone_num
            }
        })
    }

    return (
        <ModalPortal>
            <Modal show={isOpen} size="xl">
                    <div className="modal-content">
                        <div className="modal-header bg-light">
                            <div className="modal-title fs-1" id="picture_phoneLabel"><i
                                className="bi bi-image me-2"></i>사진 보내실 분 연락처를
                                기입해주세요.
                            </div>
                        </div>
                        <div className="modal-body text-center fs-2 p-4 py-6">
                            <div className="mb-5">연락처 기입 후<br/> 저장을 하시면 마지막 단계에서<br/><span className="text-pink"
                                                                              style={{textDecoration:"underline"}}>청구서류 첨부링크가 포함된 알림톡</span>이 발송됩니다.
                            </div>
                            <div className="d-flex align-items-center">
                                <input style={{fontSize:"2.6rem"}} type="tel" className="form-control py-6 kiosk-input write-input " name="phone_input" id="phone_num_first"
                                       maxLength={3} placeholder="ex)010"/>
                                    <div className="px-2">-</div>
                                    <input style={{fontSize:"2.6rem"}} type="tel" className="form-control py-6 kiosk-input write-input" name="phone_input"
                                           id="phone_num_second"
                                           maxLength={4} placeholder="1234"/>
                                        <div className="px-2">-</div>
                                        <input style={{fontSize:"2.6rem"}} type="tel" className="form-control py-6 kiosk-input write-input" name="phone_input"
                                               id="phone_num_third"
                                               maxLength={4} placeholder="5678"/>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <div className="d-flex w-100">
                                <button type="button" className="btn btn-secondary py-5 fs-4 me-4 w-100"
                                        onClick={onClose}>닫기
                                </button>
                                <button type="button" className="btn btn-pink py-5 fs-4 w-100"
                                        onClick={() => nextPage()}>다음
                                </button>
                            </div>
                        </div>
                    </div>
            </Modal>
        </ModalPortal>
    );
}

export default SendTypeModal;