import '../components/css/kiosk_common.css';
import {useNavigate} from "react-router-dom";
import React, {useState} from 'react';
import {ErrorMessage} from "@hookform/error-message";
import {useForm} from "react-hook-form";
import Loading from "../components/loading/Loading";
import axios from "axios";
function DentalPaperPayment() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const { register, setValue, formState: { errors }, handleSubmit } = useForm();
    const autoDeleteSpecialChar = (target: HTMLInputElement) => {
        target.value = target.value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gim, "");
    }
    const inputForm = document.getElementById("inputForm") as HTMLFormElement;
    const requestPaymentButton = document.getElementById("requestPaymentButton") as HTMLButtonElement;
    const goLoginButton = document.getElementById("goLoginButton") as HTMLButtonElement;
    const createAccountButton = document.getElementById("createAccountButton") as HTMLButtonElement;
    const paymentInformMsg = document.getElementById("paymentInformMsg") as HTMLParagraphElement;
    const goLogin = async () => {
        return navigate("/dentalLogin");
    }
    const createAccount = () => {
        return navigate("/createAccount");
    }
    if(isComplete) {
        inputForm.style.display = "none";
        goLoginButton.style.display = "block";
        createAccountButton.style.display = "none";
        paymentInformMsg.innerText = "결제신청이 완료되어 휴대폰번호로 안내메세지가 발송되었습니다.";
        requestPaymentButton.style.display = "none";
    }
    const onValid = async ({ depositorName, account, password }:any) => {
        // input 태그 값 비워주는 코드
        setValue("password", "");

        setLoading(true); // 데이터 저장 중임을 표시 로딩 상태 설정

        const requestPaymentUrl = process.env.REACT_APP_API_URL_ENTRY + "/requestPayment";
        // 회원 확인
        let memInfo = {
            depositorName : depositorName,
            account: account,
            password: password
        }
        axios.post(requestPaymentUrl, {
            "memInfo" : JSON.stringify(memInfo)
        }, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access-Control-Allow-Origin': '*'
            },
            withCredentials: true
        }).then(async (res) => {
            console.log(res);
            alert(res.data.msg);
            setIsComplete(true);
            setLoading(false);
        }).catch((err) => {
            console.log(err);
            alert(err.response.data.msg);
            setIsComplete(false);
            setLoading(false);
        });
    };
    return (
        <main className="form-signin text-center bg-light d-flex" style={{minHeight:"100vh", minWidth:"100%"}}>
            {loading ? <Loading/> : null}
            <form onSubmit={handleSubmit(onValid)} className="align-self-center justify-content-center mx-auto" style={{minWidth:"350px"}}>
                <img className="mb-4" src="/image/logo/bocare_white.svg" alt="" width="150" />
                <h1 className="h3 mb-3 fw-normal">제품구매</h1>
                <p className="h5 mt-5 mb-3 text-muted" id={"paymentInformMsg"}>결제는 현재 계좌이체만 지원하고 있습니다.</p>
                <div id={"inputForm"}>
                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" id="depositorName" placeholder="김보험" {...register("depositorName", {required: "입금자명을 입력해주세요."})}
                               maxLength={20} onInput={(event) => autoDeleteSpecialChar(event.target as HTMLInputElement)}/>
                        <label>입금자명</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input type="number" className="form-control" id="account" placeholder="01012345678" {...register("account", {required: "ID(전화번호)를 입력해주세요."})} />
                        <label>ID(전화번호)</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input type="password" className="form-control" id="password" placeholder="비밀번호" {...register("password", {required: "비밀번호를 입력해주세요."})} />
                        <ErrorMessage
                            name="password"
                            errors={errors}
                            render={( { message }) =>
                                <p className="text-sm font-medium text-rose-500">
                                    { message }
                                </p>
                            }
                        />
                        <label>비밀번호</label>
                    </div>
                </div>
                <div>
                    <div className={"h5 mb-3 fw-normal"}>
                        상품명 : 치과치료확인서 프로그램 1개월 이용권
                    </div>
                    <div className={"h5 mb-3 fw-normal"}>
                        입금금액 : 9,900원
                    </div>
                    <div className={"h5 mb-3 fw-normal"}>
                        입금은행 : 우리은행
                    </div>
                    <div className={"h5 mb-3 fw-normal"}>
                        입금계좌번호 : 1005-403-400313
                    </div>
                    <div className={"h5 mb-3 fw-normal"}>
                        예금주 : (주)에프피하우스
                    </div>
                </div>
                <p className="mb-3 text-danger">계좌이체 시 결제 반영까지 약 10여분 소요됩니다</p>
                <div className="d-flex gap-2 align-self-center justify-content-center mx-auto">
                    <button className="mt-5 btn btn-lg btn-secondary" id={"goLoginButton"} style={{display: "none"}}
                            onClick={goLogin}>로그인화면으로 이동
                    </button>
                    <button className="mt-5 btn btn-lg btn-primary" type="submit"
                            id={"requestPaymentButton"}>결제하기
                    </button>
                </div>
                <button className="mt-3 btn btn-lg btn-success" id={"createAccountButton"} onClick={createAccount}>계정 생성하기
                </button>
                <p className="mt-5 mb-3 text-muted">BOCARE 고객센터 1566-4875</p>
            </form>
        </main>
    );
}
export default DentalPaperPayment;