import axios from "axios";
import {getCookieToken} from "../components/Jwt/Cookie";

export const getAdminInsuranceData = async () => {
    const user = JSON.parse(localStorage.getItem("user") as string);

    return await axios.post(process.env.REACT_APP_API_URL_ENTRY + "/admin/getInsuranceList", {userId:user.account}, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access-Control-Allow-Origin': '*'
        },
        withCredentials: true
    });
}

//재청구 시 상세 내역조회
export const getAdminInsuranceDataByIdx = async (requestId : any) => {
    const user = JSON.parse(localStorage.getItem("user") as string);

    return await axios.post(process.env.REACT_APP_API_URL_ENTRY + "/admin/getInsuranceListByIdx", {
        userId: user.account,
        requestId: requestId
    }, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access-Control-Allow-Origin': '*'
        },
        withCredentials: true
    });
}

export const getAdminInsuranceDataByIdxWithPdf = async (requestId : any) => {
    const user = JSON.parse(localStorage.getItem("user") as string);

    return await axios.post(process.env.REACT_APP_API_URL_ENTRY + "/admin/getInsuranceListByIdxWithPdf", {
        userId: user.account,
        requestId: requestId
    }, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access-Control-Allow-Origin': '*'
        },
        withCredentials: true
    });
}

export const getAdminDentalConfirmDataByIdxWithPdf = async (requestId : any) => {
    const user = JSON.parse(localStorage.getItem("user") as string);

    return await axios.post(process.env.REACT_APP_API_URL_ENTRY + "/admin/getDentalConfirmListByIdxWithPdf", {
        userId: user.account,
        requestId: requestId
    }, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access-Control-Allow-Origin': '*'
        },
        withCredentials: true
    });
}

export const getAdminInsuranceDataByIdxWithAttachedPdf = async (requestId : any) => {
    const user = JSON.parse(localStorage.getItem("user") as string);

    return await axios.post(process.env.REACT_APP_API_URL_ENTRY + "/admin/getInsuranceListByIdxWithAttachedPdf", {
        userId: user.account,
        requestId: requestId
    }, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access-Control-Allow-Origin': '*'
        },
        withCredentials: true
    });
}

export const getUserInfo = async (data:any) => {
    const user = JSON.parse(localStorage.getItem("user") as string);

    return await axios.post(process.env.REACT_APP_API_URL_ENTRY + "/admin/info", {userId:user.account}, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access-Control-Allow-Origin': '*'
        },
        withCredentials: true
    });
}

export const insertAdminRequest = async (data:any) => {
    return await axios.post('http://localhost:8080/admin/save', data, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${getCookieToken()}`
        },
        withCredentials: true
    });
}

export const deleteAdminRequest = async (accessToken:any, data:any) => {

}

export const updateAdminInfo = async (accessToken:any, data:any) => {

}

export const getDentalFlyer = async () => {
    return await axios.post("https://bocare.co.kr/v2/restful/flyer/SelectFlyer.php", {}, {});
}