import '../components/css/kiosk_common.css';
import Layout from "../Layout/Layout";
import {useNavigate} from "react-router-dom";
import { useEffect } from 'react';
import SimpleSlider from '../components/Carousel/SimpleSlider';
function Introduce(){
    let navigate = useNavigate();
    useEffect(() => {
        if(window.location.hostname.includes("dentistpaper")) {
            return navigate("/dentalPaper");
        }
        const user = JSON.parse(localStorage.getItem("user") as string);
        if(user) {
            const main_image_paths = [user.main_logo_path_1, user.main_logo_path_2, user.main_logo_path_3];
            const main_images:any = [
                Array.from(document.getElementsByClassName("main_1")),
                Array.from(document.getElementsByClassName("main_2")),
                Array.from(document.getElementsByClassName("main_3"))
            ];
            for (let i = 0; i < main_image_paths.length; i++) {
                if (main_image_paths[i] === "" || main_image_paths[i] === undefined || main_image_paths[i] === null) {
                    main_images[i].src = '/image/main_title.svg';
                } else {
                    main_images[i].forEach((element:any)=> {
                        element.src = `https://bocare.azureedge.net/kioskmain/${main_image_paths[i]}`;
                    });
                }
            }
        }
    }, []);

    return(
        <Layout>
            <main className="container main_pd text-center">
                <SimpleSlider></SimpleSlider>
            </main>
        </Layout>
    );
}

export default Introduce;