import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { AzureBlobStorage } from "../AzureStorage/AzureStorage";
import Loading from "../components/loading/Loading";
import {InsuranceImageScan} from "../components/ImageResize/InsuranceImageScan";
import {AjaxClientV2} from "../components/util/AjaxClientV2";
import {HtmlElements} from "../components/util/HtmlElements";

declare let jsPDF:any;

declare let PdfJS:any;

function CustomerShare() {
    const [requestIdx, setRequestIdx] = useState("");
    const { uuid }: any = useParams();
    const selectFile: any = useRef("");
    const idCardSelectFile: any = useRef("");

    const [showImages, setShowImages] = useState([]);
    const [uploadList, setUploadList] = useState([]);
    const [idCard, setIdCard] = useState([]);
    const [uploadDBList, setUploadDBList] = useState([]);
    const [loading, setLoading] = useState(false);

    let returnObj:any = {};

    let scanImageList = new Array<{
        imageName:string,
        format:string,
        image:string
    }>();

    const azure = new AzureBlobStorage("insurancerequest");

    const GetScanImage = async (file:any) => {
        const extension = file.name.substring(file.name.length, file.name.lastIndexOf('.')).toLowerCase();
        await readFileAsync(file, extension);

    }

    function readFileAsync(file:any, extension:any) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = async() => {
                // resolve(reader.result);
                let imageName = file.name;
                let imagePath:any = reader.result;
                let format = InsuranceImageScan.getExtensionFormat(extension);

                if (format == "") {
                    return;
                }

                let base64 = await InsuranceImageScan.imageScanAsync(imagePath, extension);

                let formData = new FormData();

                let base64_split = base64.split(',');
                let byteString = atob(base64_split[1]);
                let max = byteString.length;
                let uint8Array = new Uint8Array(byteString.length);
                let mime_type = base64_split[0].split(':')[1].split(';')[0];

                for (let i = 0; i < max; i++) {
                    uint8Array[i] = byteString.charCodeAt(i);
                }

                let blob = new Blob([uint8Array], { type: mime_type });

                formData.append("image", blob);
                let response = await AjaxClientV2.sendPostFormDataAsync("https://bocareimagefunction.azurewebsites.net/api/scan", formData
                );
                let data = response.responseText;

                let tempObject = {
                    imageName: imageName,
                    format: format,
                    image: `${base64_split[0]},${data}`
                };

                scanImageList.push(tempObject);
                resolve(scanImageList);
            };
            reader.readAsDataURL(file);
            reader.onerror = reject;
        });

    }

    const getRequestInfo = async (uuid: any) => {
        let result = await axios.post(process.env.REACT_APP_API_URL_ENTRY + "/getRequestInfo", { "uuid": uuid }, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access-Control-Allow-Origin': '*'
            },
            withCredentials: true
        });

        let data = result.data.responseData;

        if (data.status === 20 || data.status === 30) {
            window.location.replace(`${window.location.origin}/shareEndOpen`);
        }

        const insurer_name = document.getElementById("insurer_name") as HTMLElement;
        const insurer_number = document.getElementById("insurer_number") as HTMLElement;
        const insurer_phone = document.getElementById("insurer_phone") as HTMLElement;
        const selected_company = document.getElementById("selected_company") as HTMLElement;

        insurer_name.innerHTML = data.insurerName;
        insurer_number.innerHTML = data.insurerNumber;
        insurer_phone.innerHTML = data.insurerPhone;
        for (let i = 0; i < data.selectedCompany.length; i++) {
            if (i === 0) {
                selected_company.innerHTML = data.selectedCompany[i];
            } else {
                selected_company.innerHTML += ", " + data.selectedCompany[i];
            }
        }
    }

    const handleFileSubmit = async () => {
        setLoading(true);
        setUploadDBList([]);

        if(uploadList.length === 0){
            alert("보험금 청부서류를 첨부해주세요.");
            setLoading(false);
            return;
        }

        if(uploadList.length > 50){
            alert("보험금 청부서류는 최대 50장까지 첨부가능합니다.");
            setLoading(false);
            return;
        }

        // if(idCard.length === 0){
        //     alert("신분증을 첨부해주세요.");
        //     setLoading(false);
        //     return;
        // }

        let date = new Date();
        let year = date.getFullYear();
        let month = (("00" + (date.getMonth() + 1).toString()).slice(-2));
        let day = (("00" + date.getDate().toString()).slice(-2));

        const promises = [];
        let uploadDB: any = [...uploadDBList];

        let fileList = [...uploadList, ...idCard];

        let elementName: string;
        let blob: Blob;
        let checkFirstImage:boolean = false;
        let imageName: string;
        
        //pdf열기(file 객체)
        let pdfDoc = new jsPDF("p", "mm", "a4", true);
        pdfDoc.deletePage(1);

        let pdfPromise:any = [];

        for await(let file of fileList){
            pdfPromise.push(GetScanImage(file));
        }

        await Promise.all(pdfPromise);

        for (let i = 0; i < fileList.length; i++) {
            let file: File = fileList[i];
            //bocare logic
            if (!checkFirstImage) {
                let base64_split = scanImageList[i].image.split(',');
                let byteString = atob(base64_split[1]);
                let max = byteString.length;
                let uint8Array = new Uint8Array(byteString.length);
                let mime_type = base64_split[0].split(':')[1].split(';')[0];

                for (let i = 0; i < max; i++) {
                    uint8Array[i] = byteString.charCodeAt(i);
                }
                blob = new Blob([uint8Array], {type: mime_type});
                checkFirstImage = true;
                elementName = "image_file";
                imageName = "imageThumbnail.jpg";

                const data = new DataTransfer();
                let newFile = new File([blob], `${imageName}`, { type: blob['type'] });
                data.items.add(newFile);
                file = data.files[0];
            } else {
                imageName = "mergeAttachImage.pdf";
                pdfDoc.addPage("a4", "p");
                pdfDoc.addImage(scanImageList[i].image, scanImageList[i].format, 0, 0, 210, 297, undefined, 'FAST'); //imageData as base64 encoded DataUrl or Image-HTMLElement or Canvas-HTMLElement
                blob = pdfDoc.output('blob');
                elementName = "image2pdf";

                const data = new DataTransfer();
                let newFile = new File([blob], `${imageName}`, { type: blob['type'] });
                data.items.add(newFile);
                file = data.files[0];
            }

            const ext = file.name.substring(file.name.length, file.name.lastIndexOf('.')).toLowerCase();
            let filePath: string = `${year}/${year}-${month}-${day}/${azure.uniqid("insurancerequest", ext)}`;

            //Image to Pdf

            if( i == 0 || i == fileList.length - 1) {
                uploadDB.push({
                    file_name: azure.uniqid("보험금청구_팩스_첨부파일", ext),
                    file_path: filePath,
                    file_size: file.size
                });
                promises.push(azure.UploadInsuranceRequestAsync(file, filePath));
            }
        }

        await Promise.all(promises);
        promises.length = 0;

        for (let i = 0; i < fileList.length; i++) {
            let file: File = fileList[i];
            const ext = file.name.substring(file.name.length, file.name.lastIndexOf('.')).toLowerCase();
            let filePath: string = `${year}/${year}-${month}-${day}/${azure.uniqid("insurancerequest", ext)}`;
            uploadDB.push({
                file_name: azure.uniqid("insurancerequest", ext),
                file_path: filePath,
                file_size: file.size
            })
            promises.push(azure.UploadInsuranceRequestAsync(file, filePath));
        }

        await Promise.all(promises);

        setUploadDBList(uploadDB);

        await axios.post(`${process.env.REACT_APP_API_URL_ENTRY}/insRequest`
            , {
                "uploadFileInfo": JSON.stringify(uploadDB),
                "uuid": uuid
            }
            , {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access-Control-Allow-Origin': '*',
                },
                withCredentials: true
            }).then((res) => {
            alert("파일 업로드가 완료되었습니다. 팩스는 자동으로 발송됩니다.");
            window.location.replace(`${window.location.origin}/shareEnd`);
        }).catch((err) => {
            console.log(err);
            alert("파일 업로드 도중 에러가 발생하였습니다. 관리자에게 문의하여주세요.");
            window.location.reload();
        });

        setUploadDBList([]);
        setIdCard([]);
    }

    const handleAddIdCard = (event: any) => {
        const imageLists = event.target.files;
        let imageUrlLists: any = [...showImages];
        let fileLists: any = [...idCard];

        for (let i = 0; i < imageLists.length; i++) {
            const currentImageUrl: any = URL.createObjectURL(imageLists[i]);
            imageUrlLists.push(currentImageUrl);
            fileLists.push(imageLists[i]);
        }

        setIdCard(fileLists);
        setShowImages(imageUrlLists);
    };

    const handleAddImages = (event: any) => {
            const imageLists = event.target.files;
            let imageUrlLists: any = [...showImages];
            let fileLists: any = [...uploadList];

            for (let i = 0; i < imageLists.length; i++) {
                const currentImageUrl: any = URL.createObjectURL(imageLists[i]);
                imageUrlLists.push(currentImageUrl);
                fileLists.push(imageLists[i]);
            }

            setUploadList(fileLists);
            setShowImages(imageUrlLists);
    };

    const handleDeleteImage = (id: any) => {
        setShowImages(showImages.filter((_, index) => index !== id));
        setUploadList(uploadList.filter((_, index) => index !== id));
    };

    const handleImgError = (e: any) => {
        e.target.src = "/image/PDF_file_icon.svg";
    }

    useEffect(() => {
        getRequestInfo(uuid);
    }, []);

    return (
        <main className="flex-column container p-3">
            {loading ? <Loading /> : null}
            <div className="mb-4">
                <div className="fs-2 fw-bold">안녕하세요, 고객님<i className="bi bi-emoji-smile ms-1"></i></div>
                <div className="fs-2 fw-bold mb-3">파일첨부 후 보험금청구를 마무리해주세요.</div>
                <div className="fs-3">· 필수 첨부서류</div>
                <div className="fs-3">① <span className="text-pink fw-bold">병원 첨부서류</span></div>
                <div className="fs-3">② <span className="text-pink fw-bold">주민등록증</span> or <span className="text-pink fw-bold">운전면허증</span></div>
                <div className="fs-6 my-3">※ 서류가 누락 될 경우 보험금 지급 및 금액이 제대로 지급이 안될 수 있습니다.</div>
            </div>
            <hr className="d-flex g-brd-gray-light-v7 g-my-30" />
            <div className="mb-3">
                <div className="mb-4">
                    <div className="mb-4">
                        <table className="table table-bordered">
                            <thead>
                            <tr className="fs-5 bg-light">
                                <th colSpan={2}>인적사항</th>
                            </tr>
                            </thead>

                            <tbody>
                            <tr className="fs-6">
                                <td>
                                    진료받으신 환자
                                </td>
                                <td className="fw-bold text-bocare" id="insurer_name">
                                    테스트
                                </td>
                            </tr>
                            <tr className="fs-6">
                                <td>
                                    주민등록번호
                                </td>
                                <td className="fw-bold text-bocare" id="insurer_number">
                                    940721-1******
                                </td>
                            </tr>
                            <tr className="fs-6">
                                <td>
                                    연락처
                                </td>
                                <td className="fw-bold text-bocare" id="insurer_phone">
                                    010-1234-1234
                                </td>
                            </tr>

                            </tbody>
                        </table>

                        <table className="table table-bordered">
                            <thead>
                            <tr className="fs-5 bg-light">
                                <th>선택보험사</th>
                            </tr>
                            </thead>

                            <tbody>
                            <tr className="fs-6">
                                <td className="fw-bold text-bocare" id="selected_company">
                                    KB손해보험, 메리츠화재
                                </td>
                            </tr>

                            </tbody>
                        </table>

                        <div className="col-12 d-flex justify-content-between">
                            <div className="files w-100">
                                <div id="attachFileContainer" className="form-group">

                                    <div id="fileUploadButton"
                                         className="border d-flex align-items-center justify-content-center"
                                         role="button" style={{ height: "192px" }}>
                                        <input className="d-none" type="file" accept="image/*" id="fileAttachment"
                                               name="fileAttachment[]" ref={selectFile} multiple onChange={handleAddImages} />
                                        <div className="d-flex align-items-center" onClick={() => selectFile.current.click()}>
                                            <div className="d-flex align-items-center">
                                                <img className="w-25" src="/image/icon/kiosk_document_icon.svg"></img>
                                                <div className="fs-4 fw-bold">① 청구서류 파일 첨부(클릭)</div>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <div id="fileUploadButton"
                                         className="border d-flex align-items-center justify-content-center"
                                         role="button" style={{ height: "192px" }}>
                                        <input className="d-none" type="file" accept="image/*" id="fileAttachment"
                                               name="fileAttachment[]" ref={idCardSelectFile} onChange={handleAddIdCard} />
                                        <div className="d-flex align-items-center" onClick={() => idCardSelectFile.current.click()}>
                                            <div className="d-flex align-items-center">
                                            <img className="w-25" src="/image/icon/kiosk_ID_icon.svg"></img>
                                                <div className="fs-4 fw-bold">② 신분증 파일 첨부(클릭)</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="file_wrapper" className="row">
                                    {showImages.map((image, id) => (
                                        <div className="mt-3" key={id}>
                                            <div className="border card h-100 p-3">
                                                <div className="w-100 d-flex flex-wrap h-100 text-center">
                                                    <div className="w-100 align-self-center">
                                                        <img className="img-fluid" src={image} onError={handleImgError}
                                                             alt={`${image}-${id}`} />
                                                    </div>
                                                    <div className="w-100 align-self-end">
                                                        <button id="delete_{{idx}}" type="button" onClick={() => handleDeleteImage(id)}
                                                                className="btn btn-secondary mt-2 w-100">파일삭제
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <input type="file" className='d-none' id="file_{{idx}}" name="file_{{idx}}" />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="fs-5 mt-2">1. 그림자 생기지 않도록 해주세요.</div>
                        <div className="fs-5">2. 초점 흔들리지 않게 찍어주세요.</div>
                        <div className="fs-5">3. 화면 가득차게 찍어주세요.</div>
                        <div className="fs-6 mt-2">※ 보험금청구는 각 보험사 사정에 따라 영업일 기준 1-2일 소요됩니다.</div>
                    </div>

                    <hr className="bg-secondary" />
                    <div className="py-2">
                        <div className="d-flex align-self-center">
                            <button id="saveButton" onClick={() => handleFileSubmit()} className="btn btn-info fs-1 fw-bold text-white w-100 py-4">보험금청구 파일 전송<i className="bi bi-chevron-right ms-2"></i></button>
                        </div>
                        <div className="d-flex align-self-end align-items-center float-right text-right">

                        </div>
                    </div>
                    <input type="file" id="image_file" name="image_file" className="d-none"/>
                    <input type="file" id="image2pdf" name="image2pdf" className="d-none"/>
                </div>
            </div>
        </main>
    );
}

export default CustomerShare;