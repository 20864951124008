import {ImageResizer} from "./ImageResizer";

export class InsuranceImageScan{
    static getDataFormat(data : string) : string {
        let format = "";

        let path = data.split('?path=')[1];
        let mime_type = path.substring(path.lastIndexOf('.') , path.length).toLowerCase();
        if(mime_type == '.jpg' || mime_type == '.jpeg'){
            format = 'JPEG';
        }
        else if(mime_type == '.png'){
            format = 'PNG';
        }

        return format;
    }

    static getExtensionFormat(ext : string) : string {
        let format = "";

        if(ext == '.jpg' || ext == '.jpeg'){
            format = 'JPEG';
        }
        else if(ext == '.png'){
            format = 'PNG';
        }

        return format;
    }


    static getImageType(ext : string) : string{
        let format = this.getExtensionFormat(ext);

        if(format == "JPEG"){
            return 'image/jpeg';
        }

        return 'image/png';
    }


    static async imageScanAsync(src: string, ext: string) : Promise<string>{
        return new Promise<string>(async resolve =>{
            let image = new Image();
            image.src = src;

            image.onload = async () => {
                let canvas = document.createElement('canvas');
                let wh = ImageResizer.getImageSize(image);
                let width = wh.width;
                let height = wh.height;
                canvas.width = width;
                canvas.height = height;

                let ctx:any = canvas.getContext("2d");
                ctx.drawImage(image, 0, 0, width, height);

                let type = this.getImageType(ext);

                let imageData = ctx.getImageData(0, 0, image.width, image.height);
                imageData = ImageResizer.reszieImageData(image , imageData);
                let base64 = await ImageResizer.resizeImageDataToBase64StringAsync(imageData, type);

                if (wh.width > wh.height) {
                    base64 = await ImageResizer.rotateBase64ImageAsync(base64, 90, type);
                }
                resolve(base64);
            };
        });
    }
}