import {Cookies} from "react-cookie";

const cookies = new Cookies();

export const setRefreshToken = (refreshToken: string) => {
    const today = new Date();
    const expireDate = today.setDate(today.getDate() + 7);

    return cookies.set('refresh_token', refreshToken, {
        sameSite: 'strict',
        path: '/',
        expires: new Date(expireDate)
    });
};

export const setAccessToken = (accessToken: string) => {
    const today = new Date();
    const expireDate = today.setDate(today.getDate() + 31);

    return cookies.set('access_token', accessToken, {
        sameSite: 'strict',
        path: '/',
        expires: new Date(expireDate)
    });
}

export const getCookieToken = () => {
    return cookies.get('refresh_token');
}

export const getAccessToken = () => {
    return cookies.get('access_token');
}

export const removeCookieToken = () => {
    return cookies.remove('refresh_token', {sameSite: 'strict', path: '/'});
}