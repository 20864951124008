import {ModifyRequest} from "./ModifyRequest";
import {DentalUserInfoRequest} from "./DentalUserInfoRequest";
import {DentalTreatmentInfoRequest} from "./DentalTreatmentInfoRequest";


export class DentalPdfConstruction {
    constructor(
        public modifyRequest: ModifyRequest,
        public dentalUserInfoRequest: DentalUserInfoRequest,
        public dentalTreatmentInfoRequest : DentalTreatmentInfoRequest[]
    ) {
    }
}