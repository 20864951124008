import React, { useState, useEffect } from 'react';
import '../css/dental.css';
import AdminDentalFlyerModal from "../../Admin/Modal/AdminDentalFlyerModal";
import ModalPortal from "../../Layout/Modal/ModalPortal";

interface Slide {
    image: string;
    alt: string;
    title: string;
}

interface Props {
    slides: Slide[];
}

const MultiSlide: React.FC<Props> = ({ slides }) => {
    const [currentSlide, setCurrentSlide] = useState<number>(0);
    const [shownSlides, setShownSlides] = useState<number[]>([0,1,2,3,4]); // 초기값 설정
    const [adminDentalFlyerModalOpen, setAdminDentalFlyerModalOpen] = useState();
    const [flyerTitle, setFlyerTitle] = useState();
    const [flyerImagePath, setFlyerImagePath] = useState();

    const HandleAdminDentalFlyerModalShow = (flyerTitle: any , flyerImagePath: any) => {
        setFlyerTitle(flyerTitle);
        setFlyerImagePath(flyerImagePath);
        // @ts-ignore
        setAdminDentalFlyerModalOpen(true);
    }
    const HandleAdminDentalFlyerModalClose = () => {
        // @ts-ignore
        setAdminDentalFlyerModalOpen(false);
    }

    const calculateSlidesToShow = (currentIndex: number) => {
        const length = slides.length;
        return [
            (currentIndex - 2 + length) % length,
            (currentIndex - 1 + length) % length,
            currentIndex,
            (currentIndex + 1) % length,
            (currentIndex + 2) % length
        ];
    };


    const nextSlide = () => {
        setCurrentSlide((prevSlide) =>
            prevSlide === slides.length - 1 ? 0 : prevSlide + 1
        );
    };

    const prevSlide = () => {
        setCurrentSlide((prevSlide) =>
            prevSlide === 0 ? slides.length - 1 : prevSlide - 1
        );
    };

    useEffect(() => {
        const interval = setInterval(() => {
            nextSlide();
        }, 4000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        setShownSlides(calculateSlidesToShow(currentSlide));
    }, [currentSlide]);

    return (
        <div style={{textAlign: 'center' , overflow: 'hidden' }}> {/* 외부 컨테이너 */}
            <div className="slide-container" style={{ width: 300, display: 'flex', justifyContent: 'center', marginTop: '20px', marginLeft: 'auto', marginRight: 'auto', maxWidth: '100%' }}>
                {shownSlides.map((indexToShow, index) => (
                    <div key={index} className="slide" style={{ minWidth: '100%', maxWidth: '100%', transform: `translateX(${index}%)`, margin: '0' }}>
                        <img src={slides[indexToShow].image} alt={slides[indexToShow].alt} className="image" style={{ width: '80%', height: 'auto', border: '1px solid black', borderRadius: '8px', cursor: "pointer" }}
                             onClick={() =>HandleAdminDentalFlyerModalShow(slides[indexToShow].title, slides[indexToShow].image)}/>
                    </div>
                ))}
            </div>
            <br/>
            <button className="dental-btn btn-sm me-2" onClick={prevSlide}>
                ⮜
            </button>
            <button className="dental-btn btn-sm" onClick={nextSlide}>
                ⮞
            </button>
            <br/><br/>

            {adminDentalFlyerModalOpen && (
                <ModalPortal>
                    <AdminDentalFlyerModal onClose={HandleAdminDentalFlyerModalClose} isOpen={true}
                                             flyerTitle={flyerTitle} flyerImagePath={flyerImagePath}/>
                </ModalPortal>
            )}
        </div>
    );
};

export default MultiSlide;
