import '../components/css/kiosk_common.css';
import DentalLayout from "../Layout/DentalLayout";
import {useNavigate} from "react-router-dom";

function DentalPaperMain() {
    const navigate = useNavigate();
    const start = async () => {
        if (!localStorage.getItem("user")) {
            alert("로그인이 필요합니다.");
            return navigate("/dentalLogin");
        }
        return navigate("/admin/dental")
    }
    const dentalPayment = () => {
        return navigate("/dentalPayment");
    }
    return (
        <DentalLayout>
            <main className="container main_pd text-center">
                <div className="d-block">
                    <div className={"mb-3"}>
                        <button className={"btn btn-primary me-5"} onClick={start}>
                            시작하기
                        </button>
                        <button className={"btn btn-success me-5"} onClick={dentalPayment}>
                            구매하기
                        </button>
                    </div>
                    <div className="py-5">
                        <img src={"/image/dental_kiosk.jpg"}/>
                    </div>
                    <div className="py-5">
                        고객센터 1566-4875
                    </div>
                </div>
            </main>
        </DentalLayout>
    );
}
export default DentalPaperMain;