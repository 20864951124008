import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

const pdfWorkerPath: string = "/js/pdf-dist/pdf.worker.min.js";
pdfjs.GlobalWorkerOptions.workerSrc = pdfWorkerPath;

const PDFViewer: React.FC<{ pdfUrl: string }> = ({ pdfUrl }) => {
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(0.8);

    function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
        setNumPages(numPages);
    }

    function changePage(offset: number) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    const previousPage = () => {
        changePage(-1);
    };

    const nextPage = () => {
        changePage(1);
    };

    const zoomIn = () => {
        setScale(prevScale => prevScale * 1.1); // 10% 확대
    };

    const zoomOut = () => {
        setScale(prevScale => prevScale / 1.1); // 10% 축소
    };

    return (
        <div>
            <div>
                <div className="d-flex justify-content-center">
                    <button className="btn btn-sm btn-outline-success me-1" onClick={zoomIn}>
                        확대
                    </button>
                    <button className="btn btn-sm btn-outline-success" onClick={zoomOut}>
                        축소
                    </button>
                </div>
            </div>
            <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} scale={scale} />
            </Document>
            <div className="d-flex justify-content-center">
                <p className="me-1">
                    페이지 {pageNumber} / {numPages}
                </p>
            </div>
            <div className="d-flex justify-content-center">
                <button className="btn btn-sm btn-outline-primary me-1" disabled={pageNumber <= 1} onClick={previousPage}>
                    <i className="bi bi-arrow-left"></i>
                    이전
                </button>
                <button className="btn btn-sm btn-outline-primary" disabled={pageNumber >= numPages} onClick={nextPage}>
                    다음
                    <i className="bi bi-arrow-right"></i>
                </button>
            </div>
        </div>
    );
};

export default PDFViewer;
