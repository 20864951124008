import ModalPortal from "../../Layout/Modal/ModalPortal";
import Loading from "../../components/loading/Loading";
import Modal from "react-bootstrap/Modal";
import {useState} from "react";

const AdminDentalFlyerModal = ({onClose, isOpen, flyerTitle, flyerImagePath}: any) => {
    const [loading, setLoading] = useState(false);

    return (
        <ModalPortal>
            {loading ? <Loading /> : null}
            <Modal show={isOpen} size="lg">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel"><b>{flyerTitle}</b></h5>
                    <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <img className="image" src={flyerImagePath} alt="이미지를 불러올 수 없습니다" style={{width:"100%"}}/>
                </div>
                <div className="modal-footer w-100">
                    <div className="mb-2 d-flex w-100">
                        <div className="w-50 pe-1">
                            <a className="w-100 btn btn-pink py-2" href={flyerImagePath}>
                                <i className="bi bi-download me-1" ></i>
                                다운로드
                            </a>
                        </div>
                        <div className="w-50 ps-1">
                            <a className="w-100 btn btn-secondary py-2" onClick={onClose}>
                                닫기
                            </a>
                        </div>
                    </div>

                </div>
            </Modal>
        </ModalPortal>
    );
}

export default AdminDentalFlyerModal;